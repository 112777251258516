.homepage-enterprise{
    position: relative;
    width: 100%; 
    height: 783px; 
    background:url('../images/logoC.png') calc(-5% - 1%) 0 no-repeat,
                url('../images/dots_transparents_right.png') no-repeat right top;
    &:after{
        content: ''; 
        position: absolute;
        left: 0; 
        bottom: 30px;
        height: 50px; 
        width: 50%;
        background: url('../images/dots_transparents_bottom.png');
        background-repeat: round;
        @media (max-width:1024px){
            display: none;
        }
    }

    @media(max-width:$screen-xs-min) {
        height:832px;
    }

    .homepage-enterprise_one, 
    .homepage-enterprise_two{
        display: inline-block; 
        position: relative;
        width: 50%; 
        height: 100%;

        .homepage-enterprise_one-content{
            position: absolute; 
            top: 10%; 
            left: 20%;
            height: 60%; 
            width: 80%;
            @media (max-width: 1024px){
                width: 70%;
            }
            @media (max-width:$screen-xs-min){
                left: 0; 
                top: 5%;
                width: 100%;
            }
            .services-logo{
                margin-bottom: 37px;
                width: 60%; 
                height: auto;
                @media (max-width:$screen-xs-min){
                    width: 100%;
                }
            }

            h2{
                position: relative; 
                width: 60%;
                line-height: 25px; 
                font-size: 2rem;
                font-weight: 800;
                @media (max-width:$screen-xs-min){
                    width: 80%; 
                    margin: auto;
                }
                &:after{ 
                    content: '';
                    position: absolute;
                    bottom: -20px;
                    left: 0px;
                    width: 90px;
                    height: 2px;
                    background: $color-secondary;
                    @media(max-width:1024px){
                        bottom: -10px;
                    }
                }
            }

            .services-descr{
                margin-top: 5%;
                margin-bottom: 30px;
                @media(max-width: $screen-xs-min){
                    width: 80%;
                    margin-top: 10%; 
                    padding-left: 12px;
                    margin-left: auto; 
                    margin-right: auto;
                }
            }
        }

        .btn{
            @media(max-width: $screen-xs-min){
                display: block; 
                width: 60%; 
                margin: auto;
            }
        }

        .homepage-enterprise_two--pic{
            position: absolute;
            top: 12%;
            left: 8%;
            height: 70%; width: 90%;
            background:url('../images/mecabam_facade.png') no-repeat;
            background-size: cover;

            @media (max-width: $screen-md-min) {
                background-position: 60%;
            }

            @media(max-width:1024px){
                height: 100%; 
                width: 100%;
                top: 0; 
                left: 0;
            }
            
            @media (max-width: $screen-xs-min) {
                display: none;
            }
        }

        .homepage-enterprise_two--charte{
            position: absolute;
            display: block;  
            bottom: 0; 
            left: 0px;
            height: 300px; 
            width: 475px; 
            background:url('../images/black-diamond-plate.png') calc(-5% - 1%) 0 no-repeat,
                url('../images/dots_transparents_right.png') no-repeat right top;
            background-color: $color-primary;
            overflow: hidden;

            @media(max-width: $screen-xs-min){
                height:330px;
                left: 0; 
                width: 100%;
                background-color: $color-secondary;

                .btn-white{
                    margin: auto;
                    display: block;
                    width: 70%;
                }
            }

            @media(max-width: 1024px){
                right: 0; 
                left: inherit;
            }

            .charte_content{
                height: 80%; 
                width: 80%; 
                margin-top: 6%; 
                margin-left: 6%;

                @media(max-width: $screen-xs-min){
                    margin-left: auto; 
                    margin-right: auto; 
                    margin-top: 8%;
                    width:90%;
                }
                
                h2{
                    position: relative; 
                    width: 60%;
                    font-size: 2rem; 
                    font-weight: 500; 
                    color: white;
                    margin-bottom: 10%;
                    
                    &:after{ 
                        content: '';
                        position: absolute;
                        bottom: -20px; 
                        left: 0px;
                        width: 90px;
                        height: 2px;
                        background: white;
                    }
                }

                .charte-descr{
                    color: white; 
                    margin-bottom: 10%; 
                    font-weight: 100;
                }

                .btn-white{
                    color: white; 
                    border-color: white;

                    @media(max-width: $screen-xs-min){
                        width: 100%;
                    }
                }
            }
        }
    }

    .homepage-enterprise_one{
        float: left;

        @media(max-width:1024px){
            display: block; 
            height: 60%; 
            width: 100%;
        }
    }
    .homepage-enterprise_two{
        float: right;

        @media(max-width:1024px){
            display: block; 
            height: 40%; 
            width: 100%;
        }

        @media(max-width:$screen-xs-min){
            background: url('../images/dots_transparents_bottom.png') no-repeat left top 12%;
        }
    }
    
}