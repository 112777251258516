//
// Homepage section
// --------------------------------------------------------------------------

.homepage-section {
    margin: 0;

    @media (min-width: $screen-sm-min) {
        margin: 0;
    }
}


// Homepage section Title
// --------------------------------------------------------------------------

.homepage-section__title {
    position:relative;
    display: block;
    width:100%;
    margin: 0;
    font-size: $font-size-large + .2rem;
    font-weight: 300;
    letter-spacing: .5px;
    text-align: center;
    &::after {
        content:'';
        position:absolute;
        bottom:-20px;
        left: calc(50% - 45px);
        width:90px;
        height:2px;
        background:$color-primary;
    } 

    @media (min-width: $screen-sm-min) {
        margin: 0;
        font-size: $font-size-h2;
        letter-spacing: .8px;
    }
}
