// Toolbar
//-------------------------  
.toolbar-products{
    margin-top: 20px; 
    margin-bottom: 20px;
    text-align: left;

    select{
        border: none;
        background: transparent;

        &:focus, 
        &:active, 
        &:hover, 
        &:checked {
            border: none; 
            background: transparent;
        }

        option{
            background: transparent;
        }
    }   
}

// Sidebar Left
//------------------------- 
.sidebar-main{
    margin-top: 90px;
}

.sidebar-additional{
    @media(min-width: $screen-sm-min){
        padding-right: 0;
    }
}

.product-item-actions .actions-primary {
    @media (min-width: 640px){
        display: inherit;
        text-align: center;
    }
}

.filter-title{
        display: none;
}

.filter-content{
    @media(max-width:$screen-sm-min){
        text-align: left;
    }

    .action.remove{
        display: block;
        margin-top: 16px;
    }
}

.block-content{
    a:visited{
        color: $color-primary;
    }

    a:hover{
        color: $color-primary;
    }
}


.block-compare{
    padding: 7%;

    .actions-toolbar{
        padding: 11%;

        @media(max-width:1800px){
            padding: 0;
        }

        a.action{
            margin-top: 0;
        }

        .compare{
            margin-bottom: 15px;

            a, a:hover{
                background: transparent;
                border: none; 
                color: white;
                font-weight: 700; 
                font-size: 1.6rem;
            }
        }
    }

    .delete:before{
        &:hover{ 
            color: $color-primary;
        }
    }
}



// Main content list products
//------------------------- 
.page-products .products-grid{
    .product-items{
        padding-right: 2%;
        margin-left: 0;
        @media(max-width:$screen-sm-min){
            padding-right: 0;
        }
    }
    
    .product-item{
        position: relative;
        background-color: white;
        padding: 2%; 
        border-radius: 6px; 
        border: solid 1px rgba(33,33,33 ,.1);
        text-align:center; 
        overflow: hidden;
        @media(max-width:$screen-xs-min) {
            display: block;
            width: 100%;
            max-width: 80%;
            margin: auto;
            margin-bottom: 12px;
        }
        @media(max-width:$screen-xs-min - 120px) {
            max-width:100%;
            margin: auto;
        }

        .price-box{
            height: 70px; 
            margin: 25px 0 25px;
            line-height: 25px;

            .price-label{
                font-size: 1.2rem;
            }
        }

        .stock-on-top{
            width: 80%; 
            margin: auto;

            .out-of,
            .for-rent {
                color: rgba(46,125,50 ,.7); 
                font-weight: 600; 
                text-align: center; 
                width: 100%; 
                margin: auto;
            }
            .for-rent {
                display:block;
            }
        }

        .product-item-info{
            margin: auto;
            width: 270px;
            
            img{
                margin: auto;
                height: 234px; 
                width: auto;
            }
        }

        .product-item-inner{
            .product-item-actions{
                @media(max-width:640px){
                    display: block;
                }

                .btn{
                    @media(max-width:773px){
                        width: 100%; 
                        margin: auto; 
                        padding: 12px 20px;
                        text-overflow: inherit;
                    }
                }
            }

            .product-item-action-view{
                margin-top: 10px; 
                margin-bottom: 15px;
            }

            .addcompare{
                background-color: rgba(69,90,100 ,.6);
                position: relative; 
                display: none;

                @media(max-width:$screen-sm-min){
                    display: none;
                }
                
                a::before{
                    font-family: 'icons-blank-theme';
                    font-size:0; 
                    color: transparent;
                    position: absolute; 
                    left: -1500px;
                    height: 0.1px; 
                    width: 0.1px;
                }
                .addcompare-icon{
                    color: white;
                    line-height: 20px;
                    vertical-align: middle;

                    .icon-compare{
                        font-family: 'icons-blank-theme';
                        content: '';
                        font-size: 2.5rem; 
                        color: white;
                    }
                }
            }
        }

        .product-item-name{
            height: 80px; 
            overflow: hidden; 
            position: relative;
            margin-top: 5%; 
            margin-bottom: 2%;
            text-overflow: ellipsis;

            a{
                font-size: 2rem; 
                font-weight: 700;
                line-height: 25px;
                text-decoration: none;

                &:after {
                    content:'';
                    position:absolute;
                    bottom:0;
                    left:15%;
                    width:70%;
                    height:3px;
                    background:$color-primary;
    
                    @media (max-width: $screen-sm-max) {
                        font-size: $font-size-base; 
                    }
                    @media (max-width: $screen-xs-min){
                        font-size: $font-size-base - 0.5;
                    }
                }        
            }
        }

        .price-box{
            height: 45px;

            .price{
                font-weight: 700;
                font-size: 2rem;
            }

            .old-price{
                .price{
                    font-size: 1.2rem;
                }
            }
        }

        .product-reviews-summary{
            display: block; 
            margin: 0;
    
            .reviews-actions{
                line-height: inherit;
                margin: 0; 
                display: none;
    
                a.action.view, span{
                    color: black;
                }
    
                a .action .view:visited{
                    color: black;
                }

                a{
                    color: black;
                }
            }
        }

        .review-block{
            display: inline-block; 
            position: absolute; 
            top: 0; 
            left: -10px;
            overflow: hidden;
            vertical-align: middle;
            padding: 12px 40px;
            font-size: 1.6rem;
            line-height: 1.1;
            font-weight: 500;
            white-space: nowrap;
            text-transform: none;
            text-overflow: ellipsis;
            text-decoration: none;
            text-align: center;
            background-image: none;
            border: 0;  
            
            @media(max-width: 1440px){
                left: -35px;
            }

            @media(max-width:$screen-sm-min){
                display: none;
            }
        }

        
    }
}

.product-reviews-summary .reviews-actions a {
    color: black;
    &:hover{
        color: black;
    }
}

.page-products {
    .message.info.empty {
        max-width: 400px;
        background-color: transparent;
        border-color: transparent;
        color: black;
        @media(max-width:$screen-xs-min) {
            width: 70%;
            max-width: 100%;
        }
    }
}


// Pager
//------------------------- 

.pages{
    a.next, 
    a.previous{
        &:hover{
            background-color: $color-primary;
            color: #ccc; 
            font-weight: 700;
        }
    }

    .action.next:before{
        color: #ccc;
    }

    .action.next:visited:before{
        color: #ccc;
    }

    a.page{
        span{
            color: $color-secondary;
        }
    }
}

.catalogsearch-advanced-result {
    .search.found,
    .search.summary {
        padding-left: 1%;
    }
}