//
// Homepage cover
// --------------------------------------------------------------------------

.homepage-cover {
    position:relative;
    display: block;
    width:100%;
    margin: 0;
    font-size: $font-size-h3 + .4rem;
    font-weight: 300;
    letter-spacing: .5px;
    line-height: 1.3;
    text-align: center;
    @media (max-width: $screen-xs-min){
        height: 600px; font-weight: 500;
    }
    @media (max-width: 1200px){
        height: 380px;
    }

    .homepage-cover_img{
        height: 100%; width: 100%;

        img{
            object-fit: cover;
            width: 100%;
        }

        @media (max-width: $screen-xs-min){
            display: none;
        }
    }

    .homepage-cover_img_mobile{
        display: none;

        img{
            object-fit: cover;
            width: 100%;
            height: 600px;
        }
        
        @media (max-width: $screen-xs-min){
            display: block; 
        }
    }

    &__text {
        position:absolute;
        top:80px;
        right:6%;
        width:40%;
        text-align:left;

        @media(max-width: $screen-sm-min){
            top: 5%;
            left: 15%;
            width: 80%;
        }

        @media (max-width: $screen-xs-min){
            width: 80%; top: 10px;
            left: 1%;
        }

        & h1 {
            font-weight: 600;
            font-size: $font-size-h1;
            color: $color-primary;
            margin-bottom:20px;

            @media (max-width: $screen-xs-min){
                font-size: $font-size-base + 1;
            }
        }
        & p {
            position:relative;
            color: $color-gray-darker;
            font-size: $font-size-base + .2rem;
            font-weight: 400;
            &::after {
                content:'';
                position:absolute;
                bottom:-20px;
                left:0px;
                width:90px;
                height:2px;
                background:$color-gray-darker;

                @media (max-width: $screen-sm-max) {
                    font-size: $font-size-base; 
                }
                @media (max-width: $screen-xs-min){
                    font-size: $font-size-base - 0.5;
                }
            }           
        }
        & button {
            margin-top:40px;
            padding:8px 20px 10px 20px;
            color: $color-primary;
            font-size: $font-size-base;
            border-radius: 5;
            border: 1px solid $color-primary;
            background-color:transparent;
        }
    }
}
