.page-footer{
    width: 100%;
    background: $color-secondary;
    color: white;

    .copyright{
        color: white;
        width: 100%; 
        margin-top: 40px; 
        margin-left: 70px;
        @media(max-width:$screen-xs-min) {
            display: block;
            margin: auto;
        }
    }

}

.footer.content{ 
    width: 100%;
    max-width: 100%;
    padding: 0; 
    margin: 0;
    border: none; 
    position: relative;

    @media(max-width: $screen-xs-min){
        text-align: center;
        padding-top: 10%;
        padding-bottom: 10%;
    }
}

.footer_content{
    width: 100%; 
    height: auto; 
    display: block;
    position: relative;

    .footer_content_top{
        display: block; 
        position: relative;
        height: 120px; 
        padding-left: 50px;
        padding-right: 50px;
        @media(max-width:$screen-xs-min + 200px) {
            padding-left: inherit;
            padding-right: inherit;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-around;
        }
        @media(max-width:$screen-xs-min) {
            flex-direction: column;
        }
        .footer_content-logo{
            height: 92px; 
            width: 257px;
            float: left;

            @media(max-width: $screen-xs-min){
                float: none; 
                margin: auto;
            }

            .footer-logo{
                margin: auto; 
                height: auto; 
                max-width: 100%; 
                bottom: 0;
            }

        }
    }
    
    @media(max-width: $screen-xs-min){
        display: block;
        width: 100%;
    }   
    
    .footer_content-call{
        float: right; 
        height: 92px; 
        width: auto; 
        margin-right: 10px;
        line-height: 85px; 
        vertical-align: middle;

        a{
            color: white;
            border-color: white;
            text-transform: uppercase;
        }

        @media(max-width: $screen-xs-min){
            display: block; 
            position: relative;
            width: 100%; 
            margin-bottom: 20px;
            margin-right: 0;
        }
    }
}

.footer_content-nav{ 
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-bottom: 30px;
    width: 95%; 
    height: auto; 
    text-align: left; 
    background-color: $color-secondary;
    overflow: hidden;
    ul.first-level{
        display: flex; 
        justify-content: flex-start; 
        flex-wrap: wrap;
        margin: auto; 
        width: 90%;

        li{
            padding: 10px ; 
            text-transform: uppercase;
            max-width: inherit; 
            a {
                display: block;
            }
        }
    }

    ul.subnav{
        width: auto; 
        overflow: hidden; 
        margin-top: 30px;

        li{
            margin-top: 0px;
            text-transform: none; 
            text-align: left; 
            padding: 0px; 
            font-weight: 300;
        }
        a {
            display: inline-block;
        }
    }

    li{
        color: white;
    }

    a{
        color: white;
        &:visited{
            color:white;
        }
        &:hover{
            color: white;
        }
    }

    @media(max-width:980px){
        display: none;
    }
}

.footer_content-arrow{
    display: none;
    position: absolute;  
    right: 65px; 
    bottom: 19px;
    height: 25px; 
    width: 23px;
    background-color: $color-primary;
    font-size: 2em;
    text-align: center; 
    vertical-align: middle; 
    line-height: 8px;
    cursor: pointer;

    i {
        border: solid white;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
    }
    
    .up {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        //transition: 0.5s;
    }

    @media(max-width:980px){
        display: none;
    }
}