//
// Layout
// --------------------------------------------------------------------------


// Body
// --------------------------------------------------------------------------

body {
    display: flex;
    flex-direction: column;

    @media (min-width: $screen-sm-min) {
        padding: 0;
    }
}


// Wrapper
// --------------------------------------------------------------------------

.page-title-wrapper{
    font-size: 3rem; 
    color: $color-primary; 
    width: 100%; 
    margin: auto; 
    text-align: center; 
    padding-top: 16px;
    padding-left:1%; 
    padding-bottom: 16px;
    text-shadow: 1px 1px #ccc;
    .catalog-product-view & {
        display:none;
    }

    .catalog-category-view & {
        text-align:left;
    }
}

.wrapper {
    flex: 1 0 auto;
}

.page-wrapper  {
    max-width: $wrapper-max-width;
    width: 100%;
    margin: auto;
    @media(max-width: $screen-lg-min) {
        max-width: 100%;
        margin: inherit;
    }
}

.columns .column.main{
    padding-bottom: 0; 
    min-height: 400px;
    overflow: hidden;
}


// Container
// --------------------------------------------------------------------------

.container {
    @include container();
}

.page-main{
    background-color: white;
    margin-left: 0; 
    margin-right: 0;
    padding-left: 0; 
    padding-right: 0;
    width: 100%;
    max-width: 100%;
}


// Product View
// --------------------------------------------------------------------------
.catalog-product-view {
    .column.main {
        margin-bottom:140px;
    }
}