//
// Carousel - Owl Carousel (https://owlcarousel2.github.io/OwlCarousel2)
// --------------------------------------------------------------------------

.owl-carousel {
    position: relative;
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    z-index: 1;

    &.owl-loaded {
        display: block;
    }

    &.owl-loading {
        opacity: 0;
        display: block;
    }

    &.owl-hidden {
        opacity: 0;
    }

    &.owl-grab {
        cursor: grab;
    }

    .no-js & {
        display: block;
    }
}


// Stage outer
// --------------------------------------------------------------------------

.owl-stage-outer {
    position: relative;
    overflow: hidden;
    // fix for flashing background
    transform: translate3d(0,0,0);

  @media screen and (max-width: 470px){
    pointer-events: none;
    z-index: 2;
  }
}


// Stage
// --------------------------------------------------------------------------

.owl-stage {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    -ms-touch-action: pan-Y;
    -moz-backface-visibility: hidden;
}


// Wrapper
// --------------------------------------------------------------------------

.owl-wrapper,
.owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    transform: translate3d(0,0,0);
}


// Item
// --------------------------------------------------------------------------

.owl-item {
    position: relative;
    min-height: 1px;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;

    .owl-refresh & {
        visibility: hidden;
    }

    .owl-drag & {
        user-select: none;
    }
}


// Nav & dots
// --------------------------------------------------------------------------

.owl-nav,
.owl-dots {
    &.disabled {
        display: none;
    }
}

.owl-prev,
.owl-next {
    position: absolute;
    top: 50%;
    width: 44px;
    height: 44px;
    @include text-hide();
    background-color: $color-gray-light;
    transform: translate(0,-50%);
    @media screen and (max-width: 471px){
        display:none;
    }
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 16px;
        height: 2px;
        background-color: $color-gray-darker;
        border-radius: 2px;
        transform: translate(-50%,-50%);
    }

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: calc(50% - 3px);
        width: 11px;
        height: 11px;
        background-color: transparent;
        border: 2px solid $color-gray-darker;
        border-width: 0 2px 2px 0;
        transform: translate(-50%,-50%) rotate(135deg);
    }

    &.disabled {
        opacity: .25;
    }

    @media (min-width: $screen-md-min) {
        width: 50px;
    }
}

.owl-prev {
    left: 0;
    @include border-right-radius(50%);
}

.owl-next {
    right: 0;
    @include border-left-radius(50%);

    &::after {
        left: calc(50% + 3px);
        transform: translate(-50%,-50%) rotate(-45deg);
    }
}

.owl-prev,
.owl-next,
.owl-dot {
    cursor: pointer;
    user-select: none;
}


/* Arrow navigation on mobile */
.gauche,
.droite {
    position: absolute !important;
    z-index: 9000 !important;
    top: 50%;
    width: 44px;
    height: 44px;
    @include text-hide();
    background-color: white;
    transform: translate(0,-50%);
    @media screen and (min-width: 470px){
        display:none;
    }
  @media screen and (max-width: 470px){
    pointer-events: all;
  }
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 16px;
        height: 2px;
        background-color: $color-gray-darker;
        border-radius: 2px;
        transform: translate(-50%,-50%);


    }

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: calc(50% - 3px);
        width: 11px;
        height: 11px;
        background-color: transparent;
        border: 2px solid $color-gray-darker;
        border-width: 0 2px 2px 0;
        transform: translate(-50%,-50%) rotate(135deg);
    }

    &.disabled {
        opacity: .25;
    }

    @media (min-width: $screen-md-min) {
        width: 50px;
    }
}

.gauche {
    left: 0;
    @include border-right-radius(50%);
}

.droite {
    right: 0;
    @include border-left-radius(50%);

    &::after {
        left: calc(50% + 3px);
        transform: translate(-50%,-50%) rotate(-45deg);
    }
}

a.link-nav{
  @media screen and (max-width: 470px){
    pointer-events: none;
  }
}

.gauche,
.droite,
.owl-dot {
    cursor: pointer;
    user-select: none;
}