//
// Variables
// --------------------------------------------------------------------------


// Colors
// --------------------------------------------------------------------------

$color-gray-darker:             #333333;
$color-gray-dark:               #575757;
$color-gray:                    #989898;
$color-gray-light:              #e2e1e1;
$color-gray-lighter:            #f7f7fb;

$color-primary:                 #EF3340;
$color-primary-light:           #ed404c;
$color-secondary:               #2D2D2A;
$color-tertiary:                #FFFFFF;
$color-quaternary:              #7C7C7C;
$color-quinary:                 #ee385c;
$color-senary:                  #2898f8;

$color-info:                    #5bc0de;
$color-success:                 #5cb85c;
$color-warning:                 #f0ad4e;
$color-error:                   #d9534f;

$color-facebook:                #3b5998;
$color-twitter:                 #00aced;
$color-googleplus:              #dd4b39;
$color-instagram:               #517fa4;
$color-pinterest:               #cb2027;
$color-youtube:                 #cc181e;
$color-linkedin:                #007bb6;


// Core
// --------------------------------------------------------------------------

$body-bg:                       #fff;
$text-color:                    $color-gray-darker;
$link-color:                    $text-color;
$link-decoration:               none;
$link-hover-color:              $color-primary;


// Typography
// --------------------------------------------------------------------------

$font-family-sans-serif:        Helvetica, Arial, sans-serif;
$font-family-serif:             Georgia, "Times New Roman", Times, serif;
$font-family-custom:            'Roboto', sans-serif;

$font-family-base:              $font-family-custom;

$font-size-base:                1.6rem; // ~16px
$line-height-base:              1.3;
$line-height-computed:          floor(($font-size-base * $line-height-base));

$font-size-large:               1.8rem; // ~18px
$font-size-small:               1.2rem; // ~12px


// Z-index
// --------------------------------------------------------------------------

$zindex-dropdown:               100;
$zindex-fixed:                  200;


// Media queries breakpoints
// --------------------------------------------------------------------------

$screen-xs-min:                 480px;
$screen-sm-min:                 768px;
$screen-md-min:                 992px;
$screen-lg-min:                 1200px;
$screen-xl-min:                 1470px;

$screen-xs-max:                 ($screen-sm-min - 1);
$screen-sm-max:                 ($screen-md-min - 1);
$screen-md-max:                 ($screen-lg-min - 1);
$screen-lg-max:                 ($screen-xl-min - 1);


// Gutter
// --------------------------------------------------------------------------

$gutter-sm:                     24px;
$gutter-md:                     30px;

// Wrapper max width 
$wrapper-max-width:             1200px;
