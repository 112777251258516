.nav-sections{
    background: $body-bg;
    width: 100%;

    @media(min-width: $screen-sm-min){
        margin-top: 25px;

    }

    @media(max-width: $screen-sm-min){
        background: $color-secondary;
        width: calc(100% - 54px);
        padding-top:40px;
    }

    .logo-nav{
        display: none;
        @media(max-width: $screen-sm-min - 1px){
            display: block; 
            width: 70%; 
            height: auto;
        }
    }

    .navigation{
        background: $body-bg;
        text-align: center; 
        border: none;
        font-size:$font-size-base - .1rem;
        font-weight: 500;

        @media(min-width: $screen-sm-min){
            width: 100%; 
            max-width: inherit;

            .level0:last-child{
                margin-right: inherit; 
            }
        }

        .ui-menu-icon,
        .ui-menu-item.all-category {
            display:none !important;//here
        }

        li{
            &:hover{
                background-color: $color-secondary; 
                color: white;         
            }
        }


        li:hover a span{ 
            color: white;
        }
        a:hover{
            color: white;
        }

        li.level-top:hover{
            @media(min-width:$screen-sm-min){
                &:after {
                    content:'';
                    position:absolute;
                    bottom:0;
                    left:0;
                    width:100%;
                    height:3px;
                    background:$color-primary;
                }
            }
        }

        .level0 > .level-top{
            text-transform: uppercase;

            &:hover{
                color: white;
            }
        }

        .level0.active > a:not(.ui-state-active) span:not(.ui-menu-icon){ 
            margin-left: 0;
        }

        .navigation .level0{
            margin: 0;
            padding-left: 0;
            padding-right: 0;
             .nav-2 {
                min-width:90px;
            }
        }
        .level1 {
            width: 100%;
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-left: 8px;
            padding-right: 8px;
            line-height: 2;
            &.submenu {
                & li {
                    border-left:1px solid gray;
                }
            }
        }

        .level0 .submenu a:hover, 
        .level0 .submenu a.ui-state-focus{
            background: $color-secondary;
        }
        .level0 .submenu{
            background: $color-secondary;
            border: none;
            margin: 0; 
            padding: 0;
            width: auto;
            min-width: 100%; 

            & li {
                padding-left:8px;
                padding-right:8px;
            }

            @media(max-width: $screen-sm-min - 1px){
                margin-left: 30px;
            }

            .active > a {
                @media(max-width:768px){
                    border-color: $color-primary;
                }
                @media(min-width:768px){
                    border: none;
                }
            }

            a:hover{
                background: $color-secondary;
            }

            a{
                color: white; 
                font-weight: 500;
                text-align: left; 
                font-size: $font-size-base - .2rem;
                padding-left: 0; 
                padding-right: 0; 
                padding-top: 8px; 
                padding-bottom: 8px;
                margin-top: 0;
            }

            a:hover, 
            .navigation .level0 .submenu a.ui-state-focus{
                background: $color-secondary;
            }
        }

        .level0.active > .level-top,
         .level0.has-active > .level-top {
            border-color: $color-primary;
            border-width:100%;
            
        }

        @media(max-width:$screen-sm-min - 1px){
            text-align: left; 
            background: $color-secondary;

            a{ 
                color: white; 
            }

            li{ 
                border: none; 
                margin-bottom: 15px; 
                color: white; 
                font-weight: 500; 

                &:last-child{
                    border: none;
                }
            }

            .level0.active > a:not(.ui-state-active), 
            .level0.active .all-category .ui-state-focus, 
            .level0.active > .level-top{
                border-color: $color-primary;

                @media(min-width:$screen-sm-min - 1px){
                    border-color: $color-primary;
                }
            }

            span{ 
                color: white; 
                font-weight: 500; 
            }
            .level0{ 
                border: none; 
            }
        }
    }
}

.nav-open {
    .header.content {
        padding-top:60px;
        .logo {
            position:fixed;
            left:0;
            top:0;
            z-index:100;
        }
    }
}

//Reset
@media (min-width: 768px), print {
    .navigation .level0 .submenu .submenu-reverse {
        left: 100% !important;
        right: inherit;
    }
}

//Nav Mobile
@media only screen and (max-width: $screen-sm-min - 1px){
    .nav-sections-item-title {
        display: none;
    }

    .nav-sections-item-content{
        margin: 0;
        overflow: hidden;
    }
}

@media(min-width:$screen-sm-min + 1px) {
    .navigation{
        .level0 {
            margin: 0px;
            &.nav-2 {
                min-width:70px;
            }
        }
    }
    .navigation .level0.parent > .level-top {
        padding-right:12px;
    }
}