//Base container for static cms pages
.container-static_page {
    margin-top: 40px;
    height: 100%; 
    width: 100%; 
    margin: auto; 
    padding: 0px; 

    @media(max-width:$screen-sm-min) {
        padding-left:1%;
        padding-right:1%;
    }

    @media(max-width:$screen-xs-min){
        width: 100%;
        padding: 10px;
    }

    h1{
        font-size: 3rem; 
        color: $color-primary; 
        width: 80%; 
        margin: auto; 
        text-align: left; 
        padding-top: 16px; 
        padding-bottom: 16px;
        text-shadow: 1px 1px #ccc;

        @media(max-width:$screen-sm-min){
            width: 100%;
        }

    }

    h2{
        font-size: 2.5rem; 
        color: $color-gray-dark; 
        width: 80%; 
        padding: 16px; 
        margin: auto; 
        text-align: left;
        
        @media(max-width:$screen-sm-min){
            width: 100%;
        }

    }

    .simple-block_text{
        width: 100%; 
        margin: auto;
        margin-bottom: 28px; 
        margin-top: 40px;

        &:after{
            content: '';
            display: block;
            clear: both;
        }
        
        h1{
            font-size: 3rem; 
            color: $color-primary; 
            width: 80%; 
            margin: auto; 
            text-align: left; 
            padding-top: 16px; 
            padding-bottom: 16px;
            text-shadow: 1px 1px #ccc;

            @media(max-width:$screen-sm-min){
                width: 100%;
            }

        }

        h2{
            font-size: 2.5rem; 
            color: $color-gray-dark; 
            width: 80%; 
            padding: 16px 8px; 
            margin: auto; 
            text-align: left;
            
            @media(max-width:$screen-sm-min){
                width: 100%;
            }
        }

        p{
            font-size: 1.7rem; 
            width: 80%; 
            margin: auto; 
            padding: 8px;

            @media(max-width:$screen-sm-min){
                width: 100%;
            }
        }

        span.address{
            font-weight: 500; 
            font-style: italic;
        }

        a{
            color: $color-gray-dark; 
            font-weight: 600;
            text-decoration: underline !important;

            &:hover, 
            &:focus{
                text-decoration: underline !important; 
                color: $color-primary;
            }

            &:visited{
                color: $color-gray-darker;
                text-decoration:underline !important;

                &:hover{
                    color: $color-primary;
                }
            }
        }


        //block text with image
        .wrap-left { 
            width: 100%; 
            margin: auto;
            margin-bottom: 30px;
            
            p{
                width: 100%; 
                padding: 0;
            }
            
            .left { 
                width: 60%; 
                float: left; 
                padding-left: 10px; 
                padding-right: 10px;
                @media(max-width:1100px){
                    float: none; 
                    width: 80%; 
                    margin: auto;
                }

                @media(max-width:1024px){
                    margin-bottom: 10px;
                }

                @media(max-width:480px){
                    float: none; 
                    width: 99%; 
                    margin: auto;
                    margin-bottom: 10px;
                }
            }

            .right { 
                width: 40%; 
                float: right; 
                padding-left: 10px; 
                padding-right: 10px;
                @media(max-width:1100px){
                    float: none; 
                    width: 80%; 
                    margin: auto;
                }
                @media(max-width:480px){
                    float: none; 
                    width: 99%; 
                    margin: auto;
                }
            }

        }

        .wrap-right {
            width: 100%; 
            margin: auto; 
            margin-bottom: 20px;
        
            .left { 
                width: 60%; 
                float: left; 
                padding-left: 10px; 
                padding-right: 10px;
                @media(max-width:1100px){
                    float: none; 
                    width: 80%; 
                    margin: auto; 
                    margin-bottom: 30px;
                }

                @media(max-width:480px){
                    float: none; 
                    width: 99%; 
                    margin: auto; 
                    margin-bottom: 30px;
                }

                p {
                   padding-left: 0;
                }
            }
            
            .right { 
                float: right; 
                width: 40%;
                
                @media(max-width:1100px){
                    float: none; 
                    width: 80%; 
                    margin: auto;
                    margin-top: 20px;
                }

                @media(max-width:480px){
                    float: none; width: 99%; margin: auto;
                }
            }
        }
        
        //special class for sav mecabam
        .container-block-sav{
            width: 100%; 
            display: block;
            margin-top: 20px;

            p{
                width: 100%; 
                padding: 0; 
                margin-bottom: 20px;
            }

            .container-block-sav_left{
                width: 60%; 
                float: left;

                @media(max-width:768px){
                    float: none; 
                    width: 90%; 
                    margin: auto;
                }

                .wrap-sav{
                    width: 100%; 
                    margin: auto; 
                    margin-bottom: 20px;

                    &:after{
                        content: ''; 
                        display: block; 
                        clear: both;
                    }

                    .left{
                        width: 30%; 
                        float: left;
                    }

                    .right{
                        width: 70%; 
                        float: right; 
                        padding-left: 20px; 
                        padding-right: 20px;
                    }
                }
            }

            .container-block-sav_right{
                width: 40%; 
                float: right;

                @media(max-width:768px){
                    float: none; 
                    width: 90%; 
                    margin: auto;
                }

                .wrap-sav{
                    width: 100%; 
                    margin: auto; 
                    margin-bottom: 20px;

                    &:after{
                        content: ''; 
                        display: block; 
                        clear: both;
                    }

                    .left{
                        width: 40%; 
                        float: left;

                        @media(max-width:768px){
                            width: 30%;
                        }
                    }

                    .right{
                        width: 60%; 
                        float: right; 
                        padding-left: 20px;

                        @media(max-width:768px){
                            width: 70%;
                        }
                    }
                }
            }
        }


        //special block for Showroom

        .showroom-left{
            float: left; 
            width: 50%;

            @media(max-width:768px){
                float: none; 
                width: 90%; 
                margin: auto;
            }

            .content_showroom-left{
                width: 90%; 
                border: 2px solid $color-secondary;
                text-align: center;
                
                @media(max-width:768px){
                    width: 100%; 
                    margin: auto;
                }

                h2{
                    text-align: center;
                }

                .content_showroom-left-pic{
                    display: flex; 
                    margin-top: 10px; 
                    margin-bottom: 10px;
                    justify-content: space-around;

                    img{
                        height: auto; 
                        width: 150px;
                    }
                }

            }
        }

        .showroom-right{
            float: right; 
            width: 50%;

            @media(max-width:768px){
                float: none; 
                width: 90%; 
                margin: auto; 
                margin-top: 20px;
            }


            .content_showroom-right{
                width: 90%;

                @media(max-width:768px){
                    width: 100%; 
                    margin: auto;
                }

                .content_showroom-right-pic{
                    width: 40%; 
                    float: left;
                }

                .content_showroom-right-text{
                    width: 60%; 
                    float: right;

                    p{
                        width: 80%; 
                        margin-left: 0;
                    }
                }
            }
        }
    }

    //default container form
    .container-form{
        width: 60%; 
        margin: auto; 
        margin-top: 20px;

        @media(max-width:$screen-sm-min){
            width: 90%;
        }

        input{
            border-radius: 6px;
        }
    }


    //container for Gmap
    .page-store__map{
        width: 60%; 
        margin: auto;
        margin-bottom:46px;
        text-align: center;

        @media(max-width:$screen-sm-min){
            width: 100%; 
            overflow: hidden;
        }

        .page-store__map-iframe{
            #map{
                min-height: 400px; 
                border-radius: 10px;
            }
        }

        iframe{
            width: 100%;
            height: 400px; 
            border-radius: 20px; 
            border: 1px solid #ccc;
        }
    }
}


//Resets

.fieldset > .field:not(.choice) > .label{
    text-align: left;
}