.form.search.advanced{
    width: 60%; 
    margin: auto;
    padding: 1%; 
    margin-top: 5%;
    label.label{
        text-align: left;
    }
    legend.legend{
        margin-left: 0; 
        position: relative;
        margin-bottom: 25px;
    }
    .primary{
        width: 100%; 
        margin-bottom: 20px;
    }
    input{
        border-radius: 4px;
    }
}

.search-autocomplete{
    top: 3px;
    ul{
        border: none;
        li{
            border: none;
            &:hover{
                background: white;
            }
        }
        li.selected{
            background: white;
        }
    } 
    .amount{
        display: none;
    }
}