//
// Page blocks
// --------------------------------------------------------------------------

// Documentation
// --------------------------------------------------------------------------

/*doc
---
title: Blocks
name: blocks
category: Components
---
This allow to organize CMS page content in blocks with image and text. On large screens the blocks are listed tow by two.

```html_example
<div class="page-blocks">
    <div class="page-block">
        <h2 class="page-block__title">Sed posuere consectetur est at lobortis</h2>
        <div class="page-block__content">
            <div class="page-block__image">
                <img src="https://dummyimage.com/120x120/000/fff" alt="">
            </div>
            <div class="page-block__text">
                <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus sagittis lacus
                    vel augue laoreet rutrum faucibus dolor auctor. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cras mattis consectetur purus sit amet fermentum. Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
            </div>
        </div>
    </div>
    <div class="page-block">
        <h2 class="page-block__title">Sed posuere consectetur est at lobortis</h2>
        <div class="page-block__content">
            <div class="page-block__image">
                <img src="https://dummyimage.com/120x120/000/fff" alt="">
            </div>
            <div class="page-block__text">
                <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus sagittis lacus
                    vel augue laoreet rutrum faucibus dolor auctor. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cras mattis consectetur purus sit amet fermentum. Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
            </div>
        </div>
    </div>
    <div class="page-block">
        <h2 class="page-block__title">Sed posuere consectetur est at lobortis</h2>
        <div class="page-block__content">
            <div class="page-block__image">
                <img src="https://dummyimage.com/120x120/000/fff" alt="">
            </div>
            <div class="page-block__text">
                <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus sagittis lacus
                    vel augue laoreet rutrum faucibus dolor auctor. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cras mattis consectetur purus sit amet fermentum. Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
            </div>
        </div>
    </div>
    <div class="page-block">
        <h2 class="page-block__title">Sed posuere consectetur est at lobortis</h2>
        <div class="page-block__content">
            <div class="page-block__image">
                <img src="https://dummyimage.com/120x120/000/fff" alt="">
            </div>
            <div class="page-block__text">
                <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus sagittis lacus
                    vel augue laoreet rutrum faucibus dolor auctor. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cras mattis consectetur purus sit amet fermentum. Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
            </div>
        </div>
    </div>
</div>
```
*/


// Blocks
// --------------------------------------------------------------------------

.page-blocks {
    @media (min-width: $screen-md-min) {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
}


// Block
// --------------------------------------------------------------------------

.page-block {
    margin: 0 0 16px;
    padding: 16px;
    background-color: lighten($color-gray-light, 5%);

    &:nth-child(2n) {
        margin-right: 0;
    }

    @media (min-width: $screen-md-min) {
        width: calc(50% - 14px);
        margin: 0 24px 24px 0;
        padding: 24px; display: inline-block;
    }
}

.page-block__title {
    display: block;
    margin: 0 0 16px;
    font-size: $font-size-large + .2rem;
    font-weight: bold;
    letter-spacing: .5px;
    text-transform: none;
}

.page-block__content {
    display: flex;
    align-items: center;
    width: 100%;
}

.page-block__image {
    flex: 1 0 auto;
    width: 100%;
    height: 125px;
    max-width: 120px;
    margin: 0 8px 0 0;
    display: table-cell;
    vertical-align: middle;
    text-align:center;
    @media (min-width: $screen-md-min) {
        margin: 0 16px 0 0;
        padding: 1%;
        background-color: white;
    }
}