@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
footer,
header,
nav,
section {
  display: block; }

figcaption,
figure,
main {
  display: block; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

pre {
  font-family: monospace, monospace;
  font-size: 1em; }

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: inherit; }

b,
strong {
  font-weight: bolder; }

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

dfn {
  font-style: italic; }

mark {
  background-color: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

audio,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  display: inline-block;
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details,
menu {
  display: block; }

summary {
  display: list-item; }

canvas {
  display: inline-block; }

template {
  display: none; }

[hidden] {
  display: none; }

@keyframes spin {
  to {
    transform: rotate(360deg); } }

html:not(.no-js) .collapse {
  display: none; }
  html:not(.no-js) .collapse.in {
    display: block; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-property: height, visibility;
  transition-duration: .35s;
  transition-timing-function: ease; }

.loading-mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 10; }
  .loading-mask::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin: -25px 0 0 -25px;
    border: 4px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    z-index: 1; }
  .loading-mask .loader {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
    border: 0; }

.todo {
  position: relative; }
  .todo::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 0, 0, 0.1);
    z-index: -1; }
  .todo::after {
    content: "@todo" !important;
    position: absolute;
    bottom: 100%;
    left: 0;
    padding: 5px 10px;
    font-size: 1.6rem;
    font-weight: bold;
    text-transform: uppercase;
    color: red;
    background-color: rgba(255, 0, 0, 0.1);
    z-index: 200; }

html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

html {
  min-height: 100%;
  font-size: 62.5%;
  font-size: calc(1em * .625);
  -webkit-tap-highlight-color: transparent; }
  @media (min-width: 768px) {
    html {
      height: 100%; } }

body {
  position: relative;
  height: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  line-height: 1.3;
  color: #333333;
  background-color: #fff; }

a {
  color: white; }

a:visited {
  color: white; }

a:hover {
  color: white;
  text-decoration: none; }

ul > li, ol > li {
  margin: 0; }
  ul > li:hover, ol > li:hover {
    background-color: transparent; }

ul:hover {
  background-color: transparent; }

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

img {
  display: block;
  max-width: 100%;
  height: auto; }

svg[class^="icon"] {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor; }

.mobile {
  height: auto; }
  @media (max-width: 480px) {
    .mobile {
      display: block; } }
  @media (min-width: 480px) {
    .mobile {
      display: none; } }

/*doc
---
title: Colors
name: 00_Colors
category: Colors
---

```html_example
<div class="colors">
    <div class="bgcolor-primary">
        <p>Primary color</p>
    </div>
    <div class="bgcolor-primary-light">
        <p>Primary light color</p>
    </div>
    <div class="bgcolor-secondary">
        <p>Secondary color</p>
    </div>
    <div class="bgcolor-tertiary">
        <p>Tertiary color</p>
    </div>
    <div class="bgcolor-quaternary">
        <p>Quaternary color</p>
    </div>
    <div class="bgcolor-quinary">
        <p>Quinary color</p>
    </div>
    <div class="bgcolor-senary">
        <p>Senary color</p>
    </div>
    <div class="bgcolor-info">
        <p>Info color</p>
    </div>
    <div class="bgcolor-success">
        <p>Success color</p>
    </div>
    <div class="bgcolor-warning">
        <p>Warning color</p>
    </div>
    <div class="bgcolor-error">
        <p>Error color</p>
    </div>
</div>
```

*/
.colors {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }

.colors > div {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;
  margin: 0 25px 75px 0; }
  .colors > div p {
    position: absolute;
    width: 100%;
    height: 40px;
    bottom: -60px;
    left: 0px;
    text-align: center;
    vertical-align: top;
    font-weight: bold; }
  .colors > div.bgcolor-primary {
    background: #EF3340; }
    .colors > div.bgcolor-primary::before {
      content: "#EF3340";
      position: absolute;
      width: 100%;
      height: 30px;
      top: 10px;
      left: 0px;
      text-align: center;
      vertical-align: top;
      font-weight: bold; }
  .colors > div.bgcolor-primary-light {
    background: #ed404c; }
    .colors > div.bgcolor-primary-light::before {
      content: "#ed404c";
      position: absolute;
      width: 100%;
      height: 30px;
      top: 10px;
      left: 0px;
      text-align: center;
      vertical-align: top;
      font-weight: bold; }
  .colors > div.bgcolor-secondary {
    background: #2D2D2A; }
    .colors > div.bgcolor-secondary::before {
      content: "#2D2D2A";
      position: absolute;
      width: 100%;
      height: 30px;
      top: 10px;
      left: 0px;
      text-align: center;
      vertical-align: top;
      font-weight: bold; }
  .colors > div.bgcolor-tertiary {
    background: #FFFFFF; }
    .colors > div.bgcolor-tertiary::before {
      content: "#FFFFFF";
      position: absolute;
      width: 100%;
      height: 30px;
      top: 10px;
      left: 0px;
      text-align: center;
      vertical-align: top;
      font-weight: bold; }
  .colors > div.bgcolor-quaternary {
    background: #7C7C7C; }
    .colors > div.bgcolor-quaternary::before {
      content: "#7C7C7C";
      position: absolute;
      width: 100%;
      height: 30px;
      top: 10px;
      left: 0px;
      text-align: center;
      vertical-align: top;
      font-weight: bold; }
  .colors > div.bgcolor-quinary {
    background: #ee385c; }
    .colors > div.bgcolor-quinary::before {
      content: "#ee385c";
      position: absolute;
      width: 100%;
      height: 30px;
      top: 10px;
      left: 0px;
      text-align: center;
      vertical-align: top;
      font-weight: bold; }
  .colors > div.bgcolor-senary {
    background: #2898f8; }
    .colors > div.bgcolor-senary::before {
      content: "#2898f8";
      position: absolute;
      width: 100%;
      height: 30px;
      top: 10px;
      left: 0px;
      text-align: center;
      vertical-align: top;
      font-weight: bold; }
  .colors > div.bgcolor-info {
    background: #5bc0de; }
    .colors > div.bgcolor-info::before {
      content: "#5bc0de";
      position: absolute;
      width: 100%;
      height: 30px;
      top: 10px;
      left: 0px;
      text-align: center;
      vertical-align: top;
      font-weight: bold; }
  .colors > div.bgcolor-success {
    background: #5cb85c; }
    .colors > div.bgcolor-success::before {
      content: "#5cb85c";
      position: absolute;
      width: 100%;
      height: 30px;
      top: 10px;
      left: 0px;
      text-align: center;
      vertical-align: top;
      font-weight: bold; }
  .colors > div.bgcolor-warning {
    background: #f0ad4e; }
    .colors > div.bgcolor-warning::before {
      content: "#f0ad4e";
      position: absolute;
      width: 100%;
      height: 30px;
      top: 10px;
      left: 0px;
      text-align: center;
      vertical-align: top;
      font-weight: bold; }
  .colors > div.bgcolor-error {
    background: #d9534f; }
    .colors > div.bgcolor-error::before {
      content: "#d9534f";
      position: absolute;
      width: 100%;
      height: 30px;
      top: 10px;
      left: 0px;
      text-align: center;
      vertical-align: top;
      font-weight: bold; }

/*doc
---
title: Headings
name: 01_Headings
category: Text
---

```html_example
<h1>h1. Heading</h1>
<h2>h2. Heading</h2>
<h3>h3. Heading</h3>
<h4>h4. Heading</h4>
<h5>h5. Heading</h5>
<h6>h6. Heading</h6>
```

*/
/*doc
---
title: Texts
name: Texts
category: Text
---

```html_example
<p>Nullam quis risus eget urna mollis ornare vel eu leo. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nullam id dolor id nibh ultricies vehicula.</p>
<p>Cum sociis natoque penatibus et magnis dis parturient montes, <a href="#">nascetur ridiculus mus</a>. Donec ullamcorper nulla non metus auctor fringilla. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Donec <strong>ullamcorper nulla</strong> non metus auctor fringilla.</p>
<p>Maecenas sed diam eget risus varius blandit sit amet non magna. Donec id elit non mi porta <em>gravida at eget metus</em>. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p>
```

*/
/*doc
---
title: Lists
name: Lists
category: Text
---

A list of items, unordered or ordered

```html_example
<ul>
    <li>Lorem ipsum dolor sit amet</li>
    <li>Consectetur adipiscing elit</li>
    <li>Nulla volutpat aliquam velit
        <ul>
            <li>Phasellus iaculis neque</li>
            <li>Purus sodales ultricies</li>
        </ul>
    </li>
    <li>Faucibus porta lacus fringilla vel</li>
    <li>Aenean sit amet erat nunc</li>
</ul>

<ol>
    <li>Lorem ipsum dolor sit amet</li>
    <li>Consectetur adipiscing elit</li>
    <li>Nulla volutpat aliquam velit</li>
    <li>Phasellus iaculis neque</li>
    <li>Purus sodales ultricies</li>
    <li>Faucibus porta lacus fringilla vel</li>
    <li>Aenean sit amet erat nunc</li>
</ol>
```
*/
/*doc
---
title: Links
name: Links
category: Text
---
```html_example
<a>Exemple de lien</a>
```


*/
h1, h2, h3, h4, h5, h6 {
  margin: 1rem 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 1.15;
  letter-spacing: 0.5px; }

h1, h2, h3 {
  margin-top: 1.5rem; }

h1 {
  font-size: 4.2rem; }

h2 {
  font-size: 3.4rem; }

h3 {
  font-size: 2.2rem; }

h4 {
  font-size: 2rem; }

h5 {
  font-size: 1.8rem; }

h6 {
  font-size: 1.7rem; }

p {
  margin: 0 0 1rem; }

ul,
ol {
  margin-top: 0;
  margin-bottom: 1.5rem; }
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-bottom: 0; }

a {
  text-decoration: none;
  color: #333333;
  cursor: pointer; }
  a:focus {
    color: #EF3340; }
  @media (min-width: 992px) {
    a:hover {
      color: #EF3340; } }

/*

// Source Sans Pro Light
@font-face {
    @include font-face('Source Sans Pro', 'sourcesanspro', 'sourcesanspro-light-webfont', 300);
}

// Source Sans Pro Regular
@font-face {
    @include font-face('Source Sans Pro', 'sourcesanspro', 'sourcesanspro-regular-webfont', 400);
}

// Source Sans Pro Semi-Bold
@font-face {
    @include font-face('Source Sans Pro', 'sourcesanspro', 'sourcesanspro-semibold-webfont', 600);
}

// Source Sans Pro Bold
@font-face {
    @include font-face('Source Sans Pro', 'sourcesanspro', 'sourcesanspro-bold-webfont', 700);
}

*/
/*doc
---
title: Buttons
name: 03_Buttons
category: Buttons
---
The `btn` classes are designed to be used with the `<button>` element. However, you can also use these classes on `<a>` or `<input>` elements (though some browsers may apply a slightly different rendering).

When using button classes on `<a>` elements that are used to trigger in-page functionality (like collapsing content), rather than linking to new pages or sections within the current page, these links should be given a `role="button"` to appropriately convey their purpose to assistive technologies such as screen readers.

```html_example_table
<a href="#" class="btn btn--default" role="button">Link</a>

<button type="button" class="btn btn--default">Button</button>

<input type="button" value="Input" class="btn btn--default">

<input type="submit" value="Submit" class="btn btn--default">

<input type="reset" value="Reset" class="btn btn--default">
```

## Button styles

There are three styles for buttons: default, primary and secondary. Simply add the style modifier class for the desired style.

```html_example_table
<button type="button" class="btn btn--default">Button default</button>

<button type="button" class="btn btn--primary">Button primary</button>

<button type="button" class="btn btn--secondary">Button secondary</button>

<button type="button" class="btn btn--tertiary">Button tertiary</button>

<button type="button" class="btn btn--quaternary">Button quaternary</button>
```

## Button sizes

There are three 3 sizes for buttons: Large, medium (default) and small. Simply add the size modifier class for the desired size.

```html_example_table
<button type="button" class="btn btn--primary btn--lg">Button large</button>

<button type="button" class="btn btn--primary btn--sm">Button small</button>
```

## Button disabled state

Make buttons look inactive by adding the `disabled` boolean attribute to any `<button>` element.

```html_example
<button type="button" class="btn btn--primary" disabled>Disabled button</button>
```

Disabled buttons using the `<a>` element behave a bit different:
* `<a>` don’t support the disabled attribute, so you must add the `disabled` class to make it visually appear disabled.
* Some future-friendly styles are included to disable all pointer-events on anchor buttons. In browsers which support that property, you won’t see the disabled cursor at all.
* Disabled buttons should include the `aria-disabled="true"` attribute to indicate the state of the element to assistive technologies.

```html_example
<a href="#" class="btn btn--primary disabled" role="button" aria-disabled="true">Disabled link</a>
```

In addition, even in browsers that do support `pointer-events: none`, keyboard navigation remains unaffected, meaning that sighted keyboard users and users of assistive technologies will still be able to activate these links. So to be safe, add a `tabindex="-1"` attribute on these links (to prevent them from receiving keyboard focus) and use custom JavaScript to disable their functionality.

## Button link

In particular cases, you may want to use `<button>` tag but have the display and behavior of a classic link. To do that, add the `btn--link` modifier class.

```html_example
<p>Maecenas sed diam eget risus varius <button type="button" class="btn btn--link">blandit</button> sit amet non magna.</p>
```
*/
.btn, .modal-footer .action-primary,
.modal-footer .action-secondary {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  padding: 12px 40px;
  font-size: 1.6rem;
  line-height: 1.1;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  white-space: nowrap;
  text-transform: none;
  text-overflow: ellipsis;
  text-decoration: none;
  text-align: center;
  background-image: none;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  touch-action: manipulation;
  user-select: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out; }
  .btn:hover, .modal-footer .action-primary:hover,
  .modal-footer .action-secondary:hover, .btn:focus, .modal-footer .action-primary:focus,
  .modal-footer .action-secondary:focus {
    text-decoration: none;
    outline: 0; }
  .btn:active, .modal-footer .action-primary:active,
  .modal-footer .action-secondary:active {
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn.disabled, .modal-footer .disabled.action-primary,
  .modal-footer .disabled.action-secondary, .btn[disabled], .modal-footer [disabled].action-primary,
  .modal-footer [disabled].action-secondary,
  fieldset[disabled] .btn,
  fieldset[disabled] .modal-footer .action-primary, .modal-footer
  fieldset[disabled] .action-primary,
  fieldset[disabled]
  .modal-footer .action-secondary,
  .modal-footer
  fieldset[disabled] .action-secondary {
    cursor: not-allowed;
    pointer-events: none;
    opacity: .5;
    box-shadow: none; }
  .btn .icon, .modal-footer .action-primary .icon,
  .modal-footer .action-secondary .icon {
    width: 1.5em;
    height: 1.5em;
    margin: -5px 8px -5px 0;
    vertical-align: top; }

.btn--default {
  color: #fff;
  background-color: #EF3340;
  text-transform: uppercase; }
  .btn--default:active {
    background-image: none; }
  .btn--default:hover, .btn--default:focus, .btn--default:active {
    color: #fff;
    background-color: #ef335f; }
  .btn--default.disabled, .btn--default.disabled:hover, .btn--default.disabled:focus, .btn--default.disabled:active, .btn--default[disabled], .btn--default[disabled]:hover, .btn--default[disabled]:focus, .btn--default[disabled]:active,
  fieldset[disabled] .btn--default,
  fieldset[disabled] .btn--default:hover,
  fieldset[disabled] .btn--default:focus,
  fieldset[disabled] .btn--default:active {
    background-color: #EF3340; }

.btn--primary, .modal-footer .action-primary {
  color: #fff;
  background-color: #EF3340; }
  .btn--primary:active, .modal-footer .action-primary:active {
    background-image: none; }
  .btn--primary:hover, .modal-footer .action-primary:hover, .btn--primary:focus, .modal-footer .action-primary:focus, .btn--primary:active, .modal-footer .action-primary:active {
    color: #fff;
    background-color: #f3626c; }
  .btn--primary.disabled, .modal-footer .disabled.action-primary, .btn--primary.disabled:hover, .modal-footer .disabled.action-primary:hover, .btn--primary.disabled:focus, .modal-footer .disabled.action-primary:focus, .btn--primary.disabled:active, .modal-footer .disabled.action-primary:active, .btn--primary[disabled], .modal-footer [disabled].action-primary, .btn--primary[disabled]:hover, .modal-footer [disabled].action-primary:hover, .btn--primary[disabled]:focus, .modal-footer [disabled].action-primary:focus, .btn--primary[disabled]:active, .modal-footer [disabled].action-primary:active,
  fieldset[disabled] .btn--primary,
  fieldset[disabled] .modal-footer .action-primary, .modal-footer
  fieldset[disabled] .action-primary,
  fieldset[disabled] .btn--primary:hover,
  fieldset[disabled] .modal-footer .action-primary:hover, .modal-footer
  fieldset[disabled] .action-primary:hover,
  fieldset[disabled] .btn--primary:focus,
  fieldset[disabled] .modal-footer .action-primary:focus, .modal-footer
  fieldset[disabled] .action-primary:focus,
  fieldset[disabled] .btn--primary:active,
  fieldset[disabled] .modal-footer .action-primary:active, .modal-footer
  fieldset[disabled] .action-primary:active {
    background-color: #EF3340; }

.btn--secondary {
  color: #EF3340;
  background-color: transparent;
  border: solid 2px #EF3340; }
  .btn--secondary:active {
    background-image: none; }
  .btn--secondary:hover, .btn--secondary:focus, .btn--secondary:active {
    color: #EF3340;
    background-color: transparent; }
  .btn--secondary.disabled, .btn--secondary.disabled:hover, .btn--secondary.disabled:focus, .btn--secondary.disabled:active, .btn--secondary[disabled], .btn--secondary[disabled]:hover, .btn--secondary[disabled]:focus, .btn--secondary[disabled]:active,
  fieldset[disabled] .btn--secondary,
  fieldset[disabled] .btn--secondary:hover,
  fieldset[disabled] .btn--secondary:focus,
  fieldset[disabled] .btn--secondary:active {
    background-color: transparent; }

.btn--tertiary {
  color: #fff;
  background-color: #EF3340;
  border: solid 2px #FFFFFF; }
  .btn--tertiary:active {
    background-image: none; }
  .btn--tertiary:hover, .btn--tertiary:focus, .btn--tertiary:active {
    color: #fff;
    background-color: #ef335f; }
  .btn--tertiary.disabled, .btn--tertiary.disabled:hover, .btn--tertiary.disabled:focus, .btn--tertiary.disabled:active, .btn--tertiary[disabled], .btn--tertiary[disabled]:hover, .btn--tertiary[disabled]:focus, .btn--tertiary[disabled]:active,
  fieldset[disabled] .btn--tertiary,
  fieldset[disabled] .btn--tertiary:hover,
  fieldset[disabled] .btn--tertiary:focus,
  fieldset[disabled] .btn--tertiary:active {
    background-color: #EF3340; }

.btn--quaternary {
  color: #2D2D2A;
  background-color: transparent;
  border: solid 2px #2D2D2A; }
  .btn--quaternary:active {
    background-image: none; }
  .btn--quaternary:hover, .btn--quaternary:focus, .btn--quaternary:active {
    color: #2D2D2A;
    background-color: transparent; }
  .btn--quaternary.disabled, .btn--quaternary.disabled:hover, .btn--quaternary.disabled:focus, .btn--quaternary.disabled:active, .btn--quaternary[disabled], .btn--quaternary[disabled]:hover, .btn--quaternary[disabled]:focus, .btn--quaternary[disabled]:active,
  fieldset[disabled] .btn--quaternary,
  fieldset[disabled] .btn--quaternary:hover,
  fieldset[disabled] .btn--quaternary:focus,
  fieldset[disabled] .btn--quaternary:active {
    background-color: transparent; }
  .btn--quaternary:hover {
    border: 2px solid #2D2D2A;
    background-color: rgba(255, 255, 255, 0.2); }

.btn--intervention {
  color: #fff;
  background-color: #EF3340;
  font-size: 2.5rem; }
  .btn--intervention:active {
    background-image: none; }
  .btn--intervention:hover, .btn--intervention:focus, .btn--intervention:active {
    color: #fff;
    background-color: #f3626c; }
  .btn--intervention.disabled, .btn--intervention.disabled:hover, .btn--intervention.disabled:focus, .btn--intervention.disabled:active, .btn--intervention[disabled], .btn--intervention[disabled]:hover, .btn--intervention[disabled]:focus, .btn--intervention[disabled]:active,
  fieldset[disabled] .btn--intervention,
  fieldset[disabled] .btn--intervention:hover,
  fieldset[disabled] .btn--intervention:focus,
  fieldset[disabled] .btn--intervention:active {
    background-color: #EF3340; }
  .btn--intervention a {
    font-size: 2rem; }

.btn--lg {
  padding: 15px 45px;
  font-size: 1.9rem;
  line-height: 1.1; }

.btn--sm {
  padding: 8px 25px;
  font-size: 1.6rem;
  line-height: 1.2; }

.btn--link, .modal-footer .action-secondary {
  display: inline;
  vertical-align: baseline;
  padding: 0;
  font-size: 1.7rem;
  font-weight: normal;
  line-height: 1.3;
  text-transform: none;
  text-decoration: none;
  text-align: left;
  color: #989898;
  transition: color .1s ease-in-out;
  border-radius: 0; }
  .btn--link, .modal-footer .action-secondary, .btn--link:active, .modal-footer .action-secondary:active, .btn--link[disabled], .modal-footer [disabled].action-secondary,
  fieldset[disabled] .btn--link,
  fieldset[disabled] .modal-footer .action-secondary, .modal-footer
  fieldset[disabled] .action-secondary {
    background-color: transparent;
    box-shadow: none; }
  .btn--link:hover, .modal-footer .action-secondary:hover, .btn--link:focus, .modal-footer .action-secondary:focus, .btn--link:active, .modal-footer .action-secondary:active {
    text-decoration: none;
    color: #EF3340;
    background-color: transparent; }
  .btn--link:active, .modal-footer .action-secondary:active {
    box-shadow: none; }

.page-header {
  margin-bottom: 0; }

.section-head {
  margin: 0 0 15px;
  padding: 10px 0 0; }
  .catalog-category-view .section-head,
  .catalog-product-view .section-head,
  .catalogsearch-result-index .section-head {
    min-height: 44px;
    margin: 0 0 10px;
    padding-left: 44px; }
  @media (min-width: 768px) {
    .section-head {
      margin: 30px 0;
      padding: 0; }
      .catalog-category-view .section-head,
      .catalog-product-view .section-head,
      .catalogsearch-result-index .section-head {
        min-height: inherit;
        margin: 30px 0 15px;
        padding-left: 0; } }

.section-head__title {
  display: block;
  margin: 0;
  font-size: 2.2rem;
  font-weight: 300; }
  @media (min-width: 768px) {
    .section-head__title {
      font-size: 3.4rem; } }

.section-head__infos {
  display: block;
  margin: 8px 0 0;
  font-size: 1.9rem;
  font-weight: bold;
  line-height: 1.3; }
  @media (min-width: 768px) {
    .section-head__infos {
      font-size: 2rem; } }

.section-head__status,
.section-head__date {
  display: block;
  font-size: 1.7rem;
  letter-spacing: .5px; }

.section-head__status {
  margin: 8px 0 4px; }

.headline {
  display: block;
  margin: 16px 0;
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: .5px;
  line-height: 1.3;
  text-align: center; }
  .headline strong {
    font-weight: 600; }
  @media (min-width: 768px) {
    .headline {
      margin: 32px 0;
      font-size: 3.8rem;
      letter-spacing: .7px; } }
  @media (min-width: 1200px) {
    .headline strong {
      display: block; } }

.error-content {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }
  .error-content li {
    list-style-type: none; }
    .error-content li:before {
      content: "- "; }
  .error-content .error-subtitle {
    font-size: 2.2rem;
    color: #EF3340; }

/*doc
---
title: Messages
name: messages
category: Forms
---
Message is used to display information to the user. This message can be an information, a success message, a warning, or a error.

```html_example
<div class="message message--info">
    Donec id elit non mi porta gravida at eget metus. Vestibulum id ligula porta felis euismod semper.
</div>

<div class="message message--success">
    Donec id elit non mi porta gravida at eget metus. Vestibulum id ligula porta felis euismod semper.
</div>

<div class="message message--warning">
    Donec id elit non mi porta gravida at eget metus. Vestibulum id ligula porta felis euismod semper.
</div>

<div class="message message--error">
    Donec id elit non mi porta gravida at eget metus. Vestibulum id ligula porta felis euismod semper.
</div>
```
*/
.messages {
  position: fixed;
  left: 0;
  bottom: 13px;
  width: 100%;
  z-index: 1; }

.message {
  padding: 15px 15px !important;
  margin: 20px 0;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px; }
  .message > div {
    font-size: 15px; }
  .message ul {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none; }
  .message li {
    margin: 0 0 5px; }
    .message li:last-child {
      margin: 0; }
  .message p:last-child {
    margin: 0; }
  .message a {
    font-weight: bold; }

.message--info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1; }
  .message--info a {
    color: #245269; }

.message--success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6; }
  .message--success a {
    color: #2b542c; }

.message--warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc; }
  .message--warning a {
    color: #66512c; }

.message--error {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1; }
  .message--error a {
    color: #843534; }

.message.success {
  background-color: #1d8d23;
  color: white;
  width: 40%;
  margin: auto;
  text-align: center; }
  .message.success > *:first-child:before {
    top: 50%; }
  @media (max-width: 480px) {
    .message.success {
      width: 80%; } }

.message.info, .message.notice {
  text-align: center;
  width: 50%;
  margin: auto;
  background-color: #fb8c00; }

.message.error {
  text-align: center;
  width: 50%;
  margin: auto;
  background-color: #d50000; }

/*doc
---
title: Tables
name: tables
category: Components
---
To add basic styling to a table, add the base class `table` to any `<table>`. It may seem super redundant, but it's to avoid conflicts with other use of the `<table>` markup.

You can add `table--responsive` class to `table`s and it will make them mobile friendly by enabling horizontal scrolling. Only applies &lt;768px. Everything above that will display normally. This isn't included with the `table` class because it will depend on the table size. Also other possibilities exist to make `<table>` mobile friendly, this one is just the more generic.

```html_example
<table class="table table--responsive">
    <thead>
        <tr>
            <th>#</th>
            <th>First</th>
            <th>Second</th>
            <th>Third</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <th scope="row">1</th>
            <td>First</td>
            <td>Second</td>
            <td>Third</td>
        </tr>
        <tr>
            <th scope="row">2</th>
            <td>First</td>
            <td>Second</td>
            <td>Third</td>
        </tr>
        <tr>
            <th scope="row">3</th>
            <td>First</td>
            <td>Second</td>
            <td>Third</td>
        </tr>
    </tbody>
</table>
```
*/
table {
  background-color: transparent;
  border-collapse: collapse; }

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
  color: #575757; }

th {
  text-align: left; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  background-color: #fff; }
  .table th,
  .table td {
    padding: 8px;
    vertical-align: top;
    font-weight: 600;
    color: #333333;
    border: 1px solid #d2d2d2;
    border-width: 1px 0 0; }
  .table thead th {
    font-weight: 700;
    white-space: nowrap;
    vertical-align: bottom;
    color: #fff;
    background-color: #EF3340;
    border-color: #d2d2d2;
    border-width: 0 0 1px; }
  @media (min-width: 768px) {
    .table th,
    .table td {
      padding: 12px; } }

@media screen and (max-width: 767px) {
  .table--responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar; } }

/*doc
---
title: Forms
name: forms
category: Forms
---
Individual form controls automatically receive some global styling. All textual `<input>`, `<textarea>`, and `<select>` elements with `.form-control` are set to `width: 100%;` by default. Wrap labels and controls in .form-group for optimum spacing.

```html_example
<form>
    <div class="form-group">
        <label for="example-input">Input text</label>
        <input type="text" id="example-input" class="form-control" placeholder="Placeholder">
    </div>
    <div class="form-group">
        <label for="example-textarea">Textarea</label>
        <textarea id="example-textarea" class="form-control" rows="6"></textarea>
    </div>
</form>
```

## Selects

Select have differents styles between browsers and some styles that cannot be modified. To be able to be consistent across browsers and have a custom style, the the `select` is wrapped with a `form-control-select` class.

```html_example
<label for="example-select">Select</label>
<div class="form-control-select">
    <select id="example-select" class="form-control">
        <option>Option 1</option>
        <option>Option 2</option>
        <option>Option 3</option>
        <option>Option 4</option>
    </select>
</div>
```

To be disabled, the custom `select` require to add a `disabled` class in addition to the `disabled` attribute on the `select` itself.

```html_example
<label for="example-select">Select</label>
<div class="form-control-select disabled">
    <select id="example-select" class="form-control" disabled>
        <option>Option 1</option>
        <option>Option 2</option>
    </select>
</div>
```

## Checkbox and radio

Similar as the select, to have the same render across browsers we add the class `form-control-checkbox` for checkbox and `form-control-radio` for radio.

```html_example
<div class="form-control-checkbox">
    <input type="checkbox" id="example-checkbox-1" value="">
    <label for="example-checkbox-1">Option one</label>
</div>
```

```html_example
<div class="form-control-checkbox">
    <input type="checkbox" id="example-checkbox-2" value="" checked>
    <label for="example-checkbox-2">Option two is checked</label>
</div>
```

```html_example
<div class="form-control-checkbox">
    <input type="checkbox" id="example-checkbox-3" value="" disabled>
    <label for="example-checkbox-3">Option three is disabled</label>
</div>
```

```html_example
<div class="form-control-radio">
    <input type="radio" name="example-radio" id="example-radio-1" value="">
    <label for="example-radio-1">Option one</label>
</div>
```

```html_example
<div class="form-control-radio">
    <input type="radio" name="example-radio" id="example-radio-2" value="" checked>
    <label for="example-radio-2">Option two is checked</label>
</div>
```

```html_example
<div class="form-control-radio">
    <input type="radio" name="example-radio" id="example-radio-3" value="" disabled>
    <label for="example-radio-3">Option three is disabled</label>
</div>
```

## Form container

A form can be visually delimited for better readability.

```html_example
<div class="form-container">
    <div class="form-group">
        <label for="container-example-input">Input text</label>
        <input type="text" id="container-example-input" class="form-control">
    </div>
    <div class="form-submit">
        <button type="submit" class="btn btn--primary">Submit</button>
    </div>
</div>
```

## Validation states

There are two validation states: Success and error. To use, add `valid` or `mage-error` to any `input.form-control` element. A validation message error with the class `mage-error` can be added to give more information about the error.

It use the classes used by Magento 2 in the default scripts, so Magento 2 will add the corresponding class by itself when form validation will perform.

```html_example
<div class="form-group">
    <label for="example-input-success">Validation success</label>
    <input type="text" id="example-input-success" class="form-control valid">
</div>
<div class="form-group">
    <label for="example-input-error">Validation error</label>
    <input type="text" id="example-input-error" class="form-control mage-error">
    <div class="mage-error">Validation error message</div>
</div>
```
*/
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0; }

legend {
  display: block;
  width: 100%;
  margin: 0 0 8px;
  padding: 0;
  font-size: 1.7rem;
  font-style: normal;
  letter-spacing: .5px;
  line-height: inherit;
  color: #575757;
  border: 0; }

label {
  display: inline-block;
  font-weight: normal; }

input[type="search"] {
  box-sizing: border-box; }

input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal; }

input[type="file"] {
  display: block; }

input[type="range"] {
  display: block;
  width: 100%; }

select {
  cursor: pointer;
  border-radius: 5px; }

select[multiple],
select[size] {
  height: auto; }

output {
  display: block;
  padding-top: 7px;
  font-size: 1.6rem;
  line-height: 1.3;
  color: #333333; }

@media (min-width: 768px) {
  .form-container {
    max-width: 700px;
    margin: 0 0 32px;
    padding: 32px;
    border: 1px solid #e2e1e1;
    border-radius: 5px; } }

.form-group {
  margin-bottom: 8px; }
  @media (min-width: 768px) {
    .form-group {
      margin-bottom: 16px; } }

.form-group__nested {
  margin: 8px 0 0; }
  .form-group__nested label {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
    border: 0; }

label,
.label {
  margin: 0 0 8px;
  font-family: "Roboto", sans-serif;
  font-size: 1.7rem;
  font-weight: normal;
  font-style: normal;
  letter-spacing: .5px;
  color: #575757;
  cursor: pointer; }
  label em,
  .label em {
    font-size: 1.2rem;
    font-style: italic;
    font-weight: normal;
    text-transform: lowercase; }

.form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1;
  color: #333333;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d2d2d2;
  border-radius: 40px;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }
  .form-control:focus {
    border-color: #2D2D2A;
    outline: 0; }
  .form-control::placeholder {
    font-family: "Roboto", sans-serif;
    color: rgba(51, 51, 51, 0.3);
    opacity: 1; }
  .form-control[disabled], .form-control[readonly],
  fieldset[disabled] .form-control {
    opacity: 0.6; }
  .form-control[disabled],
  fieldset[disabled] .form-control {
    cursor: not-allowed; }
  .form-control.valid[type="text"], .form-control.valid[type="email"], .form-control.valid[type="password"] {
    padding-right: 56px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 57 40'%3E%3Cpath fill='%2361D2B9' d='M13.3 0L57 0 57 40 0 40 13.3 0ZM39.7 13.3C39.4 13.1 39.1 13 38.7 13 38.3 13 38 13.2 37.8 13.5L29.2 23.7 24.7 18.3C24.5 18 24.1 17.8 23.8 17.8 23.4 17.8 23.1 17.9 22.8 18.1 22.5 18.3 22.4 18.7 22.3 19 22.3 19.4 22.4 19.7 22.7 20L28.1 26.6C28.4 26.9 28.8 27.1 29.2 27.1 29.2 27.1 29.2 27.1 29.2 27.1 29.2 27.1 29.2 27.1 29.3 27.1 29.3 27.1 29.3 27.1 29.4 27.1 29.5 27 29.5 27 29.6 27 29.6 27 29.6 27 29.7 27 29.9 26.9 30.1 26.8 30.3 26.6L39.8 15.2C40.3 14.7 40.3 13.8 39.7 13.3'/%3E%3C/svg%3E");
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: auto 100%; }
  .form-control.mage-error {
    border-color: #ee385c; }
    .form-control.mage-error:focus {
      border-color: #e0133c; }
    .form-control.mage-error[type="text"], .form-control.mage-error[type="email"], .form-control.mage-error[type="password"] {
      padding-right: 56px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 57 40'%3E%3Cpath fill='%23EE385C' d='M13.3 0L57 0 57 40 0 40 13.3 0ZM29.5 21.5C29.5 22 29.1 22.4 28.7 22.4 28.2 22.4 27.8 22 27.8 21.5L27.8 18.9C27.8 18.4 28.2 18 28.7 18 29.1 18 29.5 18.4 29.5 18.9L29.5 21.5ZM28.7 25.4C28.2 25.4 27.8 25 27.8 24.5 27.8 24 28.2 23.6 28.7 23.6 29.1 23.6 29.5 24 29.5 24.5 29.5 25 29.1 25.4 28.7 25.4L28.7 25.4ZM37.6 27.5L29 11.2C28.9 10.9 28.5 10.9 28.3 11.2L19.7 27.5C19.6 27.9 19.8 28.2 20.1 28.2L37.2 28.2C37.5 28.2 37.7 27.9 37.6 27.5L37.6 27.5Z'/%3E%3C/svg%3E");
      background-position: 100% 50%;
      background-repeat: no-repeat;
      background-size: auto 100%; }
  .form-control.is-hidden {
    display: none; }
  @media (pointer: fine) {
    .form-control {
      font-size: 1.7rem; } }

textarea.form-control {
  height: auto;
  border-radius: 20px; }

input[type="search"] {
  -webkit-appearance: none; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    line-height: 40px; } }

.form-control-select {
  position: relative; }
  .form-control-select .form-control {
    padding-right: 40px;
    color: #333333;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none; }
    .form-control-select .form-control::-ms-expand {
      display: none; }
    .form-control-select .form-control.active {
      color: #333333; }
  .form-control-select::after {
    content: "";
    position: absolute;
    top: 12px;
    right: 18px;
    width: 12px;
    height: 12px;
    background-color: transparent;
    border: 2px solid #333333;
    border-width: 0 2px 2px 0;
    border-radius: 2px;
    transform: rotate(45deg);
    pointer-events: none;
    z-index: 1; }
  .form-control-select.disabled::after {
    opacity: .2; }
  @media (min-width: 768px) {
    .form-control-select::after {
      top: 11px;
      width: 14px;
      height: 14px;
      border-width: 0 3px 3px 0;
      border-radius: 3px; } }

.form-control-checkbox:not(:last-of-type), .form-control-radio:not(:last-of-type) {
  margin: 0 0 8px; }

.form-control-checkbox input, .form-control-radio input {
  position: absolute;
  opacity: 0;
  z-index: -1; }
  .form-control-checkbox input:checked + label::before, .form-control-radio input:checked + label::before {
    background-color: #2D2D2A;
    border-color: #2D2D2A; }
  .form-control-checkbox input:checked + label::after, .form-control-radio input:checked + label::after {
    display: block; }
  .form-control-checkbox input:disabled + label, .form-control-radio input:disabled + label {
    opacity: .6;
    cursor: not-allowed; }
  .form-control-checkbox input:disabled:not(:checked) + label::before, .form-control-radio input:disabled:not(:checked) + label::before {
    background-color: #efeeee; }

.form-control-checkbox label, .form-control-radio label {
  position: relative;
  margin: 0;
  padding: 0 0 0 24px;
  font-weight: normal;
  line-height: 20px; }
  .form-control-checkbox label::before, .form-control-radio label::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 2px solid #e3e2e2;
    transition: background-color .1s ease-in-out, border-color .1s ease-in-out; }
  .form-control-checkbox label::after, .form-control-radio label::after {
    content: "";
    position: absolute;
    top: 6px;
    left: 3px;
    display: none;
    width: 10px;
    height: 6px;
    border: 2px solid #fff;
    border-width: 0 0 2px 2px;
    transform: rotate(-45deg);
    z-index: 10; }

.form-control-radio input:checked + label::before {
  background-color: #2D2D2A;
  border-color: #e3e2e2; }

.form-control-radio label::before {
  border-radius: 50%; }

.form-control-radio label::after {
  content: none; }

.form-control-checkbox-hidden input:checked + label::after, .form-control-radio-hidden input:checked + label::after {
  display: none;
  margin: 0; }

.form-control-checkbox-hidden label::before, .form-control-radio-hidden label::before {
  display: none;
  margin: 0; }

.form-control-checkbox-hidden input[type="radio"], .form-control-checkbox-hidden input[type="checkbox"] {
  display: none; }

.form-control-checkbox-hidden label {
  margin: 0;
  color: white;
  font-weight: 600; }

.hidden-checkbox-button {
  margin: 0; }

.form-info_guest {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-weight: bold;
  letter-spacing: .8px;
  line-height: 1.3; }
  .form-info_guest .admin__legend {
    font-size: 2rem; }

.form-guest_order {
  width: 50%;
  margin-left: auto;
  margin-right: auto; }
  .form-guest_order .admin__legend {
    padding: 0 24px;
    font-size: 2.8rem;
    font-weight: bold;
    letter-spacing: .8px;
    line-height: 1.3; }
  .form-guest_order label {
    padding: 0 24px;
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: .8px;
    line-height: 1.3;
    margin-top: 10px;
    margin-left: 9%; }
  .form-guest_order #quick-search-type-id {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    text-align-last: center;
    height: 30px;
    background: transparent;
    padding: 5px;
    font-size: 16px;
    line-height: 1;
    border-radius: 40px;
    height: 34px; }
  .form-guest_order input[type=text], .form-guest_order input[type=email] {
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto; }
  .form-guest_order .control {
    text-align: center; }
  .form-guest_order .btn-form-guest {
    margin-top: 20px;
    margin-bottom: 35px;
    text-align: center; }

.form-quantity {
  position: relative; }
  .form-quantity .form-control {
    text-align: center;
    -moz-appearance: textfield; }
    .form-quantity .form-control[type="number"]::-webkit-outer-spin-button, .form-quantity .form-control[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }

.form-quantity-plus,
.form-quantity-minus {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  padding: 0;
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  font-size: 1.2rem;
  line-height: 1;
  background-color: #2D2D2A;
  border: 0;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: background-color .15s ease-in-out;
  z-index: 1; }
  .form-quantity-plus:focus,
  .form-quantity-minus:focus {
    background-color: black; }
  @media (min-width: 992px) {
    .form-quantity-plus:hover,
    .form-quantity-minus:hover {
      background-color: black; } }

.form-quantity-plus {
  left: auto;
  right: 0; }
  .form-quantity-plus::before, .form-quantity-plus::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #fff;
    border-radius: 2px;
    transform: translate(-50%, -50%); }
  .form-quantity-plus::before {
    width: 50%;
    height: 2px; }
  .form-quantity-plus::after {
    width: 2px;
    height: 50%; }

.form-quantity-minus::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 2px;
  background-color: #fff;
  border-radius: 2px;
  transform: translate(-50%, -50%); }

.form-submit {
  display: flex;
  width: 100%;
  margin-top: 16px;
  text-align: right; }
  .form-submit .btn, .form-submit .modal-footer .action-primary, .modal-footer .form-submit .action-primary, .form-submit
  .modal-footer .action-secondary,
  .modal-footer .form-submit .action-secondary {
    flex-shrink: 0;
    margin: 16px 0 0; }
  @media (max-width: 767px) {
    .form-submit {
      flex-direction: column;
      align-items: center; }
      .form-submit .btn:not(.btn--link):not(.modal-footer .action-secondary), .form-submit .modal-footer .action-primary:not(.btn--link):not(.modal-footer .action-secondary), .modal-footer .form-submit .action-primary:not(.btn--link):not(.modal-footer .action-secondary), .form-submit
      .modal-footer .action-secondary:not(.btn--link):not(.modal-footer .action-secondary),
      .modal-footer .form-submit .action-secondary:not(.btn--link):not(.modal-footer .action-secondary) {
        width: 100%; }
      .form-submit .btn--link, .form-submit .modal-footer .action-secondary, .modal-footer .form-submit .action-secondary {
        order: 1; } }
  @media (min-width: 768px) {
    .form-submit {
      align-items: center;
      justify-content: flex-end; }
      .form-submit .btn, .form-submit .modal-footer .action-primary, .modal-footer .form-submit .action-primary, .form-submit
      .modal-footer .action-secondary,
      .modal-footer .form-submit .action-secondary {
        margin-left: 16px; }
      .form-submit .btn--primary .btn--secondary, .form-submit .modal-footer .action-primary .btn--secondary, .modal-footer .form-submit .action-primary .btn--secondary {
        order: 1; } }

.form-help,
div.mage-error {
  display: block;
  margin: 8px 0 0 16px;
  font-size: 1.6rem;
  color: #737373; }

div.mage-error {
  color: #ee385c; }

.form-help .form-help-account {
  font-weight: 700;
  font-style: italic;
  font-size: 2.1rem;
  color: #61d2b9; }

.password-strength-meter-1 .form-strength {
  color: #d9534f; }

.password-strength-meter-2 .form-strength {
  color: #f0ad4e; }

.password-strength-meter-3 .form-strength,
.password-strength-meter-4 .form-strength {
  color: #5cb85c; }

.field.duration {
  display: flex;
  flex-direction: row; }
  .field.duration .control:nth-child(2) {
    max-width: 60px;
    margin-right: 2px; }
  .field.duration .control:last-child {
    max-width: 116px; }
  @media (max-width: 768px) {
    .field.duration {
      flex-wrap: wrap; }
      .field.duration .label {
        width: 100%; } }

/*doc
---
title: Tooltip
name: tooltip
category: Components
---
The tooltip provide extra informations when it's hovered or clicked. It can be use in a `<label>`, for example, to give more information about the field.

The hidden text must be placed in the `aria-label` attribute.

```html_example
<button type="button" class="tooltip" aria-label="This text provide extra informations" role="tooltip">Help</button>
```
*/
.tooltip {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  margin: -5px 0 0 5px;
  padding: 0;
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  background-color: #2D2D2A;
  border: 0;
  border-radius: 50%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 13'%3E%3Cpath fill='%23ffffff' d='M2.99 6.97C3.18 6.78 3.41 6.68 3.69 6.68 4.41 6.68 4.99 6.52 5.42 6.18 5.86 5.84 6.07 5.32 6.07 4.61 6.07 4.07 5.88 3.62 5.49 3.26 5.1 2.9 4.61 2.72 4.02 2.72 3.42 2.72 2.89 2.92 2.44 3.32 2.17 3.56 1.9 3.68 1.62 3.68 1.41 3.68 1.23 3.62 1.06 3.49 0.78 3.27 0.64 3.01 0.64 2.72 0.64 2.47 0.74 2.24 0.95 2.03 1.34 1.63 1.8 1.32 2.33 1.09 2.86 0.87 3.42 0.76 4.02 0.76 4.76 0.76 5.43 0.93 6.04 1.27 6.65 1.6 7.14 2.07 7.49 2.66 7.85 3.25 8.03 3.9 8.03 4.61 8.03 5.66 7.73 6.48 7.14 7.09 6.54 7.7 5.74 8.08 4.75 8.23 4.7 8.26 4.67 8.28 4.67 8.32L4.67 8.92C4.67 9.2 4.58 9.44 4.39 9.63 4.21 9.82 3.97 9.92 3.69 9.92 3.41 9.92 3.18 9.82 2.99 9.63 2.81 9.44 2.71 9.2 2.71 8.92L2.71 7.68C2.71 7.4 2.81 7.16 2.99 6.97ZM2.88 12.58C2.72 12.42 2.63 12.17 2.63 11.86L2.63 11.58C2.63 11.26 2.72 11.02 2.88 10.85 3.05 10.69 3.29 10.6 3.61 10.6L3.82 10.6C4.14 10.6 4.38 10.69 4.55 10.85 4.72 11.02 4.8 11.26 4.8 11.58L4.8 11.86C4.8 12.17 4.72 12.42 4.55 12.58 4.38 12.75 4.14 12.84 3.82 12.84L3.61 12.84C3.29 12.84 3.05 12.75 2.88 12.58Z'/%3E%3C/svg%3E");
  background-position: 50% 42%;
  background-repeat: no-repeat;
  background-size: auto 55%;
  cursor: pointer; }
  .tooltip::after {
    content: attr(aria-label);
    position: absolute;
    top: calc(100% + 8px);
    left: 50%;
    opacity: 0;
    width: 270px;
    padding: 8px 10px;
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .5px;
    line-height: 1.1;
    text-align: center;
    color: #FFFFFF;
    background-color: #2D2D2A;
    border-radius: 3px;
    transform: translate(-50%, 0);
    transition: opacity .15s ease-in-out;
    pointer-events: none;
    z-index: 10; }
  .tooltip:focus {
    outline: none; }
    .tooltip:focus::after {
      opacity: 1; }
  @media (min-width: 992px) {
    .tooltip:hover {
      outline: none; }
      .tooltip:hover::after {
        opacity: 1; } }

.owl-carousel {
  position: relative;
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  z-index: 1; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-grab {
    cursor: grab; }
  .no-js .owl-carousel {
    display: block; }

.owl-stage-outer {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0); }
  @media screen and (max-width: 470px) {
    .owl-stage-outer {
      pointer-events: none;
      z-index: 2; } }

.owl-stage {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden; }

.owl-wrapper,
.owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  transform: translate3d(0, 0, 0); }

.owl-item {
  position: relative;
  min-height: 1px;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; }
  .owl-refresh .owl-item {
    visibility: hidden; }
  .owl-drag .owl-item {
    user-select: none; }

.owl-nav.disabled,
.owl-dots.disabled {
  display: none; }

.owl-prev,
.owl-next {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  background-color: #e2e1e1;
  transform: translate(0, -50%); }
  @media screen and (max-width: 471px) {
    .owl-prev,
    .owl-next {
      display: none; } }
  .owl-prev::before,
  .owl-next::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 2px;
    background-color: #333333;
    border-radius: 2px;
    transform: translate(-50%, -50%); }
  .owl-prev::after,
  .owl-next::after {
    content: "";
    position: absolute;
    top: 50%;
    left: calc(50% - 3px);
    width: 11px;
    height: 11px;
    background-color: transparent;
    border: 2px solid #333333;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(135deg); }
  .owl-prev.disabled,
  .owl-next.disabled {
    opacity: .25; }
  @media (min-width: 992px) {
    .owl-prev,
    .owl-next {
      width: 50px; } }

.owl-prev {
  left: 0;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%; }

.owl-next {
  right: 0;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%; }
  .owl-next::after {
    left: calc(50% + 3px);
    transform: translate(-50%, -50%) rotate(-45deg); }

.owl-prev,
.owl-next,
.owl-dot {
  cursor: pointer;
  user-select: none; }

/* Arrow navigation on mobile */
.gauche,
.droite {
  position: absolute !important;
  z-index: 9000 !important;
  top: 50%;
  width: 44px;
  height: 44px;
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  background-color: white;
  transform: translate(0, -50%); }
  @media screen and (min-width: 470px) {
    .gauche,
    .droite {
      display: none; } }
  @media screen and (max-width: 470px) {
    .gauche,
    .droite {
      pointer-events: all; } }
  .gauche::before,
  .droite::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 2px;
    background-color: #333333;
    border-radius: 2px;
    transform: translate(-50%, -50%); }
  .gauche::after,
  .droite::after {
    content: "";
    position: absolute;
    top: 50%;
    left: calc(50% - 3px);
    width: 11px;
    height: 11px;
    background-color: transparent;
    border: 2px solid #333333;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(135deg); }
  .gauche.disabled,
  .droite.disabled {
    opacity: .25; }
  @media (min-width: 992px) {
    .gauche,
    .droite {
      width: 50px; } }

.gauche {
  left: 0;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%; }

.droite {
  right: 0;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%; }
  .droite::after {
    left: calc(50% + 3px);
    transform: translate(-50%, -50%) rotate(-45deg); }

@media screen and (max-width: 470px) {
  a.link-nav {
    pointer-events: none; } }

.gauche,
.droite,
.owl-dot {
  cursor: pointer;
  user-select: none; }

.modal-open,
._has-modal {
  overflow: hidden; }
  .modal-open .modal,
  .modal-open .modal-popup,
  .modal-open .modal-slide,
  ._has-modal .modal,
  ._has-modal .modal-popup,
  ._has-modal .modal-slide {
    overflow-x: hidden;
    overflow-y: auto; }

.modal,
.modal-popup,
.modal-slide {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow: hidden;
  outline: 0;
  z-index: 220; }
  .modal .modal-dialog,
  .modal .modal-inner-wrap,
  .modal-popup .modal-dialog,
  .modal-popup .modal-inner-wrap,
  .modal-slide .modal-dialog,
  .modal-slide .modal-inner-wrap {
    transform: translate(0, -25%);
    transition: transform .3s ease-out; }
  .modal.show, .modal._show,
  .modal-popup.show,
  .modal-popup._show,
  .modal-slide.show,
  .modal-slide._show {
    display: block; }
    .modal.show .modal-dialog,
    .modal.show .modal-inner-wrap, .modal._show .modal-dialog,
    .modal._show .modal-inner-wrap,
    .modal-popup.show .modal-dialog,
    .modal-popup.show .modal-inner-wrap,
    .modal-popup._show .modal-dialog,
    .modal-popup._show .modal-inner-wrap,
    .modal-slide.show .modal-dialog,
    .modal-slide.show .modal-inner-wrap,
    .modal-slide._show .modal-dialog,
    .modal-slide._show .modal-inner-wrap {
      transform: translate(0, 0); }

.modal-popup,
.modal-slide {
  display: block;
  visibility: hidden; }
  .modal-popup .modal-inner-wrap,
  .modal-slide .modal-inner-wrap {
    transition: transform .3s ease-out, visibility .3s ease; }
  .modal-popup._show,
  .modal-slide._show {
    visibility: visible; }

.modal-dialog,
.modal-inner-wrap {
  position: relative;
  width: auto;
  margin: 80px 10px 10px; }
  @media (min-width: 480px) {
    .modal-dialog,
    .modal-inner-wrap {
      width: 460px;
      margin: 80px auto; } }
  @media (min-width: 768px) {
    .modal-dialog,
    .modal-inner-wrap {
      width: 720px;
      margin: 160px auto; } }
  @media (min-width: 992px) {
    .modal-dialog,
    .modal-inner-wrap {
      width: 880px; } }

@media (min-width: 768px) {
  .modal--xs .modal-dialog,
  .modal--xs .modal-inner-wrap {
    width: 460px; } }

@media (min-width: 992px) {
  .modal--xs .modal-dialog,
  .modal--xs .modal-inner-wrap {
    width: 460px; } }

@media (min-width: 768px) {
  .modal-popup .modal-dialog,
  .modal-popup .modal-inner-wrap {
    width: 520px; } }

@media (min-width: 992px) {
  .modal-popup .modal-dialog,
  .modal-popup .modal-inner-wrap {
    width: 520px; } }

@media (max-width: 768px) {
  .modal-popup.modal-slide {
    left: inherit; } }

@media (max-width: 768px) {
  .modal-popup.modal-slide .modal-inner-wrap {
    height: 50%;
    width: 80%;
    margin: auto;
    margin-top: 10%; } }

@media (max-width: 480px) {
  .modal-popup.modal-slide .modal-inner-wrap {
    height: 80%;
    width: 85%;
    margin: auto;
    margin-top: 10%; } }

.modal-content:not([data-role="content"]),
.modal-inner-wrap:not([data-role="content"]) {
  position: relative;
  padding: 16px;
  color: #333333;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  outline: 0; }
  @media (min-width: 768px) {
    .modal-content:not([data-role="content"]),
    .modal-inner-wrap:not([data-role="content"]) {
      padding: 37px; } }

.modal-header {
  margin: 0 0 32px; }

.modal-title {
  display: block;
  margin: 0 0 16px;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: .8px;
  text-transform: none;
  text-align: center; }

.modal-body,
.modal-content[data-role="content"] {
  position: relative;
  font-size: 1.7rem;
  letter-spacing: .5px;
  text-align: center; }
  .modal-body .form-group,
  .modal-content[data-role="content"] .form-group {
    padding: 0 24px;
    text-align: left; }

.modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 12px 0 0;
  text-align: center; }
  .modal-footer a,
  .modal-footer button {
    margin: 8px 0 0; }
  .modal-footer .action-secondary {
    order: 1; }
  .modal-footer a,
  .modal-footer .action-secondary {
    font-size: 1.7rem;
    letter-spacing: .5px;
    color: #989898; }
    .modal-footer a:focus,
    .modal-footer .action-secondary:focus {
      color: #EF3340; }
    @media (min-width: 992px) {
      .modal-footer a:hover,
      .modal-footer .action-secondary:hover {
        color: #EF3340; } }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

.modal-close,
.action-close {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 30px;
  height: 30px;
  padding: 0;
  line-height: 1;
  color: #2D2D2A;
  background-color: #333333;
  border: 0;
  border-radius: 50%;
  outline: 0;
  transition: background-color .15s ease-in-out;
  cursor: pointer; }
  .modal-close::before, .modal-close::after,
  .action-close::before,
  .action-close::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60%;
    height: 2px;
    background-color: #2D2D2A;
    border-radius: 2px; }
  .modal-close::before,
  .action-close::before {
    transform: translate(-50%, -50%) rotate(45deg); }
  .modal-close::after,
  .action-close::after {
    transform: translate(-50%, -50%) rotate(-45deg); }
  .modal-close:focus,
  .action-close:focus {
    background-color: #000; }
  @media (min-width: 992px) {
    .modal-close:hover,
    .action-close:hover {
      background-color: #000; } }
  .modal-close span,
  .action-close span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
    border: 0; }

.modal-backdrop,
.modals-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: #333333;
  transition: opacity .15s linear;
  z-index: 210; }
  .modal-backdrop.show,
  .modals-overlay.show {
    opacity: 0.75; }

.modals-overlay {
  opacity: 0.75; }

/*doc
---
title: Pagination
name: pagination
category: Navigation
---
Pagniation use microdata to help search engine to recognize it as a navigation element.

```html_example
<nav class="pagination" role="navigation">
    <ul class="pagination__list" itemscope="" itemtype="http://schema.org/SiteNavigationElement">
        <li class="pagination__item pagination__item--previous">
            <span itemprop="name">Page précédente</span>
        </li>
        <li class="pagination__item pagination__item--active">
            <span itemprop="name"><em>Page</em> 1</span>
        </li>
        <li class="pagination__item">
            <a href="#" itemprop="url">
                <span itemprop="name"><em>Page</em> 2</span>
            </a>
        </li>
        <li class="pagination__item">
            <a href="#" itemprop="url">
                <span itemprop="name"><em>Page</em> 3</span>
            </a>
        </li>
        <li class="pagination__item">
            <span>…</span>
        </li>
        <li class="pagination__item">
            <a href="#" itemprop="url">
                <span itemprop="name"><em>Page</em> 10</span>
            </a>
        </li>
        <li class="pagination__item pagination__item--next">
            <a href="#" itemprop="url">
                <span itemprop="name">Page suivante</span>
            </a>
        </li>
    </ul>
</nav>
```
*/
.pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 30px 0 0; }

.pagination__list {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex; }

.pagination__item {
  height: 30px;
  min-width: 30px;
  margin: 0 2px;
  font-size: 2rem;
  line-height: 30px;
  text-align: center; }
  .pagination__item a {
    display: block;
    width: 100%;
    height: 30px;
    font-weight: bold;
    text-decoration: none; }
  .pagination__item em {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
    border: 0; }

.pagination__item--active {
  font-weight: bold;
  color: #fff;
  background-color: #575757;
  border-radius: 30px; }

.pagination__item--previous,
.pagination__item--next {
  position: relative; }
  .pagination__item--previous a span::before,
  .pagination__item--next a span::before {
    background-color: #333333; }
  .pagination__item--previous a span::after,
  .pagination__item--next a span::after {
    border-color: #333333; }
  .pagination__item--previous a:focus span::before,
  .pagination__item--next a:focus span::before {
    background-color: #EF3340; }
  .pagination__item--previous a:focus span::after,
  .pagination__item--next a:focus span::after {
    border-color: #EF3340; }
  @media (min-width: 992px) {
    .pagination__item--previous a:hover span::before,
    .pagination__item--next a:hover span::before {
      background-color: #EF3340; }
    .pagination__item--previous a:hover span::after,
    .pagination__item--next a:hover span::after {
      border-color: #EF3340; } }
  .pagination__item--previous span,
  .pagination__item--next span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0; }
    .pagination__item--previous span::before,
    .pagination__item--next span::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 16px;
      height: 2px;
      background-color: #e2e1e1;
      border-radius: 2px;
      transform: translate(-50%, -50%);
      transition: background-color .1s ease-in-out; }
    .pagination__item--previous span::after,
    .pagination__item--next span::after {
      content: "";
      position: absolute;
      top: 50%;
      left: calc(50% - 3px);
      width: 11px;
      height: 11px;
      background-color: transparent;
      border: 2px solid #e2e1e1;
      border-width: 0 2px 2px 0;
      transform: translate(-50%, -50%) rotate(135deg);
      transition: border-color .1s ease-in-out; }

.pagination__item--next span::after {
  left: calc(50% + 3px);
  transform: translate(-50%, -50%) rotate(-45deg); }

/*doc
---
title: Breadcrumb
name: breadcrumb
category: Navigation
---
Breadcrumb use microdata, useful for search engine optimisation. The position store in a `meta` tag need to be updated.

```html_example
<ol class="breadcrumb" itemscope="" itemtype="http://schema.org/BreadcrumbList">
    <li class="breadcrumb__item" itemscope="" itemprop="itemListElement" itemtype="http://schema.org/ListItem">
        <a href="#" itemprop="item"><span itemprop="name">Home</span></a>
        <meta itemprop="position" content="1">
    </li>
    <li class="breadcrumb__item" itemscope="" itemprop="itemListElement" itemtype="http://schema.org/ListItem">
        <a href="#" itemprop="item"><span itemprop="name">Catalogue</span></a>
        <meta itemprop="position" content="2">
    </li>
    <li class="breadcrumb__item" itemscope="" itemprop="itemListElement" itemtype="http://schema.org/ListItem">
        <a href="#" itemprop="item"><span itemprop="name">Amortisseurs</span></a>
        <meta itemprop="position" content="3">
    </li>
    <li class="breadcrumb__item" itemscope="" itemprop="itemListElement" itemtype="http://schema.org/ListItem">
        <strong itemprop="item"><span itemprop="name">Amortisseurs avant</span></strong>
        <meta itemprop="position" content="4">
    </li>
</ol>
```
*/
.breadcrumbs {
  background: white;
  position: relative;
  width: 100%;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  margin-top: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 1%; }
  @media (min-width: 768px) {
    .breadcrumbs {
      max-width: 100%; } }
  .breadcrumbs strong,
  .breadcrumbs a {
    font-size: 2rem; }
    @media (max-width: 480px) {
      .breadcrumbs strong,
      .breadcrumbs a {
        font-size: 1.4rem; } }
  .breadcrumbs a {
    color: #2D2D2A; }
    .breadcrumbs a:hover {
      color: #EF3340;
      text-decoration: none; }
  .breadcrumbs .item {
    color: #2D2D2A;
    font-size: 1.4rem; }
  .breadcrumbs .item:not(:last-child):after {
    font-size: 4rem;
    vertical-align: middle;
    line-height: 28px;
    vertical-align: inherit; }
    @media (max-width: 480px) {
      .breadcrumbs .item:not(:last-child):after {
        font-size: 2rem;
        line-height: 16px;
        vertical-align: inherit; } }

/*doc
---
title: Blocks
name: blocks
category: Components
---
This allow to organize CMS page content in blocks with image and text. On large screens the blocks are listed tow by two.

```html_example
<div class="page-blocks">
    <div class="page-block">
        <h2 class="page-block__title">Sed posuere consectetur est at lobortis</h2>
        <div class="page-block__content">
            <div class="page-block__image">
                <img src="https://dummyimage.com/120x120/000/fff" alt="">
            </div>
            <div class="page-block__text">
                <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus sagittis lacus
                    vel augue laoreet rutrum faucibus dolor auctor. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cras mattis consectetur purus sit amet fermentum. Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
            </div>
        </div>
    </div>
    <div class="page-block">
        <h2 class="page-block__title">Sed posuere consectetur est at lobortis</h2>
        <div class="page-block__content">
            <div class="page-block__image">
                <img src="https://dummyimage.com/120x120/000/fff" alt="">
            </div>
            <div class="page-block__text">
                <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus sagittis lacus
                    vel augue laoreet rutrum faucibus dolor auctor. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cras mattis consectetur purus sit amet fermentum. Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
            </div>
        </div>
    </div>
    <div class="page-block">
        <h2 class="page-block__title">Sed posuere consectetur est at lobortis</h2>
        <div class="page-block__content">
            <div class="page-block__image">
                <img src="https://dummyimage.com/120x120/000/fff" alt="">
            </div>
            <div class="page-block__text">
                <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus sagittis lacus
                    vel augue laoreet rutrum faucibus dolor auctor. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cras mattis consectetur purus sit amet fermentum. Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
            </div>
        </div>
    </div>
    <div class="page-block">
        <h2 class="page-block__title">Sed posuere consectetur est at lobortis</h2>
        <div class="page-block__content">
            <div class="page-block__image">
                <img src="https://dummyimage.com/120x120/000/fff" alt="">
            </div>
            <div class="page-block__text">
                <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus sagittis lacus
                    vel augue laoreet rutrum faucibus dolor auctor. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cras mattis consectetur purus sit amet fermentum. Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
            </div>
        </div>
    </div>
</div>
```
*/
@media (min-width: 992px) {
  .page-blocks {
    display: flex;
    flex-wrap: wrap;
    width: 100%; } }

.page-block {
  margin: 0 0 16px;
  padding: 16px;
  background-color: #efeeee; }
  .page-block:nth-child(2n) {
    margin-right: 0; }
  @media (min-width: 992px) {
    .page-block {
      width: calc(50% - 14px);
      margin: 0 24px 24px 0;
      padding: 24px;
      display: inline-block; } }

.page-block__title {
  display: block;
  margin: 0 0 16px;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: .5px;
  text-transform: none; }

.page-block__content {
  display: flex;
  align-items: center;
  width: 100%; }

.page-block__image {
  flex: 1 0 auto;
  width: 100%;
  height: 125px;
  max-width: 120px;
  margin: 0 8px 0 0;
  display: table-cell;
  vertical-align: middle;
  text-align: center; }
  @media (min-width: 992px) {
    .page-block__image {
      margin: 0 16px 0 0;
      padding: 1%;
      background-color: white; } }

/*doc
---
title: Chapters
name: chapters
category: Components
---
This page may include anchors or links to other pages. However, this is optional.

The content can be organized into an accordion if the HTMl markup is respected according to the example below:

```html_example
<div class="page-chapters">
    <div class="page-chapters__aside">
        <button type="button" class="page-chapters__summary" data-toggle="collapse" data-target="#chapters-navigation" aria-expanded="false"
            aria-controls="chapters-navigation">Sommaire</button>
        <ul id="chapters-navigation" class="page-chapters__navigation collapse">
            <li>
                <a class="active" href="#">Maecenas faucibus mollis interdum</a>
            </li>
            <li>
                <a href="#">Maecenas faucibus mollis interdum</a>
            </li>
            <li>
                <a href="#">Maecenas faucibus mollis interdum</a>
            </li>
            <li>
                <a href="#">Maecenas faucibus mollis interdum</a>
            </li>
            <li>
                <a href="#">Maecenas faucibus mollis interdum</a>
            </li>
            <li>
                <a href="#">Maecenas faucibus mollis interdum</a>
            </li>
        </ul>
    </div>
    <div id="chapters" class="page-chapters__main" role="tablist">
        <div class="page-chapters__panel" data-collapse="group">
            <div id="chapters-head-1" class="page-chapters__panel-head" role="tab">
                <h2 class="page-chapters__panel-title">
                    <a href="#chapters-content-1" data-toggle="collapse" data-parent="#chapters" aria-expanded="true" aria-controls="chapters-content-1"
                        role="button">Duis mollis est non commodo luctus nisi erat porttitor ligula</a>
                </h2>
            </div>
            <div id="chapters-content-1" class="page-chapters__panel-content collapse in" aria-labelledby="chapters-head-1"
                aria-expanded="true" role="tabpanel">
                <div class="page-chapters__panel-text">
                    <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus sagittis
                        lacus vel augue laoreet rutrum faucibus dolor auctor. Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit. Cras mattis consectetur purus sit amet fermentum. Nullam id dolor id nibh ultricies vehicula
                        ut id elit.</p>
                </div>
            </div>
        </div>
        <div class="page-chapters__panel" data-collapse="group">
            <div id="chapters-head-2" class="page-chapters__panel-head" role="tab">
                <h2 class="page-chapters__panel-title">
                    <a href="#chapters-content-2" data-toggle="collapse" data-parent="#chapters" aria-expanded="false" aria-controls="chapters-content-2"
                        role="button">Duis mollis est non commodo luctus nisi erat porttitor ligula</a>
                </h2>
            </div>
            <div id="chapters-content-2" class="page-chapters__panel-content collapse" aria-labelledby="chapters-head-2"
                aria-expanded="false" role="tabpanel">
                <div class="page-chapters__panel-text">
                    <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus sagittis
                        lacus vel augue laoreet rutrum faucibus dolor auctor. Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit. Cras mattis consectetur purus sit amet fermentum. Nullam id dolor id nibh ultricies vehicula
                        ut id elit.</p>
                </div>
            </div>
        </div>
        <div class="page-chapters__panel" data-collapse="group">
            <div id="chapters-head-3" class="page-chapters__panel-head" role="tab">
                <h2 class="page-chapters__panel-title">
                    <a href="#chapters-content-3" data-toggle="collapse" data-parent="#chapters" aria-expanded="false" aria-controls="chapters-content-3"
                        role="button">Duis mollis est non commodo luctus nisi erat porttitor ligula</a>
                </h2>
            </div>
            <div id="chapters-content-3" class="page-chapters__panel-content collapse" aria-labelledby="chapters-head-3"
                aria-expanded="false" role="tabpanel">
                <div class="page-chapters__panel-text">
                    <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus sagittis
                        lacus vel augue laoreet rutrum faucibus dolor auctor. Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit. Cras mattis consectetur purus sit amet fermentum. Nullam id dolor id nibh ultricies vehicula
                        ut id elit.</p>
                </div>
            </div>
        </div>
    </div>
</div>
```
*/
@media (min-width: 992px) {
  .page-chapters {
    display: flex;
    width: 100%; } }

.page-chapters__aside {
  margin: 0 0 24px; }
  @media (min-width: 992px) {
    .page-chapters__aside {
      flex: 1 0 auto;
      width: 280px;
      margin: 0 32px 0 0; } }

.page-chapters__summary {
  position: relative;
  display: block;
  width: 100%;
  padding: 8px 40px 8px 16px;
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: .5px;
  text-align: left;
  background-color: #efeeee;
  border: 0;
  cursor: pointer; }
  .page-chapters__summary::before, .page-chapters__summary::after {
    content: "";
    position: absolute;
    top: 21px;
    right: 16px;
    width: 11px;
    height: 2px;
    background-color: currentColor;
    border-radius: 2px;
    transform: rotate(-45deg);
    transition: transform .1s ease; }
  .page-chapters__summary::after {
    right: 23px;
    transform: rotate(45deg); }
  .page-chapters__summary[aria-expanded="true"] {
    font-weight: bold; }
    .page-chapters__summary[aria-expanded="true"]::before {
      transform: rotate(45deg); }
    .page-chapters__summary[aria-expanded="true"]::after {
      transform: rotate(-45deg); }
  @media (min-width: 992px) {
    .page-chapters__summary {
      display: none; } }

.page-chapters__navigation {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  border-top: 1px solid #e2e1e1;
  background-color: #efeeee; }
  .page-chapters__navigation a {
    position: relative;
    display: block;
    padding: 8px 16px;
    text-decoration: none;
    transition: color .1s ease-in-out, background-color .1s ease-in-out; }
    .page-chapters__navigation a.active {
      color: #fff;
      background-color: #EF3340; }
    .page-chapters__navigation a:focus {
      color: #fff;
      background-color: #EF3340; }
    @media (min-width: 992px) {
      .page-chapters__navigation a:hover {
        color: #fff;
        background-color: #EF3340; } }
  @media (min-width: 992px) {
    .page-chapters__navigation {
      display: block !important;
      height: auto !important;
      padding: 8px 0;
      border: 0; } }

.page-chapters__panel:not(:first-child) {
  margin: 16px 0 0; }

.page-chapters__panel-title {
  margin: 0; }
  .page-chapters__panel-title a {
    position: relative;
    display: block;
    padding: 8px 48px 8px 16px;
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: .5px;
    text-decoration: none;
    background-color: #efeeee; }
    .page-chapters__panel-title a::before, .page-chapters__panel-title a::after {
      content: "";
      position: absolute;
      top: 17px;
      right: 16px;
      width: 11px;
      height: 2px;
      background-color: currentColor;
      border-radius: 2px;
      transform: rotate(-45deg);
      transition: transform .1s ease; }
    .page-chapters__panel-title a::after {
      right: 23px;
      transform: rotate(45deg); }
    .page-chapters__panel-title a[aria-expanded="true"] {
      font-weight: bold; }
      .page-chapters__panel-title a[aria-expanded="true"]::before {
        transform: rotate(45deg); }
      .page-chapters__panel-title a[aria-expanded="true"]::after {
        transform: rotate(-45deg); }
    .page-chapters__panel-title a:focus {
      color: currentColor; }
    @media (min-width: 992px) {
      .page-chapters__panel-title a:hover {
        color: currentColor; } }
  @media (min-width: 992px) {
    .page-chapters__panel-title a {
      font-size: 2rem; } }

.page-chapters__panel-content::after {
  content: "";
  display: table;
  clear: both; }

.page-chapters__panel-text {
  margin: 8px 0 0; }
  .page-chapters__panel-text p:last-child {
    margin: 0; }

.nav-sections {
  background: #fff;
  width: 100%; }
  @media (min-width: 768px) {
    .nav-sections {
      margin-top: 25px; } }
  @media (max-width: 768px) {
    .nav-sections {
      background: #2D2D2A;
      width: calc(100% - 54px);
      padding-top: 40px; } }
  .nav-sections .logo-nav {
    display: none; }
    @media (max-width: 767px) {
      .nav-sections .logo-nav {
        display: block;
        width: 70%;
        height: auto; } }
  .nav-sections .navigation {
    background: #fff;
    text-align: center;
    border: none;
    font-size: 1.5rem;
    font-weight: 500; }
    @media (min-width: 768px) {
      .nav-sections .navigation {
        width: 100%;
        max-width: inherit; }
        .nav-sections .navigation .level0:last-child {
          margin-right: inherit; } }
    .nav-sections .navigation .ui-menu-icon,
    .nav-sections .navigation .ui-menu-item.all-category {
      display: none !important; }
    .nav-sections .navigation li:hover {
      background-color: #2D2D2A;
      color: white; }
    .nav-sections .navigation li:hover a span {
      color: white; }
    .nav-sections .navigation a:hover {
      color: white; }
    @media (min-width: 768px) {
      .nav-sections .navigation li.level-top:hover:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background: #EF3340; } }
    .nav-sections .navigation .level0 > .level-top {
      text-transform: uppercase; }
      .nav-sections .navigation .level0 > .level-top:hover {
        color: white; }
    .nav-sections .navigation .level0.active > a:not(.ui-state-active) span:not(.ui-menu-icon) {
      margin-left: 0; }
    .nav-sections .navigation .navigation .level0 {
      margin: 0;
      padding-left: 0;
      padding-right: 0; }
      .nav-sections .navigation .navigation .level0 .nav-2 {
        min-width: 90px; }
    .nav-sections .navigation .level1 {
      width: 100%;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-left: 8px;
      padding-right: 8px;
      line-height: 2; }
      .nav-sections .navigation .level1.submenu li {
        border-left: 1px solid gray; }
    .nav-sections .navigation .level0 .submenu a:hover,
    .nav-sections .navigation .level0 .submenu a.ui-state-focus {
      background: #2D2D2A; }
    .nav-sections .navigation .level0 .submenu {
      background: #2D2D2A;
      border: none;
      margin: 0;
      padding: 0;
      width: auto;
      min-width: 100%; }
      .nav-sections .navigation .level0 .submenu li {
        padding-left: 8px;
        padding-right: 8px; }
      @media (max-width: 767px) {
        .nav-sections .navigation .level0 .submenu {
          margin-left: 30px; } }
      @media (max-width: 768px) {
        .nav-sections .navigation .level0 .submenu .active > a {
          border-color: #EF3340; } }
      @media (min-width: 768px) {
        .nav-sections .navigation .level0 .submenu .active > a {
          border: none; } }
      .nav-sections .navigation .level0 .submenu a:hover {
        background: #2D2D2A; }
      .nav-sections .navigation .level0 .submenu a {
        color: white;
        font-weight: 500;
        text-align: left;
        font-size: 1.4rem;
        padding-left: 0;
        padding-right: 0;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-top: 0; }
      .nav-sections .navigation .level0 .submenu a:hover,
      .nav-sections .navigation .level0 .submenu .navigation .level0 .submenu a.ui-state-focus {
        background: #2D2D2A; }
    .nav-sections .navigation .level0.active > .level-top,
    .nav-sections .navigation .level0.has-active > .level-top {
      border-color: #EF3340;
      border-width: 100%; }
    @media (max-width: 767px) {
      .nav-sections .navigation {
        text-align: left;
        background: #2D2D2A; }
        .nav-sections .navigation a {
          color: white; }
        .nav-sections .navigation li {
          border: none;
          margin-bottom: 15px;
          color: white;
          font-weight: 500; }
          .nav-sections .navigation li:last-child {
            border: none; }
        .nav-sections .navigation .level0.active > a:not(.ui-state-active),
        .nav-sections .navigation .level0.active .all-category .ui-state-focus,
        .nav-sections .navigation .level0.active > .level-top {
          border-color: #EF3340; } }
    @media (max-width: 767px) and (min-width: 767px) {
      .nav-sections .navigation .level0.active > a:not(.ui-state-active),
      .nav-sections .navigation .level0.active .all-category .ui-state-focus,
      .nav-sections .navigation .level0.active > .level-top {
        border-color: #EF3340; } }
    @media (max-width: 767px) {
        .nav-sections .navigation span {
          color: white;
          font-weight: 500; }
        .nav-sections .navigation .level0 {
          border: none; } }

.nav-open .header.content {
  padding-top: 60px; }
  .nav-open .header.content .logo {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100; }

@media (min-width: 768px), print {
  .navigation .level0 .submenu .submenu-reverse {
    left: 100% !important;
    right: inherit; } }

@media only screen and (max-width: 767px) {
  .nav-sections-item-title {
    display: none; }
  .nav-sections-item-content {
    margin: 0;
    overflow: hidden; } }

@media (min-width: 769px) {
  .navigation .level0 {
    margin: 0px; }
    .navigation .level0.nav-2 {
      min-width: 70px; }
  .navigation .level0.parent > .level-top {
    padding-right: 12px; } }

.homepage-section {
  text-align: center;
  background-color: #fafafa; }

.homepage-section_product {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  padding: 20px;
  padding-left: 0;
  padding-right: 0; }
  @media (max-width: 480px) {
    .homepage-section_product {
      height: 150px;
      padding: 0;
      background-image: url("../images/black-diamond-plate.png") repeat-y;
      background-color: #2D2D2A; } }
  @media (max-width: 565px) {
    .homepage-section_product {
      justify-content: space-around; } }
  .homepage-section_product .mobile {
    margin: auto;
    line-height: 150px;
    vertical-align: middle; }
  .homepage-section_product .homepage-section_product-item {
    display: inline-block;
    background-color: #fff;
    text-align: center;
    border: solid 1px #cccccc;
    border-radius: 6px;
    width: 290px;
    box-shadow: 1px 1px rgba(0, 0, 0, 0.05);
    padding: 25px 25px 30px 25px; }
    @media (max-width: 480px) {
      .homepage-section_product .homepage-section_product-item {
        display: none; } }
    .homepage-section_product .homepage-section_product-item .homepage-section_product-item-pic {
      width: 201px;
      height: auto;
      margin: auto; }
      .homepage-section_product .homepage-section_product-item .homepage-section_product-item-pic img {
        height: 201px;
        max-width: auto; }
    .homepage-section_product .homepage-section_product-item .homepage-section_product-item-title {
      margin: auto; }
      .homepage-section_product .homepage-section_product-item .homepage-section_product-item-title h3 {
        font-size: 20px;
        line-height: 25px;
        height: 50px;
        text-overflow: ellipsis;
        overflow: hidden; }
    .homepage-section_product .homepage-section_product-item .product-separation {
      width: 70%;
      margin: 20px auto;
      border: solid 1px #EF3340; }
    .homepage-section_product .homepage-section_product-item .homepage-section_product-item-subtitle {
      color: #989898;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      height: 100px;
      text-overflow: ellipsis;
      overflow: hidden; }
    .homepage-section_product .homepage-section_product-item .homepage-section_product-item-price {
      min-height: 47px;
      color: black;
      font-size: 20px;
      line-height: 25px;
      font-weight: 800;
      padding-top: 10px;
      padding-bottom: 60px;
      max-height: 50px; }
      .homepage-section_product .homepage-section_product-item .homepage-section_product-item-price p {
        display: none; }
      .homepage-section_product .homepage-section_product-item .homepage-section_product-item-price .price {
        font-size: 2rem; }
      .homepage-section_product .homepage-section_product-item .homepage-section_product-item-price .special-price {
        display: block; }
  @media (max-width: 565px) {
    .homepage-section_product .homepage-section_product-item:nth-last-child(3) {
      display: none; } }
  @media (max-width: 950px) {
    .homepage-section_product .homepage-section_product-item:nth-last-child(2) {
      display: none; } }
  @media (max-width: 1300px) {
    .homepage-section_product .homepage-section_product-item:nth-last-child(1) {
      display: none; } }

.content-retailers {
  width: 100%;
  height: 360px;
  text-align: center; }
  @media (max-width: 1024px) {
    .content-retailers {
      height: 906px; } }
  @media (max-width: 480px) {
    .content-retailers {
      height: 500px; } }
  .content-retailers .content-retailers-first {
    float: left;
    background-size: cover;
    height: 453px; }
  .content-retailers .content-retailers-second {
    float: right;
    background-size: cover;
    height: 453px; }
  .content-retailers .content-retailers-first,
  .content-retailers .content-retailers-second {
    position: relative;
    width: 50%;
    height: 100%;
    text-align: center;
    overflow: hidden; }
    @media (max-width: 1024px) {
      .content-retailers .content-retailers-first,
      .content-retailers .content-retailers-second {
        display: inline-block;
        width: 100%;
        height: 453px; } }
    @media (max-width: 480px) {
      .content-retailers .content-retailers-first,
      .content-retailers .content-retailers-second {
        background: url(../images/black-diamond-plate.png);
        background-color: #EF3340;
        height: 50%; } }
    .content-retailers .content-retailers-first .retailers,
    .content-retailers .content-retailers-second .retailers {
      position: absolute;
      font-size: 2.5rem;
      top: 65%;
      left: 50%;
      transform: translateX(-50%); }
    .content-retailers .content-retailers-first .super_test,
    .content-retailers .content-retailers-second .super_test {
      position: absolute;
      top: 30%;
      left: 50%;
      height: 52%;
      width: 45%;
      display: inline-block;
      border-radius: 6px; }
      @media (max-width: 768px) {
        .content-retailers .content-retailers-first .super_test,
        .content-retailers .content-retailers-second .super_test {
          height: 50%;
          width: 60%;
          left: 30%; } }
      @media (max-width: 480px) {
        .content-retailers .content-retailers-first .super_test,
        .content-retailers .content-retailers-second .super_test {
          height: 50%;
          width: 60%;
          left: 20%;
          border: none; } }
      .content-retailers .content-retailers-first .super_test .super_test-content,
      .content-retailers .content-retailers-second .super_test .super_test-content {
        border-radius: 6px;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 93%;
        width: 95%; }
        @media (max-width: 480px) {
          .content-retailers .content-retailers-first .super_test .super_test-content,
          .content-retailers .content-retailers-second .super_test .super_test-content {
            background-color: transparent;
            color: white;
            font-weight: 700; }
            .content-retailers .content-retailers-first .super_test .super_test-content .btn, .content-retailers .content-retailers-first .super_test .super_test-content .modal-footer .action-primary, .modal-footer .content-retailers .content-retailers-first .super_test .super_test-content .action-primary, .content-retailers .content-retailers-first .super_test .super_test-content
            .modal-footer .action-secondary,
            .modal-footer .content-retailers .content-retailers-first .super_test .super_test-content .action-secondary,
            .content-retailers .content-retailers-second .super_test .super_test-content .btn,
            .content-retailers .content-retailers-second .super_test .super_test-content .modal-footer .action-primary, .modal-footer
            .content-retailers .content-retailers-second .super_test .super_test-content .action-primary,
            .content-retailers .content-retailers-second .super_test .super_test-content
            .modal-footer .action-secondary,
            .modal-footer
            .content-retailers .content-retailers-second .super_test .super_test-content .action-secondary {
              color: white;
              border-color: white; } }
        .content-retailers .content-retailers-first .super_test .super_test-content .super_test-content-all,
        .content-retailers .content-retailers-second .super_test .super_test-content .super_test-content-all {
          height: 90%;
          width: 90%;
          margin: auto;
          padding-top: 10%;
          margin-top: -20%;
          text-align: center;
          position: relative; }
          .content-retailers .content-retailers-first .super_test .super_test-content .super_test-content-all img,
          .content-retailers .content-retailers-second .super_test .super_test-content .super_test-content-all img {
            width: 60%;
            height: auto;
            margin: auto;
            margin-bottom: 5%; }
          @media (max-width: 480px) {
            .content-retailers .content-retailers-first .super_test .super_test-content .super_test-content-all a,
            .content-retailers .content-retailers-second .super_test .super_test-content .super_test-content-all a {
              color: white; } }
          .content-retailers .content-retailers-first .super_test .super_test-content .super_test-content-all a:visited,
          .content-retailers .content-retailers-second .super_test .super_test-content .super_test-content-all a:visited {
            color: black; }
            @media (max-width: 480px) {
              .content-retailers .content-retailers-first .super_test .super_test-content .super_test-content-all a:visited,
              .content-retailers .content-retailers-second .super_test .super_test-content .super_test-content-all a:visited {
                color: white; } }
          .content-retailers .content-retailers-first .super_test .super_test-content .super_test-content-all a:hover,
          .content-retailers .content-retailers-second .super_test .super_test-content .super_test-content-all a:hover {
            color: black; }
            @media (max-width: 480px) {
              .content-retailers .content-retailers-first .super_test .super_test-content .super_test-content-all a:hover,
              .content-retailers .content-retailers-second .super_test .super_test-content .super_test-content-all a:hover {
                color: white; } }
          .content-retailers .content-retailers-first .super_test .super_test-content .super_test-content-all a:focus,
          .content-retailers .content-retailers-second .super_test .super_test-content .super_test-content-all a:focus {
            color: black; }
            @media (max-width: 480px) {
              .content-retailers .content-retailers-first .super_test .super_test-content .super_test-content-all a:focus,
              .content-retailers .content-retailers-second .super_test .super_test-content .super_test-content-all a:focus {
                color: white; } }
          @media (max-width: 480px) {
            .content-retailers .content-retailers-first .super_test .super_test-content .super_test-content-all,
            .content-retailers .content-retailers-second .super_test .super_test-content .super_test-content-all {
              padding: 0;
              margin-top: -21%; }
              .content-retailers .content-retailers-first .super_test .super_test-content .super_test-content-all img,
              .content-retailers .content-retailers-second .super_test .super_test-content .super_test-content-all img {
                width: 80%; } }

.background-image {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover; }

.homepage-section_services {
  width: 100%;
  height: 700px; }
  @media (max-width: 1024px) {
    .homepage-section_services {
      height: 1050px; } }
  .homepage-section_services .services-one,
  .homepage-section_services .services-two {
    position: relative;
    width: 50%;
    height: 100%;
    overflow: hidden;
    background-color: #2D2D2A; }
    @media (max-width: 1024px) {
      .homepage-section_services .services-one,
      .homepage-section_services .services-two {
        display: block;
        width: 100%; } }
  .homepage-section_services .services-one {
    float: left;
    background-size: cover;
    height: 700px; }
    @media (max-width: 480px) {
      .homepage-section_services .services-one {
        height: 350px; } }
    @media (max-width: 1024px) {
      .homepage-section_services .services-one {
        height: 350px;
        background-position: center; } }
  .homepage-section_services .services-two {
    float: right;
    color: #fff;
    background: url("../images/dots_top.png") no-repeat left top, url("../images/logoC.png") no-repeat right top -5%, url("../images/dots_bottom.png") repeat-x bottom;
    background-color: #2D2D2A; }
    @media (max-width: 480px) {
      .homepage-section_services .services-two {
        height: 700px; } }
    @media (max-width: 1024px) {
      .homepage-section_services .services-two {
        height: 700px; } }
    .homepage-section_services .services-two .services-two_content {
      position: absolute;
      width: 45%;
      height: 80%;
      left: 15%;
      top: 5%;
      text-align: left; }
      @media (max-width: 480px) {
        .homepage-section_services .services-two .services-two_content {
          width: 80%; } }
      .homepage-section_services .services-two .services-two_content .services-logo {
        margin-bottom: 37px;
        width: 90%;
        height: auto; }
      .homepage-section_services .services-two .services-two_content .services-descr {
        position: relative;
        line-height: 30px;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 50px; }
        .homepage-section_services .services-two .services-two_content .services-descr::after {
          content: '';
          position: absolute;
          bottom: -20px;
          left: 0px;
          width: 90px;
          height: 2px;
          background: #fff; }
      .homepage-section_services .services-two .services-two_content .services-descr_liste,
      .homepage-section_services .services-two .services-two_content .mecabam-list {
        font-weight: 200; }
      .homepage-section_services .services-two .services-two_content .mecabam-list {
        padding: 0;
        margin: -20px 0 40px 0; }
      .homepage-section_services .services-two .services-two_content .mecabam-list li {
        list-style: none;
        margin-bottom: -45px;
        padding-left: 1em;
        text-indent: -.7em; }
        .homepage-section_services .services-two .services-two_content .mecabam-list li::before {
          content: '\002022';
          color: #EF3340;
          font-size: 4em;
          vertical-align: middle; }

.homepage-enterprise {
  position: relative;
  width: 100%;
  height: 783px;
  background: url("../images/logoC.png") calc(-5% - 1%) 0 no-repeat, url("../images/dots_transparents_right.png") no-repeat right top; }
  .homepage-enterprise:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 30px;
    height: 50px;
    width: 50%;
    background: url("../images/dots_transparents_bottom.png");
    background-repeat: round; }
    @media (max-width: 1024px) {
      .homepage-enterprise:after {
        display: none; } }
  @media (max-width: 480px) {
    .homepage-enterprise {
      height: 832px; } }
  .homepage-enterprise .homepage-enterprise_one,
  .homepage-enterprise .homepage-enterprise_two {
    display: inline-block;
    position: relative;
    width: 50%;
    height: 100%; }
    .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_one-content,
    .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_one-content {
      position: absolute;
      top: 10%;
      left: 20%;
      height: 60%;
      width: 80%; }
      @media (max-width: 1024px) {
        .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_one-content,
        .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_one-content {
          width: 70%; } }
      @media (max-width: 480px) {
        .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_one-content,
        .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_one-content {
          left: 0;
          top: 5%;
          width: 100%; } }
      .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_one-content .services-logo,
      .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_one-content .services-logo {
        margin-bottom: 37px;
        width: 60%;
        height: auto; }
        @media (max-width: 480px) {
          .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_one-content .services-logo,
          .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_one-content .services-logo {
            width: 100%; } }
      .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_one-content h2,
      .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_one-content h2 {
        position: relative;
        width: 60%;
        line-height: 25px;
        font-size: 2rem;
        font-weight: 800; }
        @media (max-width: 480px) {
          .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_one-content h2,
          .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_one-content h2 {
            width: 80%;
            margin: auto; } }
        .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_one-content h2:after,
        .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_one-content h2:after {
          content: '';
          position: absolute;
          bottom: -20px;
          left: 0px;
          width: 90px;
          height: 2px;
          background: #2D2D2A; }
          @media (max-width: 1024px) {
            .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_one-content h2:after,
            .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_one-content h2:after {
              bottom: -10px; } }
      .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_one-content .services-descr,
      .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_one-content .services-descr {
        margin-top: 5%;
        margin-bottom: 30px; }
        @media (max-width: 480px) {
          .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_one-content .services-descr,
          .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_one-content .services-descr {
            width: 80%;
            margin-top: 10%;
            padding-left: 12px;
            margin-left: auto;
            margin-right: auto; } }
    @media (max-width: 480px) {
      .homepage-enterprise .homepage-enterprise_one .btn, .homepage-enterprise .homepage-enterprise_one .modal-footer .action-primary, .modal-footer .homepage-enterprise .homepage-enterprise_one .action-primary, .homepage-enterprise .homepage-enterprise_one
      .modal-footer .action-secondary,
      .modal-footer .homepage-enterprise .homepage-enterprise_one .action-secondary,
      .homepage-enterprise .homepage-enterprise_two .btn,
      .homepage-enterprise .homepage-enterprise_two .modal-footer .action-primary, .modal-footer
      .homepage-enterprise .homepage-enterprise_two .action-primary,
      .homepage-enterprise .homepage-enterprise_two
      .modal-footer .action-secondary,
      .modal-footer
      .homepage-enterprise .homepage-enterprise_two .action-secondary {
        display: block;
        width: 60%;
        margin: auto; } }
    .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_two--pic,
    .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_two--pic {
      position: absolute;
      top: 12%;
      left: 8%;
      height: 70%;
      width: 90%;
      background: url("../images/mecabam_facade.png") no-repeat;
      background-size: cover; }
      @media (max-width: 992px) {
        .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_two--pic,
        .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_two--pic {
          background-position: 60%; } }
      @media (max-width: 1024px) {
        .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_two--pic,
        .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_two--pic {
          height: 100%;
          width: 100%;
          top: 0;
          left: 0; } }
      @media (max-width: 480px) {
        .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_two--pic,
        .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_two--pic {
          display: none; } }
    .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_two--charte,
    .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_two--charte {
      position: absolute;
      display: block;
      bottom: 0;
      left: 0px;
      height: 300px;
      width: 475px;
      background: url("../images/black-diamond-plate.png") calc(-5% - 1%) 0 no-repeat, url("../images/dots_transparents_right.png") no-repeat right top;
      background-color: #EF3340;
      overflow: hidden; }
      @media (max-width: 480px) {
        .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_two--charte,
        .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_two--charte {
          height: 330px;
          left: 0;
          width: 100%;
          background-color: #2D2D2A; }
          .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_two--charte .btn-white,
          .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_two--charte .btn-white {
            margin: auto;
            display: block;
            width: 70%; } }
      @media (max-width: 1024px) {
        .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_two--charte,
        .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_two--charte {
          right: 0;
          left: inherit; } }
      .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_two--charte .charte_content,
      .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_two--charte .charte_content {
        height: 80%;
        width: 80%;
        margin-top: 6%;
        margin-left: 6%; }
        @media (max-width: 480px) {
          .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_two--charte .charte_content,
          .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_two--charte .charte_content {
            margin-left: auto;
            margin-right: auto;
            margin-top: 8%;
            width: 90%; } }
        .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_two--charte .charte_content h2,
        .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_two--charte .charte_content h2 {
          position: relative;
          width: 60%;
          font-size: 2rem;
          font-weight: 500;
          color: white;
          margin-bottom: 10%; }
          .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_two--charte .charte_content h2:after,
          .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_two--charte .charte_content h2:after {
            content: '';
            position: absolute;
            bottom: -20px;
            left: 0px;
            width: 90px;
            height: 2px;
            background: white; }
        .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_two--charte .charte_content .charte-descr,
        .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_two--charte .charte_content .charte-descr {
          color: white;
          margin-bottom: 10%;
          font-weight: 100; }
        .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_two--charte .charte_content .btn-white,
        .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_two--charte .charte_content .btn-white {
          color: white;
          border-color: white; }
          @media (max-width: 480px) {
            .homepage-enterprise .homepage-enterprise_one .homepage-enterprise_two--charte .charte_content .btn-white,
            .homepage-enterprise .homepage-enterprise_two .homepage-enterprise_two--charte .charte_content .btn-white {
              width: 100%; } }
  .homepage-enterprise .homepage-enterprise_one {
    float: left; }
    @media (max-width: 1024px) {
      .homepage-enterprise .homepage-enterprise_one {
        display: block;
        height: 60%;
        width: 100%; } }
  .homepage-enterprise .homepage-enterprise_two {
    float: right; }
    @media (max-width: 1024px) {
      .homepage-enterprise .homepage-enterprise_two {
        display: block;
        height: 40%;
        width: 100%; } }
    @media (max-width: 480px) {
      .homepage-enterprise .homepage-enterprise_two {
        background: url("../images/dots_transparents_bottom.png") no-repeat left top 12%; } }

.sidebar-main {
  padding-left: 1%; }

.action .sorter-action .sort-asc,
.modes {
  display: none; }

.sorter-action:before {
  content: ''; }

@media (max-width: 480px) {
  .toolbar-sorter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
    .toolbar-sorter .sorter-label {
      margin: 0; } }

.toolbar-sorter #sorter {
  border-bottom: 1px solid #e2e1e1; }
  .toolbar-sorter #sorter:focus {
    box-shadow: none; }

.brand-widget {
  width: 100%;
  overflow: hidden;
  margin-top: 60px;
  margin-bottom: 60px; }
  .brand-widget .owl-item {
    height: 170px; }
  .brand-widget .block-title {
    position: relative;
    margin: auto;
    width: 300px;
    margin-bottom: 30px;
    height: 50px;
    display: block;
    text-align: center; }
    .brand-widget .block-title strong {
      font-weight: 700;
      font-size: 3rem; }
    .brand-widget .block-title::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 34%;
      width: 90px;
      height: 2px;
      background: #EF3340; }
      @media (max-width: 991px) {
        .brand-widget .block-title::after {
          font-size: 1.6rem; } }
      @media (max-width: 480px) {
        .brand-widget .block-title::after {
          font-size: 1.1rem; } }
  .brand-widget .brand-image {
    height: 150px;
    width: 150px;
    margin: auto;
    padding: 15px;
    box-shadow: 1px 1px 2px 2px #ccc; }
  .brand-widget ul {
    padding: 0;
    -webkit-padding-start: 0px; }
  .brand-widget .owl-carousel .owl-loaded .owl-drag {
    margin: 0;
    padding: 0;
    padding-left: 0;
    -webkit-padding-start: 0px; }
  .brand-widget .owl-item li {
    margin-top: 9px; }
  .brand-widget .owl-left,
  .brand-widget .owl-right {
    display: inline-block;
    height: 30px;
    width: 30px;
    margin-top: 20px; }
  .brand-widget .owl-dots {
    display: none; }
  .brand-widget .brandowl-play {
    text-align: center; }
  .brand-widget .brandowl-play > a {
    font-size: 0px;
    background-color: white;
    color: transparent;
    position: relative;
    border: solid 1px #ccc;
    transition: 2s;
    margin-left: -5px; }
    .brand-widget .brandowl-play > a:hover {
      background-color: #EF3340; }
    .brand-widget .brandowl-play > a:before {
      content: '';
      position: absolute;
      border: solid #ccc;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      top: 10px;
      left: 8px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg); }
  .brand-widget .brandowl-play > a:first-of-type {
    margin-left: 0; }
    .brand-widget .brandowl-play > a:first-of-type:before {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      left: 11px; }

.form.search.advanced {
  width: 60%;
  margin: auto;
  padding: 1%;
  margin-top: 5%; }
  .form.search.advanced label.label {
    text-align: left; }
  .form.search.advanced legend.legend {
    margin-left: 0;
    position: relative;
    margin-bottom: 25px; }
  .form.search.advanced .primary {
    width: 100%;
    margin-bottom: 20px; }
  .form.search.advanced input {
    border-radius: 4px; }

.search-autocomplete {
  top: 3px; }
  .search-autocomplete ul {
    border: none; }
    .search-autocomplete ul li {
      border: none; }
      .search-autocomplete ul li:hover {
        background: white; }
    .search-autocomplete ul li.selected {
      background: white; }
  .search-autocomplete .amount {
    display: none; }

.product_rent {
  margin-bottom: 20px;
  border-bottom: 1px solid #e2e1e1;
  max-width: 255px; }
  .product_rent .title {
    font-size: 2.2rem;
    border-bottom: 1px solid #e2e1e1;
    padding-bottom: 4px;
    margin-bottom: 4px; }
  .product_rent .day,
  .product_rent .week,
  .product_rent .weekend,
  .product_rent .month {
    margin-bottom: 4px; }
  .product_rent .value {
    font-weight: 500; }
  .product_rent .label {
    color: #575757; }

.instruction {
  margin-bottom: 40px;
  font-style: italic; }

body {
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    body {
      padding: 0; } }

.page-title-wrapper {
  font-size: 3rem;
  color: #EF3340;
  width: 100%;
  margin: auto;
  text-align: center;
  padding-top: 16px;
  padding-left: 1%;
  padding-bottom: 16px;
  text-shadow: 1px 1px #ccc; }
  .catalog-product-view .page-title-wrapper {
    display: none; }
  .catalog-category-view .page-title-wrapper {
    text-align: left; }

.wrapper {
  flex: 1 0 auto; }

.page-wrapper {
  max-width: 1200px;
  width: 100%;
  margin: auto; }
  @media (max-width: 1200px) {
    .page-wrapper {
      max-width: 100%;
      margin: inherit; } }

.columns .column.main {
  padding-bottom: 0;
  min-height: 400px;
  overflow: hidden; }

.container {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }

.page-main {
  background-color: white;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  max-width: 100%; }

.catalog-product-view .column.main {
  margin-bottom: 140px; }

.page-footer {
  width: 100%;
  background: #2D2D2A;
  color: white; }
  .page-footer .copyright {
    color: white;
    width: 100%;
    margin-top: 40px;
    margin-left: 70px; }
    @media (max-width: 480px) {
      .page-footer .copyright {
        display: block;
        margin: auto; } }

.footer.content {
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  position: relative; }
  @media (max-width: 480px) {
    .footer.content {
      text-align: center;
      padding-top: 10%;
      padding-bottom: 10%; } }

.footer_content {
  width: 100%;
  height: auto;
  display: block;
  position: relative; }
  .footer_content .footer_content_top {
    display: block;
    position: relative;
    height: 120px;
    padding-left: 50px;
    padding-right: 50px; }
    @media (max-width: 680px) {
      .footer_content .footer_content_top {
        padding-left: inherit;
        padding-right: inherit;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around; } }
    @media (max-width: 480px) {
      .footer_content .footer_content_top {
        flex-direction: column; } }
    .footer_content .footer_content_top .footer_content-logo {
      height: 92px;
      width: 257px;
      float: left; }
      @media (max-width: 480px) {
        .footer_content .footer_content_top .footer_content-logo {
          float: none;
          margin: auto; } }
      .footer_content .footer_content_top .footer_content-logo .footer-logo {
        margin: auto;
        height: auto;
        max-width: 100%;
        bottom: 0; }
  @media (max-width: 480px) {
    .footer_content {
      display: block;
      width: 100%; } }
  .footer_content .footer_content-call {
    float: right;
    height: 92px;
    width: auto;
    margin-right: 10px;
    line-height: 85px;
    vertical-align: middle; }
    .footer_content .footer_content-call a {
      color: white;
      border-color: white;
      text-transform: uppercase; }
    @media (max-width: 480px) {
      .footer_content .footer_content-call {
        display: block;
        position: relative;
        width: 100%;
        margin-bottom: 20px;
        margin-right: 0; } }

.footer_content-nav {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  width: 95%;
  height: auto;
  text-align: left;
  background-color: #2D2D2A;
  overflow: hidden; }
  .footer_content-nav ul.first-level {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: auto;
    width: 90%; }
    .footer_content-nav ul.first-level li {
      padding: 10px;
      text-transform: uppercase;
      max-width: inherit; }
      .footer_content-nav ul.first-level li a {
        display: block; }
  .footer_content-nav ul.subnav {
    width: auto;
    overflow: hidden;
    margin-top: 30px; }
    .footer_content-nav ul.subnav li {
      margin-top: 0px;
      text-transform: none;
      text-align: left;
      padding: 0px;
      font-weight: 300; }
    .footer_content-nav ul.subnav a {
      display: inline-block; }
  .footer_content-nav li {
    color: white; }
  .footer_content-nav a {
    color: white; }
    .footer_content-nav a:visited {
      color: white; }
    .footer_content-nav a:hover {
      color: white; }
  @media (max-width: 980px) {
    .footer_content-nav {
      display: none; } }

.footer_content-arrow {
  display: none;
  position: absolute;
  right: 65px;
  bottom: 19px;
  height: 25px;
  width: 23px;
  background-color: #EF3340;
  font-size: 2em;
  text-align: center;
  vertical-align: middle;
  line-height: 8px;
  cursor: pointer; }
  .footer_content-arrow i {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px; }
  .footer_content-arrow .up {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg); }
  @media (max-width: 980px) {
    .footer_content-arrow {
      display: none; } }

#header-banderau {
  position: relative;
  height: 43px;
  width: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.03);
  text-align: center;
  color: #7C7C7C; }
  @media (max-width: 1024px) {
    #header-banderau {
      text-align: left; } }
  @media (max-width: 780px) {
    #header-banderau {
      display: none; } }
  #header-banderau .header-banderau_middle {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    display: inline-block; }
    @media (max-width: 1080px) {
      #header-banderau .header-banderau_middle {
        left: 15px;
        transform: inherit; } }
    #header-banderau .header-banderau_middle a.link-header {
      color: #7C7C7C; }
      #header-banderau .header-banderau_middle a.link-header:hover {
        color: #7C7C7C; }
      #header-banderau .header-banderau_middle a.link-header:visited {
        color: #7C7C7C; }
      #header-banderau .header-banderau_middle a.link-header:focus {
        color: #7C7C7C; }
  #header-banderau .header-banderau_right {
    display: inline-block;
    position: absolute;
    right: 15px; }
  #header-banderau p {
    display: inline-block;
    margin: auto;
    font-size: 1.4rem; }

.header.content {
  padding: 0;
  display: flex;
  align-items: flex-start; }
  @media (min-width: 768px) {
    .header.content {
      max-width: 100%; } }
  .header.content .nav-toggle {
    line-height: 30px;
    vertical-align: middle;
    left: 10px;
    font-weight: 900;
    color: #2D2D2A; }
  .header.content .logo {
    width: 360px;
    height: 120px;
    margin: 0; }
    @media (max-width: 780px) {
      .header.content .logo {
        width: 200px;
        height: 50px;
        left: 10%;
        margin-bottom: 15px; } }
  .header.content .slogan-logo {
    position: absolute;
    top: 41%;
    left: 105%;
    width: 200px; }
    @media (max-width: 992px) {
      .header.content .slogan-logo {
        display: none; } }
  .header.content .no-a {
    color: black;
    font-weight: 700; }
    .header.content .no-a:hover {
      color: black; }
  .header.content .header_mobile-icons {
    position: absolute;
    right: 10px;
    line-height: 70px;
    vertical-align: middle;
    color: black; }
    @media (min-width: 780px) {
      .header.content .header_mobile-icons {
        display: none;
        line-height: 70px; } }
    @media (min-width: 480px) {
      .header.content .header_mobile-icons {
        line-height: 70px; } }
    .header.content .header_mobile-icons a {
      color: black; }
    .header.content .header_mobile-icons a:hover {
      color: #2D2D2A; }
    .header.content .header_mobile-icons a:focus {
      color: #2D2D2A; }
    .header.content .header_mobile-icons svg.icon {
      width: 3em;
      height: 1.6em; }
  .header.content .block-search {
    width: 250px;
    z-index: 4;
    position: absolute;
    right: 55px;
    top: 50px; }
    .header.content .block-search .control input {
      border: none; }
    .header.content .block-search #search_autocomplete {
      display: none; }
    .header.content .block-search .nested {
      display: none; }
    @media (max-width: 780px) {
      .header.content .block-search {
        display: none; } }

.header.content::before {
  display: none;
  content: ''; }

.header.content::after {
  display: none;
  content: ''; }

/*doc
---
title: General
name: general
category: Pages
---
When creating a new cms page, be sure to set the `1 column` template in order to have the correct layout applied. This is valid for all the CMS templates.

The heading will be displayed at the top of the page. Most of the time, we recommend to match the page title and the heading.
*/
.page {
  padding: 0;
  font-size: 1.7rem;
  letter-spacing: .2px; }
  .page > h1,
  .page > h2,
  .page > h3,
  .page > h4,
  .page > h5,
  .page > h6 {
    font-size: 2.2rem;
    font-weight: bold;
    letter-spacing: .5px;
    text-transform: uppercase; }
  .page > h2 {
    font-size: 2rem; }
  .page > h3 {
    font-size: 1.8rem; }
  .page > h4 {
    font-size: 1.7rem; }
  .page > h5,
  .page > h6 {
    font-size: 1.6rem; }

.homepage-section {
  margin: 0; }
  @media (min-width: 768px) {
    .homepage-section {
      margin: 0; } }

.homepage-section__title {
  position: relative;
  display: block;
  width: 100%;
  margin: 0;
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: .5px;
  text-align: center; }
  .homepage-section__title::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: calc(50% - 45px);
    width: 90px;
    height: 2px;
    background: #EF3340; }
  @media (min-width: 768px) {
    .homepage-section__title {
      margin: 0;
      font-size: 3.4rem;
      letter-spacing: .8px; } }

.homepage-cover {
  position: relative;
  display: block;
  width: 100%;
  margin: 0;
  font-size: 2.6rem;
  font-weight: 300;
  letter-spacing: .5px;
  line-height: 1.3;
  text-align: center; }
  @media (max-width: 480px) {
    .homepage-cover {
      height: 600px;
      font-weight: 500; } }
  @media (max-width: 1200px) {
    .homepage-cover {
      height: 380px; } }
  .homepage-cover .homepage-cover_img {
    height: 100%;
    width: 100%; }
    .homepage-cover .homepage-cover_img img {
      object-fit: cover;
      width: 100%; }
    @media (max-width: 480px) {
      .homepage-cover .homepage-cover_img {
        display: none; } }
  .homepage-cover .homepage-cover_img_mobile {
    display: none; }
    .homepage-cover .homepage-cover_img_mobile img {
      object-fit: cover;
      width: 100%;
      height: 600px; }
    @media (max-width: 480px) {
      .homepage-cover .homepage-cover_img_mobile {
        display: block; } }
  .homepage-cover__text {
    position: absolute;
    top: 80px;
    right: 6%;
    width: 40%;
    text-align: left; }
    @media (max-width: 768px) {
      .homepage-cover__text {
        top: 5%;
        left: 15%;
        width: 80%; } }
    @media (max-width: 480px) {
      .homepage-cover__text {
        width: 80%;
        top: 10px;
        left: 1%; } }
    .homepage-cover__text h1 {
      font-weight: 600;
      font-size: 4.2rem;
      color: #EF3340;
      margin-bottom: 20px; }
      @media (max-width: 480px) {
        .homepage-cover__text h1 {
          font-size: 2.6rem; } }
    .homepage-cover__text p {
      position: relative;
      color: #333333;
      font-size: 1.8rem;
      font-weight: 400; }
      .homepage-cover__text p::after {
        content: '';
        position: absolute;
        bottom: -20px;
        left: 0px;
        width: 90px;
        height: 2px;
        background: #333333; }
        @media (max-width: 991px) {
          .homepage-cover__text p::after {
            font-size: 1.6rem; } }
        @media (max-width: 480px) {
          .homepage-cover__text p::after {
            font-size: 1.1rem; } }
    .homepage-cover__text button {
      margin-top: 40px;
      padding: 8px 20px 10px 20px;
      color: #EF3340;
      font-size: 1.6rem;
      border-radius: 5;
      border: 1px solid #EF3340;
      background-color: transparent; }

.toolbar-products {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left; }
  .toolbar-products select {
    border: none;
    background: transparent; }
    .toolbar-products select:focus, .toolbar-products select:active, .toolbar-products select:hover, .toolbar-products select:checked {
      border: none;
      background: transparent; }
    .toolbar-products select option {
      background: transparent; }

.sidebar-main {
  margin-top: 90px; }

@media (min-width: 768px) {
  .sidebar-additional {
    padding-right: 0; } }

@media (min-width: 640px) {
  .product-item-actions .actions-primary {
    display: inherit;
    text-align: center; } }

.filter-title {
  display: none; }

@media (max-width: 768px) {
  .filter-content {
    text-align: left; } }

.filter-content .action.remove {
  display: block;
  margin-top: 16px; }

.block-content a:visited {
  color: #EF3340; }

.block-content a:hover {
  color: #EF3340; }

.block-compare {
  padding: 7%; }
  .block-compare .actions-toolbar {
    padding: 11%; }
    @media (max-width: 1800px) {
      .block-compare .actions-toolbar {
        padding: 0; } }
    .block-compare .actions-toolbar a.action {
      margin-top: 0; }
    .block-compare .actions-toolbar .compare {
      margin-bottom: 15px; }
      .block-compare .actions-toolbar .compare a, .block-compare .actions-toolbar .compare a:hover {
        background: transparent;
        border: none;
        color: white;
        font-weight: 700;
        font-size: 1.6rem; }
  .block-compare .delete:before:hover {
    color: #EF3340; }

.page-products .products-grid .product-items {
  padding-right: 2%;
  margin-left: 0; }
  @media (max-width: 768px) {
    .page-products .products-grid .product-items {
      padding-right: 0; } }

.page-products .products-grid .product-item {
  position: relative;
  background-color: white;
  padding: 2%;
  border-radius: 6px;
  border: solid 1px rgba(33, 33, 33, 0.1);
  text-align: center;
  overflow: hidden; }
  @media (max-width: 480px) {
    .page-products .products-grid .product-item {
      display: block;
      width: 100%;
      max-width: 80%;
      margin: auto;
      margin-bottom: 12px; } }
  @media (max-width: 360px) {
    .page-products .products-grid .product-item {
      max-width: 100%;
      margin: auto; } }
  .page-products .products-grid .product-item .price-box {
    height: 70px;
    margin: 25px 0 25px;
    line-height: 25px; }
    .page-products .products-grid .product-item .price-box .price-label {
      font-size: 1.2rem; }
  .page-products .products-grid .product-item .stock-on-top {
    width: 80%;
    margin: auto; }
    .page-products .products-grid .product-item .stock-on-top .out-of,
    .page-products .products-grid .product-item .stock-on-top .for-rent {
      color: rgba(46, 125, 50, 0.7);
      font-weight: 600;
      text-align: center;
      width: 100%;
      margin: auto; }
    .page-products .products-grid .product-item .stock-on-top .for-rent {
      display: block; }
  .page-products .products-grid .product-item .product-item-info {
    margin: auto;
    width: 270px; }
    .page-products .products-grid .product-item .product-item-info img {
      margin: auto;
      height: 234px;
      width: auto; }
  @media (max-width: 640px) {
    .page-products .products-grid .product-item .product-item-inner .product-item-actions {
      display: block; } }
  @media (max-width: 773px) {
    .page-products .products-grid .product-item .product-item-inner .product-item-actions .btn, .page-products .products-grid .product-item .product-item-inner .product-item-actions .modal-footer .action-primary, .modal-footer .page-products .products-grid .product-item .product-item-inner .product-item-actions .action-primary, .page-products .products-grid .product-item .product-item-inner .product-item-actions
    .modal-footer .action-secondary,
    .modal-footer .page-products .products-grid .product-item .product-item-inner .product-item-actions .action-secondary {
      width: 100%;
      margin: auto;
      padding: 12px 20px;
      text-overflow: inherit; } }
  .page-products .products-grid .product-item .product-item-inner .product-item-action-view {
    margin-top: 10px;
    margin-bottom: 15px; }
  .page-products .products-grid .product-item .product-item-inner .addcompare {
    background-color: rgba(69, 90, 100, 0.6);
    position: relative;
    display: none; }
    @media (max-width: 768px) {
      .page-products .products-grid .product-item .product-item-inner .addcompare {
        display: none; } }
    .page-products .products-grid .product-item .product-item-inner .addcompare a::before {
      font-family: 'icons-blank-theme';
      font-size: 0;
      color: transparent;
      position: absolute;
      left: -1500px;
      height: 0.1px;
      width: 0.1px; }
    .page-products .products-grid .product-item .product-item-inner .addcompare .addcompare-icon {
      color: white;
      line-height: 20px;
      vertical-align: middle; }
      .page-products .products-grid .product-item .product-item-inner .addcompare .addcompare-icon .icon-compare {
        font-family: 'icons-blank-theme';
        content: '';
        font-size: 2.5rem;
        color: white; }
  .page-products .products-grid .product-item .product-item-name {
    height: 80px;
    overflow: hidden;
    position: relative;
    margin-top: 5%;
    margin-bottom: 2%;
    text-overflow: ellipsis; }
    .page-products .products-grid .product-item .product-item-name a {
      font-size: 2rem;
      font-weight: 700;
      line-height: 25px;
      text-decoration: none; }
      .page-products .products-grid .product-item .product-item-name a:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 15%;
        width: 70%;
        height: 3px;
        background: #EF3340; }
        @media (max-width: 991px) {
          .page-products .products-grid .product-item .product-item-name a:after {
            font-size: 1.6rem; } }
        @media (max-width: 480px) {
          .page-products .products-grid .product-item .product-item-name a:after {
            font-size: 1.1rem; } }
  .page-products .products-grid .product-item .price-box {
    height: 45px; }
    .page-products .products-grid .product-item .price-box .price {
      font-weight: 700;
      font-size: 2rem; }
    .page-products .products-grid .product-item .price-box .old-price .price {
      font-size: 1.2rem; }
  .page-products .products-grid .product-item .product-reviews-summary {
    display: block;
    margin: 0; }
    .page-products .products-grid .product-item .product-reviews-summary .reviews-actions {
      line-height: inherit;
      margin: 0;
      display: none; }
      .page-products .products-grid .product-item .product-reviews-summary .reviews-actions a.action.view, .page-products .products-grid .product-item .product-reviews-summary .reviews-actions span {
        color: black; }
      .page-products .products-grid .product-item .product-reviews-summary .reviews-actions a .action .view:visited {
        color: black; }
      .page-products .products-grid .product-item .product-reviews-summary .reviews-actions a {
        color: black; }
  .page-products .products-grid .product-item .review-block {
    display: inline-block;
    position: absolute;
    top: 0;
    left: -10px;
    overflow: hidden;
    vertical-align: middle;
    padding: 12px 40px;
    font-size: 1.6rem;
    line-height: 1.1;
    font-weight: 500;
    white-space: nowrap;
    text-transform: none;
    text-overflow: ellipsis;
    text-decoration: none;
    text-align: center;
    background-image: none;
    border: 0; }
    @media (max-width: 1440px) {
      .page-products .products-grid .product-item .review-block {
        left: -35px; } }
    @media (max-width: 768px) {
      .page-products .products-grid .product-item .review-block {
        display: none; } }

.product-reviews-summary .reviews-actions a {
  color: black; }
  .product-reviews-summary .reviews-actions a:hover {
    color: black; }

.page-products .message.info.empty {
  max-width: 400px;
  background-color: transparent;
  border-color: transparent;
  color: black; }
  @media (max-width: 480px) {
    .page-products .message.info.empty {
      width: 70%;
      max-width: 100%; } }

.pages a.next:hover,
.pages a.previous:hover {
  background-color: #EF3340;
  color: #ccc;
  font-weight: 700; }

.pages .action.next:before {
  color: #ccc; }

.pages .action.next:visited:before {
  color: #ccc; }

.pages a.page span {
  color: #2D2D2A; }

.catalogsearch-advanced-result .search.found,
.catalogsearch-advanced-result .search.summary {
  padding-left: 1%; }

@media (min-width: 768px) {
  .product.data.items > .item.title > .switch {
    height: 30px; } }

.product.data.items > .item.content {
  background: white; }
  @media (min-width: 768px) {
    .product.data.items > .item.content {
      margin-top: 42px;
      border-top: none; } }

.review-title {
  font-weight: 450; }

.review-form {
  margin: auto; }
  @media (min-width: 768px) {
    .review-form {
      margin: inherit; } }

@media (min-width: 768px) {
  .review-content {
    width: 60%; } }

.product-reviews-summary .reviews-actions a {
  font-style: italic;
  color: #ccc;
  display: none; }
  .product-reviews-summary .reviews-actions a:hover {
    color: #ccc; }

.page-layout-1column .product.media {
  width: 40%;
  padding-left: 60px; }
  @media (max-width: 768px) {
    .page-layout-1column .product.media {
      width: 100%;
      padding-left: inherit; } }

.fotorama__nav-wrap {
  margin-bottom: 20px; }
  .fotorama__nav-wrap .fotorama__nav--thumbs {
    text-align: center; }

.fotorama__stage {
  width: 500px !important; }
  @media (max-width: 1200px) {
    .fotorama__stage {
      height: auto; } }

.fotorama__stage__shaft .fotorama__grab {
  width: 500px; }

.fotorama__stage__frame {
  height: 500px;
  background: white; }

.fotorama-item {
  margin: auto; }

.fotorama__wrap {
  width: 500px;
  max-width: inherit;
  min-width: inherit; }
  @media (max-width: 768px) {
    .fotorama__wrap {
      margin: auto; } }

.gallery-placeholder {
  margin: auto; }

.fotorama--fullscreen {
  background: rgba(0, 0, 0, 0.6); }
  .fotorama--fullscreen .fotorama__stage {
    height: 500px !important;
    margin-top: 35%; }

.fotorama--fullscreen .fotorama__wrap {
  margin: auto; }

.fotorama__arr--disabled {
  opacity: 0; }

.fotorama__stage__frame .fotorama__img {
  border: 2px solid #ccc;
  height: auto;
  width: auto;
  top: 40%; }
  @media (max-width: 1115px) {
    .fotorama__stage__frame .fotorama__img {
      top: 30%; } }
  @media (max-width: 1024px) {
    .fotorama__stage__frame .fotorama__img {
      top: 30%; } }
  @media (max-width: 768px) {
    .fotorama__stage__frame .fotorama__img {
      top: 50%; } }
  @media (max-width: 480px) {
    .fotorama__stage__frame .fotorama__img {
      top: 40%; } }
  @media (max-width: 375px) {
    .fotorama__stage__frame .fotorama__img {
      top: 30%; } }

.fotorama__thumb-border {
  border: 1px solid #ccc; }

.fotorama__nav__frame--thumb {
  opacity: 0.3; }
  .fotorama__nav__frame--thumb:hover {
    opacity: 1; }

.fotorama__active {
  opacity: 1; }

.fotorama__active .fotorama__dot {
  background-color: #EF3340;
  border-color: #EF3340; }

.fotorama__dot {
  border-radius: 16px;
  height: 14px;
  width: 14px; }

.product-reviews-summary .reviews-actions a {
  font-style: italic;
  color: #ccc; }
  .product-reviews-summary .reviews-actions a:hover {
    color: #ccc; }

.product.info.detailed {
  margin-left: 60px; }
  @media (max-width: 767px) {
    .product.info.detailed {
      margin-left: 0; } }

@media (max-width: 767px) {
  .product.data.items > .item.title,
  .product.data.items > .item.content {
    margin: 0; } }

.product.data.items > .item.title > .switch {
  background: white;
  color: #424242;
  border: none; }

.product.data.items > .item.title.active > .switch {
  background: white;
  color: #424242;
  border-bottom: 2px solid #EF3340; }
  @media (max-width: 767px) {
    .product.data.items > .item.title.active > .switch {
      padding: 0;
      text-align: center; } }

.column.main .product-item.complementary-item {
  padding-left: 0;
  padding: 3%;
  width: 300px; }
  @media (max-width: 1000px) {
    .column.main .product-item.complementary-item {
      width: 210px; } }
  @media (max-width: 900px) {
    .column.main .product-item.complementary-item {
      width: 190px; } }
  @media (max-width: 767px) {
    .column.main .product-item.complementary-item {
      width: 180px; } }
  @media (max-width: 480px) {
    .column.main .product-item.complementary-item {
      width: 150px; } }

.column.main .product-items.complementary-items {
  display: block;
  margin-left: 0; }
  @media (max-width: 768px) {
    .column.main .product-items.complementary-items {
      display: inherit; } }
  .column.main .product-items.complementary-items li {
    background: white;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: .5%; }

.products-grid .product-item {
  border: 1px solid #ccc;
  border-radius: 6px; }
  .products-grid .product-item .product-item-info {
    max-width: 100%;
    width: 100%; }
    .products-grid .product-item .product-item-info img {
      margin-bottom: 20px;
      margin-top: 2px;
      width: 100%;
      height: 100%; }
    .products-grid .product-item .product-item-info strong {
      height: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative; }
      .products-grid .product-item .product-item-info strong a {
        font-size: 1.7rem;
        font-weight: 600;
        height: 100px;
        overflow: hidden;
        color: #2D2D2A; }
        .products-grid .product-item .product-item-info strong a:after {
          content: '';
          height: 2px;
          width: 80%;
          bottom: 10px;
          left: 10%;
          position: absolute;
          background: #EF3340; }
    .products-grid .product-item .product-item-info .price-box {
      min-height: 22px; }
    .products-grid .product-item .product-item-info .price {
      font-size: 1.8rem;
      height: 100px;
      overflow: hidden;
      color: #2D2D2A; }
    .products-grid .product-item .product-item-info a.btn, .products-grid .product-item .product-item-info .modal-footer a.action-primary, .modal-footer .products-grid .product-item .product-item-info a.action-primary, .products-grid .product-item .product-item-info
    .modal-footer a.action-secondary,
    .modal-footer .products-grid .product-item .product-item-info a.action-secondary {
      color: white;
      width: 100%;
      text-overflow: inherit;
      padding: 12px 20px; }
      .products-grid .product-item .product-item-info a.btn:hover, .products-grid .product-item .product-item-info .modal-footer a.action-primary:hover, .modal-footer .products-grid .product-item .product-item-info a.action-primary:hover, .products-grid .product-item .product-item-info
      .modal-footer a.action-secondary:hover,
      .modal-footer .products-grid .product-item .product-item-info a.action-secondary:hover {
        color: white; }
  .products-grid .product-item a:hover, .products-grid .product-item a:visited {
    color: #2D2D2A;
    text-decoration: none; }

.name-complementary {
  font-size: 2rem;
  margin-left: 5%;
  padding-top: 30px;
  padding-bottom: 5px;
  border-bottom: solid 3px red;
  width: 250px; }
  @media (max-width: 480px) {
    .name-complementary {
      margin: auto; } }

.homepage-section.complementary {
  background: white;
  display: block; }

.homepage-section_product.complementary {
  background: white;
  padding: 0; }
  @media (max-width: 480px) {
    .homepage-section_product.complementary {
      display: block;
      height: auto;
      padding: 0;
      margin-left: inherit;
      background: inherit;
      background-color: transparent; }
      .homepage-section_product.complementary .complementary-items {
        display: flex;
        justify-content: space-between; }
      .homepage-section_product.complementary .homepage-section_product-item:nth-last-child(3) {
        display: none; }
      .homepage-section_product.complementary .homepage-section_product-item:nth-last-child(2) {
        display: none; }
      .homepage-section_product.complementary .homepage-section_product-item:nth-last-child(1) {
        display: none; } }
  .homepage-section_product.complementary .block-complementary {
    width: 100%; }
  @media (max-width: 480px) {
    .homepage-section_product.complementary .homepage-section_product-item {
      display: block; } }

.column.main .block:last-child {
  width: 100%;
  height: 100%;
  clear: both; }

.product.data.items > .item.title:not(.disabled) > .switch:active,
.product.data.items > .item.title.active > .switch,
.product.data.items > .item.title.active > .switch:focus,
.product.data.items > .item.title.active > .switch:hover {
  background: white; }

.page-layout-1column .product-info-main {
  width: 60%; }
  @media (max-width: 768px) {
    .page-layout-1column .product-info-main {
      width: 80%; } }

.product-info-main {
  position: relative;
  padding-left: 20px; }
  @media (max-width: 768px) {
    .product-info-main {
      padding: 2%; } }
  .product-info-main .product.attribute.name {
    color: #2D2D2A;
    width: 65%;
    font-weight: 600;
    font-size: 3.5rem;
    margin-bottom: 10px; }
    @media (max-width: 940px) {
      .product-info-main .product.attribute.name {
        width: 60%;
        font-size: 2.3rem; } }
    @media (max-width: 768px) {
      .product-info-main .product.attribute.name {
        width: 100%; } }
    .product-info-main .product.attribute.name strong {
      display: none; }
  .product-info-main .product.attribute.overview {
    width: 70%; }
    .product-info-main .product.attribute.overview strong {
      display: none; }
    @media (max-width: 480px) {
      .product-info-main .product.attribute.overview {
        width: 100%; } }
  .product-info-main .stock.available {
    color: rgba(46, 125, 50, 0.7); }
  .product-info-main .brand-label {
    display: block;
    float: left;
    margin-right: 20px; }
    .product-info-main .brand-label:after {
      content: '';
      height: 4px;
      width: 60px;
      position: absolute;
      background-color: #EF3340; }
  .product-info-main .brand-image {
    display: inline-block;
    position: absolute;
    right: 60px;
    top: 0; }
    @media (max-width: 768px) {
      .product-info-main .brand-image {
        display: none; } }
    .product-info-main .brand-image img {
      margin: auto;
      width: 150px;
      height: 150px; }
  .product-info-main .product-info-price {
    margin-bottom: 20px; }
  .product-info-main .price-box .price {
    color: #2D2D2A;
    font-size: 2.5rem; }
  .product-info-main .btn:visited, .product-info-main .modal-footer .action-primary:visited, .modal-footer .product-info-main .action-primary:visited, .product-info-main
  .modal-footer .action-secondary:visited,
  .modal-footer .product-info-main .action-secondary:visited {
    color: #EF3340; }
  .product-info-main .product_call-to {
    margin-top: 20px;
    margin-bottom: 20px; }
    .product-info-main .product_call-to .btn, .product-info-main .product_call-to .modal-footer .action-primary, .modal-footer .product-info-main .product_call-to .action-primary, .product-info-main .product_call-to
    .modal-footer .action-secondary,
    .modal-footer .product-info-main .product_call-to .action-secondary {
      margin: auto; }
      .product-info-main .product_call-to .btn:visited, .product-info-main .product_call-to .modal-footer .action-primary:visited, .modal-footer .product-info-main .product_call-to .action-primary:visited, .product-info-main .product_call-to
      .modal-footer .action-secondary:visited,
      .modal-footer .product-info-main .product_call-to .action-secondary:visited {
        color: #FFFFFF; }

.container-static_page {
  margin-top: 40px;
  height: 100%;
  width: 100%;
  margin: auto;
  padding: 0px; }
  @media (max-width: 768px) {
    .container-static_page {
      padding-left: 1%;
      padding-right: 1%; } }
  @media (max-width: 480px) {
    .container-static_page {
      width: 100%;
      padding: 10px; } }
  .container-static_page h1 {
    font-size: 3rem;
    color: #EF3340;
    width: 80%;
    margin: auto;
    text-align: left;
    padding-top: 16px;
    padding-bottom: 16px;
    text-shadow: 1px 1px #ccc; }
    @media (max-width: 768px) {
      .container-static_page h1 {
        width: 100%; } }
  .container-static_page h2 {
    font-size: 2.5rem;
    color: #575757;
    width: 80%;
    padding: 16px;
    margin: auto;
    text-align: left; }
    @media (max-width: 768px) {
      .container-static_page h2 {
        width: 100%; } }
  .container-static_page .simple-block_text {
    width: 100%;
    margin: auto;
    margin-bottom: 28px;
    margin-top: 40px; }
    .container-static_page .simple-block_text:after {
      content: '';
      display: block;
      clear: both; }
    .container-static_page .simple-block_text h1 {
      font-size: 3rem;
      color: #EF3340;
      width: 80%;
      margin: auto;
      text-align: left;
      padding-top: 16px;
      padding-bottom: 16px;
      text-shadow: 1px 1px #ccc; }
      @media (max-width: 768px) {
        .container-static_page .simple-block_text h1 {
          width: 100%; } }
    .container-static_page .simple-block_text h2 {
      font-size: 2.5rem;
      color: #575757;
      width: 80%;
      padding: 16px 8px;
      margin: auto;
      text-align: left; }
      @media (max-width: 768px) {
        .container-static_page .simple-block_text h2 {
          width: 100%; } }
    .container-static_page .simple-block_text p {
      font-size: 1.7rem;
      width: 80%;
      margin: auto;
      padding: 8px; }
      @media (max-width: 768px) {
        .container-static_page .simple-block_text p {
          width: 100%; } }
    .container-static_page .simple-block_text span.address {
      font-weight: 500;
      font-style: italic; }
    .container-static_page .simple-block_text a {
      color: #575757;
      font-weight: 600;
      text-decoration: underline !important; }
      .container-static_page .simple-block_text a:hover, .container-static_page .simple-block_text a:focus {
        text-decoration: underline !important;
        color: #EF3340; }
      .container-static_page .simple-block_text a:visited {
        color: #333333;
        text-decoration: underline !important; }
        .container-static_page .simple-block_text a:visited:hover {
          color: #EF3340; }
    .container-static_page .simple-block_text .wrap-left {
      width: 100%;
      margin: auto;
      margin-bottom: 30px; }
      .container-static_page .simple-block_text .wrap-left p {
        width: 100%;
        padding: 0; }
      .container-static_page .simple-block_text .wrap-left .left {
        width: 60%;
        float: left;
        padding-left: 10px;
        padding-right: 10px; }
        @media (max-width: 1100px) {
          .container-static_page .simple-block_text .wrap-left .left {
            float: none;
            width: 80%;
            margin: auto; } }
        @media (max-width: 1024px) {
          .container-static_page .simple-block_text .wrap-left .left {
            margin-bottom: 10px; } }
        @media (max-width: 480px) {
          .container-static_page .simple-block_text .wrap-left .left {
            float: none;
            width: 99%;
            margin: auto;
            margin-bottom: 10px; } }
      .container-static_page .simple-block_text .wrap-left .right {
        width: 40%;
        float: right;
        padding-left: 10px;
        padding-right: 10px; }
        @media (max-width: 1100px) {
          .container-static_page .simple-block_text .wrap-left .right {
            float: none;
            width: 80%;
            margin: auto; } }
        @media (max-width: 480px) {
          .container-static_page .simple-block_text .wrap-left .right {
            float: none;
            width: 99%;
            margin: auto; } }
    .container-static_page .simple-block_text .wrap-right {
      width: 100%;
      margin: auto;
      margin-bottom: 20px; }
      .container-static_page .simple-block_text .wrap-right .left {
        width: 60%;
        float: left;
        padding-left: 10px;
        padding-right: 10px; }
        @media (max-width: 1100px) {
          .container-static_page .simple-block_text .wrap-right .left {
            float: none;
            width: 80%;
            margin: auto;
            margin-bottom: 30px; } }
        @media (max-width: 480px) {
          .container-static_page .simple-block_text .wrap-right .left {
            float: none;
            width: 99%;
            margin: auto;
            margin-bottom: 30px; } }
        .container-static_page .simple-block_text .wrap-right .left p {
          padding-left: 0; }
      .container-static_page .simple-block_text .wrap-right .right {
        float: right;
        width: 40%; }
        @media (max-width: 1100px) {
          .container-static_page .simple-block_text .wrap-right .right {
            float: none;
            width: 80%;
            margin: auto;
            margin-top: 20px; } }
        @media (max-width: 480px) {
          .container-static_page .simple-block_text .wrap-right .right {
            float: none;
            width: 99%;
            margin: auto; } }
    .container-static_page .simple-block_text .container-block-sav {
      width: 100%;
      display: block;
      margin-top: 20px; }
      .container-static_page .simple-block_text .container-block-sav p {
        width: 100%;
        padding: 0;
        margin-bottom: 20px; }
      .container-static_page .simple-block_text .container-block-sav .container-block-sav_left {
        width: 60%;
        float: left; }
        @media (max-width: 768px) {
          .container-static_page .simple-block_text .container-block-sav .container-block-sav_left {
            float: none;
            width: 90%;
            margin: auto; } }
        .container-static_page .simple-block_text .container-block-sav .container-block-sav_left .wrap-sav {
          width: 100%;
          margin: auto;
          margin-bottom: 20px; }
          .container-static_page .simple-block_text .container-block-sav .container-block-sav_left .wrap-sav:after {
            content: '';
            display: block;
            clear: both; }
          .container-static_page .simple-block_text .container-block-sav .container-block-sav_left .wrap-sav .left {
            width: 30%;
            float: left; }
          .container-static_page .simple-block_text .container-block-sav .container-block-sav_left .wrap-sav .right {
            width: 70%;
            float: right;
            padding-left: 20px;
            padding-right: 20px; }
      .container-static_page .simple-block_text .container-block-sav .container-block-sav_right {
        width: 40%;
        float: right; }
        @media (max-width: 768px) {
          .container-static_page .simple-block_text .container-block-sav .container-block-sav_right {
            float: none;
            width: 90%;
            margin: auto; } }
        .container-static_page .simple-block_text .container-block-sav .container-block-sav_right .wrap-sav {
          width: 100%;
          margin: auto;
          margin-bottom: 20px; }
          .container-static_page .simple-block_text .container-block-sav .container-block-sav_right .wrap-sav:after {
            content: '';
            display: block;
            clear: both; }
          .container-static_page .simple-block_text .container-block-sav .container-block-sav_right .wrap-sav .left {
            width: 40%;
            float: left; }
            @media (max-width: 768px) {
              .container-static_page .simple-block_text .container-block-sav .container-block-sav_right .wrap-sav .left {
                width: 30%; } }
          .container-static_page .simple-block_text .container-block-sav .container-block-sav_right .wrap-sav .right {
            width: 60%;
            float: right;
            padding-left: 20px; }
            @media (max-width: 768px) {
              .container-static_page .simple-block_text .container-block-sav .container-block-sav_right .wrap-sav .right {
                width: 70%; } }
    .container-static_page .simple-block_text .showroom-left {
      float: left;
      width: 50%; }
      @media (max-width: 768px) {
        .container-static_page .simple-block_text .showroom-left {
          float: none;
          width: 90%;
          margin: auto; } }
      .container-static_page .simple-block_text .showroom-left .content_showroom-left {
        width: 90%;
        border: 2px solid #2D2D2A;
        text-align: center; }
        @media (max-width: 768px) {
          .container-static_page .simple-block_text .showroom-left .content_showroom-left {
            width: 100%;
            margin: auto; } }
        .container-static_page .simple-block_text .showroom-left .content_showroom-left h2 {
          text-align: center; }
        .container-static_page .simple-block_text .showroom-left .content_showroom-left .content_showroom-left-pic {
          display: flex;
          margin-top: 10px;
          margin-bottom: 10px;
          justify-content: space-around; }
          .container-static_page .simple-block_text .showroom-left .content_showroom-left .content_showroom-left-pic img {
            height: auto;
            width: 150px; }
    .container-static_page .simple-block_text .showroom-right {
      float: right;
      width: 50%; }
      @media (max-width: 768px) {
        .container-static_page .simple-block_text .showroom-right {
          float: none;
          width: 90%;
          margin: auto;
          margin-top: 20px; } }
      .container-static_page .simple-block_text .showroom-right .content_showroom-right {
        width: 90%; }
        @media (max-width: 768px) {
          .container-static_page .simple-block_text .showroom-right .content_showroom-right {
            width: 100%;
            margin: auto; } }
        .container-static_page .simple-block_text .showroom-right .content_showroom-right .content_showroom-right-pic {
          width: 40%;
          float: left; }
        .container-static_page .simple-block_text .showroom-right .content_showroom-right .content_showroom-right-text {
          width: 60%;
          float: right; }
          .container-static_page .simple-block_text .showroom-right .content_showroom-right .content_showroom-right-text p {
            width: 80%;
            margin-left: 0; }
  .container-static_page .container-form {
    width: 60%;
    margin: auto;
    margin-top: 20px; }
    @media (max-width: 768px) {
      .container-static_page .container-form {
        width: 90%; } }
    .container-static_page .container-form input {
      border-radius: 6px; }
  .container-static_page .page-store__map {
    width: 60%;
    margin: auto;
    margin-bottom: 46px;
    text-align: center; }
    @media (max-width: 768px) {
      .container-static_page .page-store__map {
        width: 100%;
        overflow: hidden; } }
    .container-static_page .page-store__map .page-store__map-iframe #map {
      min-height: 400px;
      border-radius: 10px; }
    .container-static_page .page-store__map iframe {
      width: 100%;
      height: 400px;
      border-radius: 20px;
      border: 1px solid #ccc; }

.fieldset > .field:not(.choice) > .label {
  text-align: left; }
