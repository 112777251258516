//
// Page
// --------------------------------------------------------------------------


// Documentation
// --------------------------------------------------------------------------

/*doc
---
title: General
name: general
category: Pages
---
When creating a new cms page, be sure to set the `1 column` template in order to have the correct layout applied. This is valid for all the CMS templates.

The heading will be displayed at the top of the page. Most of the time, we recommend to match the page title and the heading.
*/


// Page
// --------------------------------------------------------------------------

.page {
    padding: 0;
    font-size: $font-size-base + .1rem;
    letter-spacing: .2px;

    & > h1,
    & > h2,
    & > h3,
    & > h4,
    & > h5,
    & > h6 {
        font-size: $font-size-large + .4rem;
        font-weight: bold;
        letter-spacing: .5px;
        text-transform: uppercase;
    }

    & > h2 {
        font-size: $font-size-large + .2rem;
    }

    & > h3 {
        font-size: $font-size-large;
    }

    & > h4 {
        font-size: $font-size-base + .1rem;
    }

    & > h5,
    & > h6 {
        font-size: $font-size-base;
    }
}
