//Resets Magento
.product.data.items > .item.title > .switch{
    @media(min-width: $screen-sm-min){
        height: 30px;
    }
}


//Reviews
.product.data.items > .item.content{
    background: white;

    @media(min-width: 768px){
        margin-top: 42px;
        border-top: none;
    }
}

.review-title{
    font-weight: 450;
}

.review-form{
    margin: auto;

    @media(min-width:$screen-sm-min){
        margin: inherit;
    }
}

.review-content{
    @media(min-width:768px){
        width: 60%;
    }
}

.product-reviews-summary .reviews-actions a{
    font-style: italic; 
    color: #ccc;
    display: none;
    &:hover{
        color: #ccc;
    }
}

//Picture controls mobile
.page-layout-1column .product.media{
    width: 40%; 
    padding-left: 60px;

    @media(max-width:768px){
        width: 100%; 
        padding-left: inherit;
    }
}


.fotorama__nav-wrap{
    margin-bottom: 20px;

    .fotorama__nav--thumbs{
        text-align: center;
    }
} 

.fotorama__stage{ 
    width: 500px !important;

    @media(max-width:1200px){
        height: auto;
    }
}

.fotorama__stage__shaft .fotorama__grab{
    width: 500px;
}

.fotorama__stage__frame{
    height: 500px; 
    background: white;
}

.fotorama-item{
    margin: auto;
} 

.fotorama__wrap{
    width: 500px; 
    max-width: inherit; 
    min-width: inherit;
    @media(max-width:768px){
        margin: auto;
    }
}

.gallery-placeholder{
    margin: auto;
}

//fullpage opacity
.fotorama--fullscreen{
    background: rgba(0,0,0 ,0.6); 

    .fotorama__stage{
        height: 500px !important;
        margin-top: 35%;
    }
}

.fotorama--fullscreen .fotorama__wrap{
    margin: auto;
}

.fotorama__arr--disabled{
    opacity: 0;
}

.fotorama__stage__frame .fotorama__img{
    border: 2px solid #ccc;
    height: auto; 
    width: auto;
    top: 40%;

    @media(max-width:1115px){
        top: 30%;
    }

    @media(max-width:1024px){
        top: 30%;
    }

    @media(max-width: 768px){
        top: 50%;
    }

    @media(max-width: 480px){
        top: 40%;
    }
    @media(max-width: 375px){
        top: 30%;
    }
}

.fotorama__thumb-border{
    border: 1px solid #ccc;
}

.fotorama__nav__frame--thumb{
    opacity: 0.3;

    &:hover{
        opacity: 1;
    }
}

.fotorama__active{
    opacity: 1;
}

.fotorama__active .fotorama__dot{
    background-color: $color-primary;
    border-color: $color-primary;
}

.fotorama__dot{
    border-radius: 16px;
    height: 14px; 
    width: 14px;
}


//reviews
.product-reviews-summary .reviews-actions a{
    font-style: italic; 
    color: #ccc;

    &:hover{
        color: #ccc;
    }
}


//Tabs on product view

.product.info.detailed{
    margin-left: 60px;

    @media(max-width:$screen-sm-min - 1){
        margin-left: 0;
    }
}


.product.data.items > .item.title, 
.product.data.items > .item.content{
    @media(max-width:$screen-sm-min - 1){
        margin: 0;
    }
}


.product.data.items > .item.title > .switch{
    background: white; color: rgba(66,66,66 ,1); border: none;
}

.product.data.items > .item.title.active > .switch{
    background: white; 
    color: rgba(66,66,66 ,1);
    border-bottom: 2px solid $color-primary;
    @media(max-width: $screen-sm-min - 1){
        padding: 0; 
        text-align: center;
    }
}


//new tab for related product
.column.main .product-item.complementary-item{
    padding-left: 0; 
    padding: 3%;
    width: 300px;

    @media(max-width:1000px){
        width: 210px;
    }

    @media(max-width:900px){
        width: 190px;
    }

    @media(max-width:$screen-sm-min - 1){
        width: 180px;
    }
    @media(max-width:$screen-xs-min){
        width: 150px;
    }
}

.column.main .product-items.complementary-items{
    display: block;
    margin-left: 0;

    @media(max-width: $screen-sm-min){
        display: inherit;
    }

    li{
        background: white; 
        margin-left:1%; 
        margin-right: 1%; 
        margin-top: .5%;
    }
}

.products-grid .product-item{
    border: 1px solid #ccc; 
    border-radius: 6px; 

    .product-item-info {
        max-width: 100%; 
        width: 100%;

        img{
            margin-bottom: 20px; 
            margin-top: 2px;
            width: 100%; 
            height: 100%;
        }

        strong{
            height: 100px; 
            overflow: hidden; 
            text-overflow: ellipsis; 
            position: relative;

            a{
                font-size: 1.7rem; 
                font-weight: 600;
                height: 100px; 
                overflow: hidden;
                color: $color-secondary;
                &:after{
                    content: '';
                    height: 2px; 
                    width: 80%;
                    bottom: 10px; 
                    left: 10%;
                    position: absolute; 
                    background: $color-primary;
                }
            }
        }

        .price-box{
            min-height: 22px;
        }

        .price{
            font-size: 1.8rem;
            height: 100px; 
            overflow: hidden;
            color: $color-secondary;
        }

        a.btn{
            color: white; 
            width: 100%; 
            text-overflow: inherit; 
            padding: 12px 20px;
            &:hover{
                color: white;
            }
        }
    }

    a{
        &:hover,
        &:visited{
            color: $color-secondary;
            text-decoration: none;
        }
    }
}

.name-complementary{
    font-size: 2rem;
    margin-left: 5%; 
    padding-top: 30px; 
    padding-bottom: 5px;
    border-bottom: solid 3px red;
    width: 250px;

    @media(max-width: 480px){
        margin: auto;
    }
}

.homepage-section.complementary{
    background: white; 
    display: block;
}

.homepage-section_product.complementary{
    background: white; 
    padding: 0;

    @media(max-width:$screen-xs-min){
        display: block;
        height: auto;
        padding: 0;
        margin-left: inherit;
        background: inherit;
        background-color: transparent;


        .complementary-items{
            display: flex; 
            justify-content: space-between;
        }

        .homepage-section_product-item:nth-last-child(3){
            display: none;
        }   

        .homepage-section_product-item:nth-last-child(2){
            display: none;
        }

        .homepage-section_product-item:nth-last-child(1){
            display: none;
        }
    }

    .block-complementary{
        width: 100%;
    }

    .homepage-section_product-item{
        @media(max-width: $screen-xs-min){
            display: block;
        }
    }
}

//reset magento for tab complementary
.column.main .block:last-child{
    width: 100%; 
    height: 100%;
    clear: both;
}



//Magento
.product.data.items > .item.title:not(.disabled) > .switch:active, 
.product.data.items > .item.title.active > .switch, 
.product.data.items > .item.title.active > .switch:focus, 
.product.data.items > .item.title.active > .switch:hover{
    background: white;
}


//Info product

.page-layout-1column .product-info-main{
    width: 60%;
    @media(max-width:768px){
        width: 80%;
    }
}

.product-info-main{
    position: relative; 
    padding-left: 20px;
    @media(max-width:$screen-sm-min){
        padding: 2%;
    }

    .product.attribute.name{
        color: $color-secondary; 
        width: 65%;
        font-weight: 600; 
        font-size: 3.5rem;
        margin-bottom: 10px;

        @media(max-width:940px){
            width: 60%; 
            font-size: 2.3rem;
        }

        @media(max-width:768px){
            width: 100%; 
        }

        strong{
            display:none;
        }
    }

    .product.attribute.overview{
        width: 70%;

        strong{
            display: none;
        }
        
        @media(max-width:480px){
            width: 100%;
        }
    }

    .stock.available{
        color: rgba(46, 125, 50, 0.7);
    }

    .brand-label{
        display: block; 
        float: left;
        margin-right: 20px;

        &:after{ 
            content: ''; 
            height: 4px; 
            width: 60px;
            position: absolute;
            background-color: $color-primary;
        }
    }

    .brand-image{
        display: inline-block;
        position: absolute; 
        right: 60px; 
        top: 0;

        @media(max-width:768px){
            display: none;
        }

        img{
            margin: auto;
            width: 150px; 
            height: 150px;
        }
    }

    .product-info-price{
        margin-bottom: 20px;
    }
    
    .price-box{
        .price{
            color: $color-secondary;
            font-size: 2.5rem;
        }
    }

    .btn {
        &:visited {
            color:$color-primary;
        }
    }

    .product_call-to{
        margin-top: 20px; 
        margin-bottom: 20px;
        .btn{
            margin: auto;
            &:visited {
                color: $color-tertiary;
            }
        }
    }
} 