// Quote / Rent 
//----------------------------------------------------------

.product_rent {
  margin-bottom:20px;
  border-bottom:1px solid $color-gray-light;
  max-width:255px;
  & .title {
    font-size:$font-size-base + .6rem;
    border-bottom:1px solid $color-gray-light;
    padding-bottom:4px;
    margin-bottom:4px;
  }
  & .day,
  & .week,
  & .weekend,
  & .month {
    margin-bottom:4px;
  }
  & .value {
    font-weight:500;
  }
  & .label {
    color:$color-gray-dark;
  }
}
 
.instruction {
  margin-bottom:40px;
  font-style:italic;
}