#header-banderau{
    position:relative;
    height: 43px;
    width: 100%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.03);
    text-align: center;
    color: $color-quaternary;
    @media (max-width: 1024px){
        text-align: left;
    }
    @media (max-width: 780px){
        display: none;
    }

    .header-banderau_middle{
        position:absolute;
        left: 50%;
        transform: translate(-50%,0);
        display: inline-block;
        @media(max-width:$screen-md-min + 88px) {
            left: 15px;
            transform: inherit;
        }
        a.link-header{
            color: #7C7C7C;
            &:hover{
                color: #7C7C7C;
            }
            &:visited{
                color: #7C7C7C;
            }
            &:focus{
                color: #7C7C7C;
            }
        }
    }

    .header-banderau_right{
        display: inline-block;
        position: absolute;
        right: 15px;
    }

    p{
        display: inline-block;
        margin: auto;
        font-size: $font-size-base - 0.2rem;  
    }
}

.header.content {
    padding: 0;
    display: flex;
    align-items: flex-start;
    @media (min-width: $screen-sm-min){
        max-width: 100%;
    }

    .nav-toggle{
        line-height: 30px; //middle height of header
        vertical-align: middle;
        left: 10px; font-weight: 900; color: $color-secondary;
    }

    .logo{
        width: 360px;
        height: 120px;
        margin: 0;

        @media(max-width: 780px){
            width: 200px; height: 50px; left: 10%; margin-bottom: 15px;
        }
    }

    .slogan-logo{
        position: absolute;
        top: 41%;
        left: 105%;
        width: 200px;

        @media (max-width: $screen-md-min){
            display: none;
        }
    }

    .no-a{
        color: black;
        font-weight: 700;
        &:hover{
            color:black;
        }
    }

    .header_mobile-icons{
        @media(min-width:780px){display: none; line-height: 70px;}
        @media(min-width:$screen-xs-min){line-height: 70px;}

        position: absolute; right: 10px;
        line-height: 70px; vertical-align: middle;
        color: black;

        a{
            color: black;
        }

        a:hover{
            color: $color-secondary;
        }

        a:focus{
            color: $color-secondary;
        }

        svg.icon{
            width: 3em;
            height: 1.6em;
        }
    }

    .block-search{
        width: 250px;
        z-index: 4;
        position: absolute;
        right: 55px;
        top: 50px;

        .control input{
            border: none;
        }

        #search_autocomplete{
            display: none;
        }

        .nested{
            display: none;
        }

        @media (max-width: 780px){
            display: none;
        }

    }
}

.header.content::before{
    display: none; 
    content: '';
}
.header.content::after{
    display: none; 
    content: '';
}
