//
// Headings
// --------------------------------------------------------------------------
.page-header{
    margin-bottom: 0; // Reset margin bottom on blank theme
}

// Section
// --------------------------------------------------------------------------

.section-head {
    margin: 0 0 15px;
    padding: 10px 0 0;

    .catalog-category-view &,
    .catalog-product-view &,
    .catalogsearch-result-index & {
        min-height: 44px;
        margin: 0 0 10px;
        padding-left: 44px;
    }

    @media (min-width: $screen-sm-min) {
        margin: 30px 0;
        padding: 0;

        .catalog-category-view &,
        .catalog-product-view &,
        .catalogsearch-result-index & {
            min-height: inherit;
            margin: 30px 0 15px;
            padding-left: 0;
        }
    }
}

.section-head__title {
    display: block;
    margin: 0;
    font-size: $font-size-large + .4rem;
    font-weight: 300;

    @media (min-width: $screen-sm-min) {
        font-size: $font-size-h2;
    }
}

.section-head__infos {
    display: block;
    margin: 8px 0 0;
    font-size: $font-size-large + .1rem;
    font-weight: bold;
    line-height: 1.3;

    @media (min-width: $screen-sm-min) {
        font-size: $font-size-large + .2rem;
    }
}

.section-head__status,
.section-head__date {
    display: block;
    font-size: $font-size-base + .1rem;
    letter-spacing: .5px;
}

.section-head__status {
    margin: 8px 0 4px;
}


// Headline
// --------------------------------------------------------------------------

.headline {
    display: block;
    margin: 16px 0;
    font-size: $font-size-large + .2rem;
    font-weight: 300;
    letter-spacing: .5px;
    line-height: 1.3;
    text-align: center;

    strong {
        font-weight: 600;
    }

    @media (min-width: $screen-sm-min) {
        margin: 32px 0;
        font-size: $font-size-h2 + .4rem;
        letter-spacing: .7px;
    }

    @media (min-width: $screen-lg-min) {
        strong {
            display: block;
        }
    }
}

// Errors
// --------------------------------------------------------------------------

.error-content{
    width: 100%;
    margin-left: auto; 
    margin-right: auto;
    text-align: center;
    li {
        list-style-type: none;
        
        &:before {
	        content: "- ";
        }
    }

    .error-subtitle{
        font-size: $font-size-base + 0.6;
        color: $color-primary;
    }
}