// Main sidebar 
.sidebar-main{
    padding-left: 1%;
}

.action .sorter-action .sort-asc, 
.modes{
    display: none; 
}

.sorter-action:before{
    content: '';
}

.toolbar-sorter {
    @media(max-width:$screen-xs-min) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .sorter-label {
            margin: 0;
        }
    }
    #sorter {
        border-bottom: 1px solid $color-gray-light;
        &:focus {
            box-shadow: none;
        }
    }
}