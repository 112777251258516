//
// Fonts
// --------------------------------------------------------------------------


// Mixin
// --------------------------------------------------------------------------

@mixin font-face($font-name, $font-path, $font-file, $font-weight: normal, $font-style: normal) {
    font-family: $font-name;
    src: url('../fonts/' + $font-path + '/' + $font-file + '.woff2') format("woff2"),
         url('../fonts/' + $font-path + '/' + $font-file + '.woff') format("woff");
    font-weight: $font-weight;
    font-style: $font-style;
}


// Load fonts
// --------------------------------------------------------------------------

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');

/*

// Source Sans Pro Light
@font-face {
    @include font-face('Source Sans Pro', 'sourcesanspro', 'sourcesanspro-light-webfont', 300);
}

// Source Sans Pro Regular
@font-face {
    @include font-face('Source Sans Pro', 'sourcesanspro', 'sourcesanspro-regular-webfont', 400);
}

// Source Sans Pro Semi-Bold
@font-face {
    @include font-face('Source Sans Pro', 'sourcesanspro', 'sourcesanspro-semibold-webfont', 600);
}

// Source Sans Pro Bold
@font-face {
    @include font-face('Source Sans Pro', 'sourcesanspro', 'sourcesanspro-bold-webfont', 700);
}

*/