.homepage-section_services{
    width: 100%; 
    height: 700px;

    @media(max-width: 1024px){
        height: 1050px;
    }

    .services-one, 
    .services-two{
        position: relative;
        width: 50%; 
        height: 100%; 
        overflow: hidden;
        background-color: $color-secondary;
        @media (max-width: 1024px) {
            display: block; 
            width: 100%;
        }
    }

    .services-one{
        float: left;
        //background: url(../images/mecabam_services.png) no-repeat;
        background-size: cover;
        height: 700px;
        @media(max-width:$screen-xs-min){
            height: 350px;
        }
        @media(max-width:1024px){
            height: 350px;
            background-position: center;
        }
    }

    .services-two{
        float: right;
        color: #fff;
        @media(max-width: $screen-xs-min){
            height: 700px;
        }
        @media(max-width: 1024px){
            height: 700px;
        }
        background:url('../images/dots_top.png') no-repeat left top, 
            url('../images/logoC.png') no-repeat right top -5%,  
            url('../images/dots_bottom.png') repeat-x bottom;
        background-color: $color-secondary;
        .services-two_content{
            position: absolute; 
            width: 45%; 
            height: 80%;
            left: 15%; 
            top: 5%; 
            text-align: left;
            @media (max-width: $screen-xs-min){
                width: 80%;
            }
            .services-logo{
                margin-bottom: 37px;
                width: 90%; 
                height: auto;
            }

            .services-descr{
                position: relative; 
                line-height: 30px;
                font-size: 20px; 
                font-weight: 500;
                margin-bottom: 50px;
                &::after {
                    content: '';
                    position: absolute;
                    bottom: -20px;
                    left: 0px;
                    width: 90px;
                    height: 2px;
                    background: #fff;
                }
            }
            
            .services-descr_liste, 
            .mecabam-list{
                font-weight: 200;
            }
            .mecabam-list{
                padding: 0;
                margin: -20px 0 40px 0;
            }
            .mecabam-list li{
                list-style: none; 
                margin-bottom: -45px;
                padding-left: 1em; 
                text-indent: -.7em;
                &::before{
                    content: '\002022';
                    color: $color-primary;
                    font-size: 4em;
                    vertical-align: middle;
                }
            }
        }
    }
}