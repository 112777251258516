//
// Tooltip
// --------------------------------------------------------------------------


// Documentation
// --------------------------------------------------------------------------

/*doc
---
title: Tooltip
name: tooltip
category: Components
---
The tooltip provide extra informations when it's hovered or clicked. It can be use in a `<label>`, for example, to give more information about the field.

The hidden text must be placed in the `aria-label` attribute.

```html_example
<button type="button" class="tooltip" aria-label="This text provide extra informations" role="tooltip">Help</button>
```
*/


// Class
// --------------------------------------------------------------------------

.tooltip {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    margin: -5px 0 0 5px;
    padding: 0;
    @include text-hide();
    background-color: $color-secondary;
    border: 0;
    border-radius: 50%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 13'%3E%3Cpath fill='%23ffffff' d='M2.99 6.97C3.18 6.78 3.41 6.68 3.69 6.68 4.41 6.68 4.99 6.52 5.42 6.18 5.86 5.84 6.07 5.32 6.07 4.61 6.07 4.07 5.88 3.62 5.49 3.26 5.1 2.9 4.61 2.72 4.02 2.72 3.42 2.72 2.89 2.92 2.44 3.32 2.17 3.56 1.9 3.68 1.62 3.68 1.41 3.68 1.23 3.62 1.06 3.49 0.78 3.27 0.64 3.01 0.64 2.72 0.64 2.47 0.74 2.24 0.95 2.03 1.34 1.63 1.8 1.32 2.33 1.09 2.86 0.87 3.42 0.76 4.02 0.76 4.76 0.76 5.43 0.93 6.04 1.27 6.65 1.6 7.14 2.07 7.49 2.66 7.85 3.25 8.03 3.9 8.03 4.61 8.03 5.66 7.73 6.48 7.14 7.09 6.54 7.7 5.74 8.08 4.75 8.23 4.7 8.26 4.67 8.28 4.67 8.32L4.67 8.92C4.67 9.2 4.58 9.44 4.39 9.63 4.21 9.82 3.97 9.92 3.69 9.92 3.41 9.92 3.18 9.82 2.99 9.63 2.81 9.44 2.71 9.2 2.71 8.92L2.71 7.68C2.71 7.4 2.81 7.16 2.99 6.97ZM2.88 12.58C2.72 12.42 2.63 12.17 2.63 11.86L2.63 11.58C2.63 11.26 2.72 11.02 2.88 10.85 3.05 10.69 3.29 10.6 3.61 10.6L3.82 10.6C4.14 10.6 4.38 10.69 4.55 10.85 4.72 11.02 4.8 11.26 4.8 11.58L4.8 11.86C4.8 12.17 4.72 12.42 4.55 12.58 4.38 12.75 4.14 12.84 3.82 12.84L3.61 12.84C3.29 12.84 3.05 12.75 2.88 12.58Z'/%3E%3C/svg%3E");
    background-position: 50% 42%;
    background-repeat: no-repeat;
    background-size: auto 55%;
    cursor: pointer;

    &::after {
        content: attr(aria-label);
        position: absolute;
        top: calc(100% + 8px);
        left: 50%;
        opacity: 0;
        width:270px;
        padding: 8px 10px;
        font-family: $font-family-base;
        font-size: $font-size-base;
        font-weight: 500;
        letter-spacing: .5px;
        line-height: 1.1;
        text-align: center;
        color: #FFFFFF;
        background-color: $color-secondary;
        border-radius: 3px;
        transform: translate(-50%,0);
        transition: opacity .15s ease-in-out;
        pointer-events: none;
        z-index: 10;
    }

    @include hover() {
        outline: none;

        &::after {
            opacity: 1;
        }
    }
}
