.content-retailers{
    width: 100%; 
    height: 360px;
    text-align: center;

    @media (max-width:1024px){
        height: 906px;
    }
    @media (max-width:$screen-xs-min){
        height: 500px;
    }

    .content-retailers-first{
        float: left;
        //background: url(../images/background_karcher.png) no-repeat;
        background-size: cover;
        height: 453px;
    }

    .content-retailers-second{
        float: right;
        //background: url(../images/background_beta.png) no-repeat;
        background-size: cover;
        height: 453px;
    }

    .content-retailers-first, 
    .content-retailers-second{
        position: relative; 
        width: 50%; 
        height: 100%; 
        text-align: center;
        overflow: hidden;

        @media (max-width: 1024px) {
            display: inline-block; 
            width: 100%; 
            height: 453px;
        }

        @media (max-width: $screen-xs-min) {
            background: url(../images/black-diamond-plate.png);
            background-color: $color-primary; 
            height: 50%;
        }

        .retailers{
            position: absolute;
            font-size: 2.5rem;
            top: 65%;
            left: 50%;
            transform: translateX(-50%);
        }
        .super_test{ 
            position: absolute; 
            top: 30%; 
            left: 50%;
            height: 52%; 
            width: 45%; 
            display: inline-block;
            border-radius: 6px;
            @media (max-width: $screen-sm-min) {
                height: 50%; 
                width: 60%; 
                left: 30%;
            }
            @media (max-width: $screen-xs-min) {
                height: 50%; 
                width: 60%; 
                left: 20%;
                border: none;
            }

            .super_test-content{
                border-radius: 6px;
                display: block; 
                position: absolute;
                left: 0; 
                right: 0; 
                top: 0; 
                bottom: 0; 
                margin: auto;
                height: 93%; 
                width: 95%; 
                @media (max-width: $screen-xs-min) {
                    background-color: transparent;
                    color: white; 
                    font-weight: 700;
                    .btn{
                        color: white; 
                        border-color: white;
                    }
                }             
                .super_test-content-all{
                    height: 90%; 
                    width: 90%;
                    margin: auto; 
                    padding-top: 10%; 
                    margin-top: -20%;
                    text-align: center; 
                    position: relative;
                    
                    img{ 
                        width: 60%; 
                        height: auto;
                        margin: auto; 
                        margin-bottom: 5%; 
                    }
                    
                    a{
                        
                        @media (max-width: $screen-xs-min){
                            color: white;
                        }

                        &:visited{
                            color: black;
                            @media (max-width: $screen-xs-min){
                                color: white;
                            }
                        }

                        &:hover{
                            color: black;
                            @media (max-width: $screen-xs-min){
                                color: white;
                            }
                        }

                        &:focus{
                            color: black;
                            @media (max-width: $screen-xs-min){
                                color: white;
                            }
                        }
                    }

                    @media (max-width: $screen-xs-min) {
                        padding: 0;
                        margin-top: -21%;
                        
                        img{
                            width: 80%;
                        }
                    }
                }
            }
        }
    }
}

.background-image {
    position:absolute;
    height:100%;
    width:100%;
    object-fit:cover;
}