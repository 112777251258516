.homepage-section{
    text-align: center;
    background-color: #fafafa;
}
.homepage-section_product{
    display: flex; 
    justify-content: space-between;
    width: 100%; 
    margin: auto;
    padding: 20px; 
    padding-left: 0;
    padding-right: 0;
    @media (max-width: $screen-xs-min) {
        height: 150px; 
        padding: 0;
        background-image: url('../images/black-diamond-plate.png') repeat-y;
        background-color: $color-secondary;
    }
    @media (max-width: $screen-xs-min + 85px) {
        justify-content: space-around;
    }
    .mobile{
        margin: auto;
        line-height: 150px;
        vertical-align: middle;
    }

    .homepage-section_product-item{
        display: inline-block; 
        background-color: #fff; 
        text-align: center; 
        border: solid 1px #cccccc; 
        border-radius: 6px; 
        width: 290px;
        box-shadow: 1px 1px rgba(0, 0, 0, 0.05);
        padding: 25px 25px 30px 25px;
        @media (max-width:$screen-xs-min) { 
            display: none; 
        }

        .homepage-section_product-item-pic{
            width: 201px; 
            height: auto; 
            margin: auto;
            img{
                height: 201px; 
                max-width: auto;
            }
        }

        .homepage-section_product-item-title{
            margin: auto;
            h3{
                font-size: 20px;
                line-height: 25px;
                height: 50px;
                text-overflow: ellipsis; 
                overflow: hidden;
            }
        }

        .product-separation{
            width: 70%; 
            margin: 20px auto;
            border: solid 1px $color-primary;
        }

        .homepage-section_product-item-subtitle{
            color: $color-gray; 
            font-size: 16px; 
            font-weight: 500;
            line-height: 20px; 
            height: 100px; 
            text-overflow: ellipsis; 
            overflow: hidden;
        }

        .homepage-section_product-item-price{
            min-height:47px;
            color: black; 
            font-size: 20px;
            line-height: 25px; 
            font-weight: 800;
            padding-top: 10px; 
            padding-bottom: 60px;
            max-height: 50px;
            p{
                display: none;
            }
            .price {
                font-size: $font-size-base + .4rem;
            }
            .special-price {
                display:block;
            }
        }
    }
    // selon les devices on reduit le nombre de produits mis en avant, jusqu'au mobile ou cela disparait
    .homepage-section_product-item:nth-last-child(3){
        @media (max-width: 565px) {
            display: none;
        }
    }   

    .homepage-section_product-item:nth-last-child(2){
        @media (max-width: 950px) {
            display: none;
        }
    }

    .homepage-section_product-item:nth-last-child(1){
        @media (max-width: 1300px) {
            display: none;
        }
    }
}
