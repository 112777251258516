//
// Breadcrumb
// --------------------------------------------------------------------------


// Documentation
// --------------------------------------------------------------------------

/*doc
---
title: Breadcrumb
name: breadcrumb
category: Navigation
---
Breadcrumb use microdata, useful for search engine optimisation. The position store in a `meta` tag need to be updated.

```html_example
<ol class="breadcrumb" itemscope="" itemtype="http://schema.org/BreadcrumbList">
    <li class="breadcrumb__item" itemscope="" itemprop="itemListElement" itemtype="http://schema.org/ListItem">
        <a href="#" itemprop="item"><span itemprop="name">Home</span></a>
        <meta itemprop="position" content="1">
    </li>
    <li class="breadcrumb__item" itemscope="" itemprop="itemListElement" itemtype="http://schema.org/ListItem">
        <a href="#" itemprop="item"><span itemprop="name">Catalogue</span></a>
        <meta itemprop="position" content="2">
    </li>
    <li class="breadcrumb__item" itemscope="" itemprop="itemListElement" itemtype="http://schema.org/ListItem">
        <a href="#" itemprop="item"><span itemprop="name">Amortisseurs</span></a>
        <meta itemprop="position" content="3">
    </li>
    <li class="breadcrumb__item" itemscope="" itemprop="itemListElement" itemtype="http://schema.org/ListItem">
        <strong itemprop="item"><span itemprop="name">Amortisseurs avant</span></strong>
        <meta itemprop="position" content="4">
    </li>
</ol>
```
*/


// Base
// --------------------------------------------------------------------------
.breadcrumbs {
    background: white;
    position: relative; 
    width: 100%;
    font-weight: 700;
    text-transform: uppercase;
    @include list-unstyled();
    margin-top: 40px;
    padding-top: 20px; 
    padding-bottom: 20px; 
    padding-left: 1%;

    @media (min-width: $screen-sm-min) {
        max-width: 100%;
    }

    strong, 
    a {
        font-size:2rem;
        @media(max-width: $screen-xs-min){
            font-size: 1.4rem;
        }
    }

    a{
        color: $color-secondary;

        &:hover{
            color: $color-primary;
            text-decoration: none;
        }
    }

    .item{
        color: $color-secondary;
        font-size: 1.4rem;
    }

    .item:not(:last-child):after{
        font-size: 4rem;
        vertical-align: middle;
        line-height: 28px;
        vertical-align: inherit;

        @media(max-width: $screen-xs-min){
            font-size: 2rem;
            line-height: 16px;
            vertical-align: inherit;
        }
    }
}