.brand-widget{
    width: 100%; 
    overflow: hidden;
    margin-top: 60px; 
    margin-bottom: 60px;

    .owl-item{
        height: 170px;
    }
    
    .block-title{
        position: relative; 
        margin: auto;
        width: 300px; 
        margin-bottom: 30px; 
        height: 50px; 
        display: block;
        text-align: center;
        strong{
            font-weight: 700;
            font-size: 3rem;
        }

            &::after {
                content:'';
                position:absolute;
                bottom: 0;
                left: 34%;
                width:90px;
                height:2px;
                background:$color-primary;;

                @media (max-width: $screen-sm-max) {
                    font-size: $font-size-base; 
                }
                @media (max-width: $screen-xs-min){
                    font-size: $font-size-base - 0.5;
                }
            }
    }
    
    .brand-image{
        height: 150px;
        width: 150px;
        margin: auto; 
        padding: 15px;
        box-shadow: 1px 1px 2px 2px #ccc;

    }

    ul{
        padding: 0; 
        -webkit-padding-start: 0px;
    }

    .owl-carousel .owl-loaded .owl-drag{
        margin: 0;
        padding: 0; 
        padding-left: 0;
        -webkit-padding-start: 0px;
    }

    .owl-item{
        li{
            margin-top: 9px;
        }
    }

    .owl-left, 
    .owl-right{
        display: inline-block; 
        height: 30px; 
        width: 30px; 
        margin-top: 20px;
    }

    .owl-dots{
        display: none;
    }

    .brandowl-play{
        text-align: center;
    }

    .brandowl-play > a {
        font-size: 0px;
        background-color: white; 
        color: transparent;
        position: relative; 
        border: solid 1px #ccc;
        transition: 2s;
        margin-left: -5px;
        
        &:hover{
            background-color: $color-primary;
        }

        &:before{
            content: ''; 
            position: absolute; 
            border: solid #ccc;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 3px; 
            top: 10px; 
            left: 8px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }
    }

    .brandowl-play > a:first-of-type{
        margin-left: 0;
        &:before{
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
            left: 11px;
        }
    }
} 